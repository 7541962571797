import React from "react";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SolutionInOneAppCards from "./SolutionInOneAppCards";

function SolutionInOneApp({ cmsData, solutionInOneAppCmsData }) {
  const history = useHistory();
  return (
    <div className="solution_bg">
      <h3 className="text-color text-color font-60 fw-bold mb-3 my-5 text-center myTextShadow">
        {cmsData?.sec2Heading}
      </h3>
      <p className="mytute_p mb-5  text-center">{cmsData?.sec2Description}</p>
      <SolutionInOneAppCards
        solutionInOneAppCmsData={solutionInOneAppCmsData}
      />
      <Col className="mt-4 text-center">
        <button
          className="get-started-now-btn my-2"
          onClick={() => history.push("/signup-role")}
        >
          Get Started Now
        </button>
      </Col>
    </div>
  );
}

export default SolutionInOneApp;
