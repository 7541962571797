import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";


import AboutVisitDoctor from "../components/AboutVisitDoctor";
import AboutBox from "../components/AboutBox";

import { Get } from "../Axios/AxiosFunctions";
import { imageUrl, URL as baseUrl } from "../Config/apiUrl";

const AboutUs = () => {
  const [cmsData, setCmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [
    visionAndMissionAnalysis1CmsData,
    setVisionAndMissionAnalysis1CmsData,
  ] = useState([]);
  const [
    visionAndMissionAnalysis2CmsData,
    setVisionAndMissionAnalysis2CmsData,
  ] = useState([]);

  const [businessStrategies1Data, setBusinessStrategies1Data] = useState([]);
  const [businessStrategies2Data, setBusinessStrategies2Data] = useState([]);
  const [productsAndServiceData, setProductsAndServiceData] = useState([]);
  const [appStoreLinkcmsData, setAppStoreLinkcmsData] = useState([]);
  const [problemWorthSolving, setProblemWorthSolving] = useState([]);

  const getHomeCmsData = async () => {
    console.log("function RUn");
    const url = baseUrl(
      `cms/page/about_us?visionAndMissionAnalysis1=true&visionAndMissionAnalysis2=true&appStoreLinks=true&businessStrategies1=true&businessStrategies2=true&productsAndService=true&problemWorthSolving=true&whatPeopleSayAboutUs=true`
    );
    // const url = baseUrl(`api/page`);

    const response = await Get(url);
    console.log("asda", response);

    if (response !== undefined) {
      setCmsData(response?.data?.data);
      setVisionAndMissionAnalysis1CmsData(
        response?.data?.visionAndMissionAnalysis1Data
      );
      setVisionAndMissionAnalysis2CmsData(
        response?.data?.visionAndMissionAnalysis2Data
      );
      setBusinessStrategies1Data(response?.data?.businessStrategies1Data);
      setBusinessStrategies2Data(response?.data?.businessStrategies2Data);

      setProductsAndServiceData(response?.data?.productsAndServiceData);
      setAppStoreLinkcmsData(response?.data?.appStoreLinksData);
      setProblemWorthSolving(response?.data?.problemWorthSolvingData);
      console.log(response);
    }
    setIsLoading(false);
  };

  // to scroll top up when page refreshed
  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });
    getHomeCmsData();
  }, []);

  return (
    <>
      {/* <Header /> */}
      <div className={`w-100 ${isLoading && "opacity-zero"}`}>
        <Container className="home_banner_b">
          <Row className="home_bg_picture home-bg">
            <Col lg={6} md={12} sm={12} className="home_first_sec_left aboutpage_sec1">
              <h3 className="m-0 text-color font-60 fw-bold whito">
                {cmsData?.sec1Heading}
              </h3>
              <p className="mt-3 mb-5 text-left">{cmsData?.sec1Description}</p>
            </Col>

            <Col lg={6} md={12}>
              <img
                className="home-right-first"
                src={`${imageUrl}${cmsData?.sec1Image}`}
                style={{ maxWidth: "100%" }}
                alt="vector mobile"
              />
            </Col>
          </Row>
        </Container>

        {/* --------------------------------- About Daweeye --------------------------------- */}

        <section className="doctorRedBg marginTop">
          <Container className="custmContainer">
            <Row className="about_first_sect">
              <Col lg={6} md={12}>
                <div className="redBgImg"></div>
                <div className="home_laptopImg">
                  <img src={`${imageUrl}${cmsData?.sec2Image}`} alt="" />
                </div>
              </Col>
              <Col lg={6}
             md={12}
               sm={12} className="center_small">
                <div className="about_mytute">
                  <h3 className="mb-4 text-color fw-bold">
                    {cmsData?.sec2Heading}
                  </h3>
                  <p className="text-left">{cmsData?.sec2Description}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* --------------------------------- Vission and mission Analysis --------------------------------- */}
        <div className="mission_analysis-parent">
        <Container className="mission_analysis">
          <Row className="row_margin left_bg">
            <div className="">
              <Row className="d-flex justify-content-center">
                <Col  md={12}>
                  <h3 className="font-60 fw-bold text-center text-color mb-3">
                    {cmsData?.sec3Heading}
                  </h3>
                  <p className="text-center m-auto" style={{ maxWidth: "100%" }}>
                    {cmsData?.sec3Description}
                  </p>
                </Col>
              </Row>

              <Row className="about_secSecontion">
                <Col  lg={6} md={12}>
                  <div className="mainDiv_circle">
                    {visionAndMissionAnalysis1CmsData.map((item, index) => {
                      return (
                        <div key={index} className="circle_vision">
                          <div
                            className={`circle ${
                              visionAndMissionAnalysis1CmsData.length - 1 ===
                              index
                                ? ""
                                : "line"
                            }`}
                          ></div>
                          <div className="circle_text">
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>

                <Col lg={6} md={12}>
                  <div>
                    <img
                      fluid
                      alt=""
                      src={`${imageUrl}${cmsData?.sec3Image}`}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                </Col>
                <div className="border_boxes_parent">
                  <Row>
                  {visionAndMissionAnalysis2CmsData.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        lg={3}
                        md={6}
                        className={`border_boxes ${
                          index % 2 === 0 ? "border_boxes3" : "border_boxes2"
                        } mx-2`}
                      >
                        <div className="innerBorder_box">
                          <h6>{item.title}</h6>
                          <p>{item.description}</p>
                        </div>
                      </Col>
                    );
                  })}
                  </Row>
                </div>
                <Col md={12}>
                  <div className="Problem_Worth ">
                    <h1>{cmsData?.sec4Heading}</h1>
                    <p style={{ textAlign: "center" }}>
                      {cmsData?.sec4Description}
                    </p>
                  </div>
                </Col>
                <Col md={12} className="abtTable_main">
                  <Table bordered className="main_tablee">
                    <thead className="aboutTable_thead">
                      <tr>
                        <th>Problems</th>
                        <th>Solutions</th>
                      </tr>
                    </thead>
                    <tbody className="tableBodyAbout">
                      {problemWorthSolving.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.title} </td>
                            <td>{item.description}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
                <Col md={12} className="problemWorth_Para circle_para">
                  <p>{cmsData?.sec4Description2}</p>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
        </div>

        {/* ____________________________Product And Services___________________________ */}

        <section className="doctorRedBg">
          <Container className="custmContainer">
            <Row>
              <Col lg={6} md={12} sm={12}>
                <div className="redBgImg"></div>
                <div className="home_laptopImg">
                  <img src={`${imageUrl}${cmsData?.sec5Image}`} alt="" />
                </div>
              </Col>
              <Col lg={6} sm={12} md={12} className="center_small">
                <div className="about_mytute">
                  <h4 className="mb-4 text-color fw-bold">
                    {cmsData?.sec5Heading1}
                  </h4>
                  <p className="text-left">{cmsData?.sec5Description1}</p>
                  <h5>{cmsData?.sec5Heading2}</h5>
                  <p>{cmsData?.sec5Description2}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* ______________________________________________________________ */}

        <AboutVisitDoctor data1={productsAndServiceData} />
        <section className="uniueSelling_proposition">
          <Container>
            <Row>
              <Col md={12}>
                <div className="unique_selling">
                  <h5>{cmsData?.sec5Heading3}</h5>
                  <p> {cmsData?.sec5Description3}</p>
                  {/* <p className="usp">We will attain the following U.S.P.s:</p>
                  <ul>
                    <li>
                      Make health care accessible to people who live in isolated
                      communities
                    </li>
                    <li>
                      Make services more readily available or convenient for
                      people with limited mobility, time, or transportation
                      options to access medical specialist
                    </li>
                    <li>
                      Improve communication & coordination of care among
                      healthcare professionals and patients
                    </li>
                    <li>
                      Building a strong relationship with healthcare e-commerce
                      platform
                    </li>
                    <li>
                      User-friendly and integrated platform in for of iOS,
                      Android, and website
                    </li>
                    <li>
                      Enabling patients to book phlebotomists for lab tests,
                      specially covid
                    </li>
                    <li>
                      Fully equipped with advanced solutions to provide FinTech
                    </li>
                  </ul> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="abtBusiness_Stratgies">
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <div className="about_business">
                  <h3>{cmsData?.sec6Heading1}</h3>
                  <h6>{cmsData?.sec6Heading2}</h6>
                  <p>{cmsData?.sec6Description2}</p>
                  <p>
                    Daweeye's business model is launching a digital healthcare
                    platform that offers managed solutions under one integrated
                    iOS, Android, and Website platform. Our business model
                    serves both the B2B and B2C customers to connect them under
                    one platform. The cost-effective solutions will enable
                    customers to rely on trusted platforms for buying and
                    selling.{" "}
                  </p>
                  <p>
                    Our business model mainly works in two forms as B2B and B2C
                    as follows:
                  </p>
                </div>
                  <Col md={12}>
                <div className="red_box_main">
                    <div className="red_box">
                      <h6>{cmsData?.sec6Heading3}</h6>
                    </div>
                    <div className="red_box">
                      <h6>{cmsData?.sec6Heading4}</h6>
                    </div>
                </div>
                  </Col>
                 
              </Col>
              <Col lg={6} md={{span:12,order:2}} className="bg_red">
                <div className="pc_img">
                  <img src={`${imageUrl}${cmsData?.sec6Image}`} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container className="businessToCutomers_main">
            <Row>
              <Col  lg={6} md={12}>
                <div className="businessToCustomer_inner">
                  <h5>{cmsData?.sec6Heading3}</h5>
                  <p> {cmsData?.sec6Description3}</p>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {appStoreLinkcmsData.map((item, index) => {
                      return (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img
                            fluid
                            src={`${imageUrl}${item?.image}`}
                            className="mx-4 storeImages"
                            style={
                              {
                                // maxWidth: "100%",
                              }
                            }
                            alt="Vector png"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="mobileBg">
                  <img src={`${imageUrl}${cmsData?.sec6Image2}`} alt="" />
                </div>
              </Col>

              <Col md={{span:12,order:2}} lg={6}>
                <div className="mobileImg_bg">
                  <img
                    style={{ width: "100%" }}
                    src={`${imageUrl}${cmsData?.sec6Image3}`}
                    alt=""
                  />
                </div>
              </Col>
              <Col lg={6} md={{span:12,order:1}}>
                <div className="mobileImg_text">
                  <h5>{cmsData?.sec6Heading4}</h5>
                  <p>{cmsData?.sec6Description4}</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="Problem_Worth location_compatibility">
                  <h5>{cmsData?.sec6Heading5} </h5>
                  <p>{cmsData?.sec6Description5}</p>
                  <h5>{cmsData?.sec6Heading6} </h5>
                  <p>{cmsData?.sec6Description6}</p>
                  <h5>{cmsData?.sec6Heading7} </h5>
                  <p>{cmsData?.sec6Description7}</p>
                </div>
              </Col>
            </Row>
          </Container>
          <AboutBox data={businessStrategies1Data} />
        </section>
        <section className="listItmesss">
          <Container>
            <Row>
              <Col md={12}>
                <div className="unique_selling">
                  <ul>
                    {businessStrategies2Data.map((item, index) => {
                      return <li key={index}>{item.description}</li>;
                    })}
                  </ul>
                </div>
              </Col>
              <Col md={12}>
                <div className="ourTeam">
                  <h3>{cmsData?.sec7Heading1} </h3>
                  {cmsData?.sec7Description1}
                </div>
              </Col>
              <Col md={12}>
                <div className="ourTeam">
                  <h3>{cmsData?.sec7Heading2} </h3>
                  {cmsData?.sec7Description2}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default AboutUs;

let aboutBoxArray = [
  "Download the App",
  "Profile Creation",
  "Connecting consumer with the professionals of healthcare sectors",
  "Ordering/Booking the Product and service",
  "Payment/ Commission",
  "Delivery of Product and services",
];
let aboutVisitDoctorArray = [
  "Visit a Doctor",
  "Medical Labs",
  "Pharmacy",
  "Home Visit",
  "Ambulance",
  "Doctor Call Free Consultaion",
];
