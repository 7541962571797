import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { useSelector } from "react-redux";
import { apiHeader, recordsLimit, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import BodyLoader from "../components/BodyLoader";
import { useHistory, useLocation } from "react-router-dom";
import NullDataComponent from "../components/NullDataComponent";
import PharmacyCard from "../components/PharmacyCard";
import PaginationB from "../components/PaginationB";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const Pharmacy = () => {
  const history = useHistory();
  const location = useLocation();
  const cartItems = useSelector((state) => state?.cartReducer?.cartItems);
  const catName = location?.state;
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoadingCats, setIsLoadingCats] = useState(false);
  const [pharmacy, setPharmacy] = useState([]);
  const [cats, setCats] = useState([]);
  const [status, setStatus] = useState(catName);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Pagination => this
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // get Pharmacy
  const getPharmacy = async () => {
    const url = URL(
      `product?page=${page}&limits=${recordsLimit}&category=${status}`
    );
    setIsApiCall(true);
    const response = await Post(
      url,
      {
        ...(status !== "All Products" && { type: [status] }),
      },
      apiHeader(accessToken)
    );
    setIsApiCall(false);

    if (response !== undefined) {
      setPharmacy(response?.data?.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  };

  // get cats
  const getCats = async () => {
    const url = URL(`category`);
    setIsLoadingCats(true);
    const response = await Get(url, accessToken);
    setIsLoadingCats(false);

    if (response !== undefined) {
      const _d = response?.data?.data?.map((item) => item?.catName);
      setCats(_d);
      console.log("cats", _d);
    }
  };

  useEffect(() => {
    getPharmacy();
  }, [page, status]);

  useEffect(() => {
    getCats();
  }, []);

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  return (
    <>
      <Container fluid className="dashboard pharmacyMain">
        <Row>
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="mt-5">
              <Col md={12}>
                <DashboardTopBar />
              </Col>
            </Row>
            <Row className="my-5">
              <Col className="text-icon-x">
                <h5>Pharmacy</h5>
                <div
                  className="cart-icon-container c-p"
                  onClick={() => {
                    cartItems.length > 0
                      ? history.push("/checkout")
                      : console.log("No items in cart");
                  }}
                >
                  <AiOutlineShoppingCart
                    className="cartIcon"
                    style={{
                      color: "red",
                    }}
                  />
                  <span className="cart-count">{cartItems?.length}</span>
                </div>
              </Col>
              {/* dropdown */}
              <Col md={3} className="product_select">
                <select
                  className="form-select b-round t-t-c f-s-r"
                  aria-label="Default select example"
                  style={{ Width: "100%" }}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value={"All Products"} selected>
                    All Products
                  </option>
                  {cats.map((e) => (
                    <option value={e} className="t-t-c">
                      {e}
                    </option>
                  ))}
                </select>
              </Col>

              <Row>
                <div className="pharmacy-card-box">
                  {pharmacy.map((e, i) => (
                    <Col lg={3} md={4} sm={4} xs={12}>
                      <PharmacyCard key={e?._id} data={e} />
                    </Col>
                  ))}
                  {/* when we dont have any appointment */}
                  {!isApiCall && pharmacy.length === 0 && (
                    <div className="p-d-null-data">
                      <NullDataComponent text={"No product Found."} />
                    </div>
                  )}
                </div>
              </Row>
            </Row>

            {totalCount > 1 && (
              <PaginationB
                page={page}
                setPage={setPage}
                totalCount={totalCount}
              />
            )}
          </Col>
        </Row>
      </Container>
      {isApiCall && <BodyLoader />}
      {!isApiCall && isLoadingCats && <BodyLoader />}
    </>
  );
};

export default Pharmacy;
