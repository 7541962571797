import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FlagIcon from "@mui/icons-material/Flag";
import SideNavbar from "./SideNavbar";
import DashboardTopbar from "../components/dashboardTopBar";
import { imageUrl, URL } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";

const MedicalLab = () => {
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [schedule, setSchedule] = useState([]);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isMobile]=useState(false)
  const [isOpen,setIsOpen]=useState(false)

  // getData
  const getData = async () => {
    const url = URL(`users/profile/6200f579a364af4e7046caf1`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setProfileData(response.data?.data);
      setSchedule(response.data?.schedule);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={3}>
          <SideNavbar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />

          </Col>
          <Col lg={9}>
            <Row>
              <Col lg={12}>
                <DashboardTopbar />
              </Col>
            </Row>
            <Row className="my-5">
              <Col md={5}>
                <div className="MedicalHead">
                  <div className="MedicalLogo">
                    <img src={`${imageUrl}${profileData?.photo}`} />
                  </div>
                  <div>
                    <h5>{`${profileData?.firstName} ${profileData?.lastName}`}</h5>
                    <p>
                      License Number: {profileData?.liscenceNumber || "Nil"}
                    </p>
                    <p>
                      <LocationOnIcon
                        style={{ color: "#EF464B", fontSize: "25px" }}
                      />
                      {profileData?.address || "Nil"}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={3}>
                <ul className="MedicalH">
                  <li>
                    <PersonIcon className="midLabIcon" />
                    Lab Owner Name: {profileData?.labOwnerName || "Nil"}
                  </li>
                  <li>
                    <LocalPhoneIcon className="midLabIcon" />{" "}
                    {`${profileData?.countryCode || '+1'} ${profileData?.phoneNumber}`}
                  </li>
                  <li>
                    <FlagIcon className="midLabIcon" />
                    {profileData?.country || "Nil"}
                  </li>
                </ul>
              </Col>
              <Col md={3}>
                <ul className="MedicalH">
                  <li>
                    <LocationOnIcon className="midLabIcon" />
                    {profileData?.address || "Nil"}
                  </li>
                  <li>
                    <EmailIcon className="midLabIcon" />
                    {profileData?.email || "Nil"}
                  </li>
                  <li>
                    <LocationCityIcon className="midLabIcon" />
                    {profileData?.city || "Nil"}
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className="midLabCard m-auto">
              <div
                className="card text-center p-0"
                style={{ overflow: "hidden" }}
              >
                <div className="card-header midLabCardHeader ">About</div>
                <div className="card-body">
                  <p className="card-text pt-2">
                    {profileData?.shortBio ||
                      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.`}
                  </p>
                </div>
              </div>
            </Row>

            <Row className="">
              <Col>
                <div className="MidLabBtn text-center ">
                  <button className="Med">Message</button>

                  <button
                    className="active"
                    onClick={() => {
                      history.push({
                        pathname: `/doctor-schedule`,
                        state: {
                          schedule,
                          consultationFee: profileData?.consultationFee,
                          profileData,
                        },
                      });
                    }}
                  >
                    Book Appointment
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {isApiCall && <BodyLoader dark />}
    </>
  );
};

export default MedicalLab;
