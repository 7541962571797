import React, { useState,  useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";
import ProfileRightBox from "./ProfileRightBox";

import "../assets/styles/doctordashboard.css";
import Chart from "../components/Chart";
import DashboardTopBar from "../components/dashboardTopBar";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import {
  URL as BaseURL,
  imageUrl,
  URL,
} from "../Config/apiUrl";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ApexChart2 from "../components/ApexChart2";
import BodyLoader from "../components/BodyLoader";
import NullDataComponent from "../components/NullDataComponent";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const DoctorDashboard = () => {
  const history = useHistory();
  let noIcon = true;
  let noRight = true;
  const { access_token: accessToken, user } = useSelector(
    (state) => state.authReducer
  );
  const [statsSelectValue, setStatsSelectValue] = useState("thisMonth");
  const [statsData, setStatsData] = useState([0, 0]);
  const [graphApiCall, setGraphApiCall] = useState(false);

  const [rooms, setRooms] = useState([]);
  const userId = user?._id;

  const [earningGraphApiState, setEarningGraphApiState] = useState("thisWeek");
  const [earningGraphData, setEarningGraphData] = useState([]);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // get rooms
  async function getRooms() {
    const url = `chat/chat-rooms?_id=${userId}`;
    const response = await Get(BaseURL(url), accessToken);
    if (response !== undefined) {
      setRooms(response?.data?.data);
      // console.log("=========>", response);
    }
  }
  useEffect(() => {
    getRooms();
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  // statsApiCall
  const statsApiCall = async () => {
    const url = URL(
      `appointment/get-appointment-stats?status=${statsSelectValue}`
    );
    setGraphApiCall(true);
    const response = await Get(url, accessToken);
    setGraphApiCall(false);
    if (response !== undefined) {
      setStatsData(response.data.data);
    }
  };

  useEffect(() => {
    statsApiCall();
  }, [statsSelectValue]);

  // earningGraphApi
  const earningGraphApi = async () => {
    // TODO: change the API endPoint
    const url = URL(`users/my-earning-graph?status=${earningGraphApiState}`);
    setGraphApiCall(true);
    const response = await Get(url, accessToken);
    setGraphApiCall(false);
    if (response !== undefined) {
      setEarningGraphData(response.data.data);
    }
  };

  useEffect(() => {
    // Todo: uncomment this line
    earningGraphApi();
  }, [earningGraphApiState]);
  return (
    <>
      <Container fluid className="">
        <Row>
          <Col lg={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>

          <Col lg={isMobile ? "7" : "6"}>
            <Row className="my-4">
              <Col lg={12}>
                <DashboardTopBar noIcon={noIcon} noRight={noRight} />
              </Col>
              <Col
                lg={isMobile ? "12" : "3"}
                className="doctor-mobile-screen mt-4"
              >
                <ProfileRightBox />
              </Col>
              <Col md={12}>
                <div className="doctor_dashboard_newyork_block mt-4">
                  <h5 className="bold_custom t-t-c">
                    {user?.role == "doctor"
                      ? user?.hospitalAddress
                      : user?.labAddress || "Hospital Address: Nil"}
           
                  </h5>
                
                  <p>-------------------------------------</p>
                  <h6 className="bold_custom">
                    {user?.role == "doctor"
                      ? `Main Number: ${user?.membershipNumber || "Nil"}`
                      : `Owner Name: ${user?.ownerName || "Nil"}`}
                  </h6>
                </div>
              </Col>
              <Col lg={6}>
                <div className="doctor_dashboard_inbox doc-dashboad-2">
                  <div className="inbox_wrapper">
                    <h5>Inbox</h5>
                    {/* CARD 1 */}
                    {rooms?.map((item, i) => {
                      return (
                        <div
                          className="inbox_card c-p"
                          key={i}
                          onClick={() => {
                            history.push({
                              pathname: "/ChatScreen",
                              state: item,
                            });
                          }}
                        >
                          <div className="inbox_card_wrapper">
                            <div className="card_img">
                              <img src={`${imageUrl}${item?.user2?.photo}`} />
                            </div>
                            <div className="card_content">
                              <div className="name_date">
                                <h6 className="bold_custom font20">
                                  {item?.user2?.displayName}
                                </h6>
                                <p className="small">
                                  {" "}
                                  {moment(item?.lastMessage?.createdAt).format(
                                    "hh:mma"
                                  )}
                                </p>
                              </div>
                              <p className="small msg-para">
                                {item?.lastMessage == null
                                  ? "lorem lorem lorem lorem lorem lorem lorem lorem "
                                  : item?.lastMessage?.text}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {rooms?.length == 0 && (
                      <div className="no-noti-yet-x">
                        <NullDataComponent text={"Inbox is empty."} />
                      </div>
                    )}
                  </div>
                </div>
                {/* NEW FUNCTIONALITY BLOCK */}
                {/* <div className="new_functionality_block">
                          <h5>New Functionality Coming Soon</h5>
                        </div> */}
              </Col>
              <Col lg={6} md={12}>
                {/* EARNINGS BLOCK */}
                <div className="doctor_dashboard_earnings">
                  <div className="earnings_wrapper">
                    <div className="heading_week">
                      <h5 className="font26">Earnings</h5>
                      <select
                        name="week"
                        id="earnings_week"
                        onChange={(e) =>
                          setEarningGraphApiState(e?.target?.value)
                        }
                        value={earningGraphApiState}
                      >
                        <option value="thisWeek">This week</option>
                        <option value="lastWeek">Last week</option>
                      </select>
                    </div>
                    <div className="week-box">
                      <Chart earningGraphData={earningGraphData} />
                    </div>
                  </div>
                </div>

                {/* STATISTICS BLOCK */}
                <div className="doctor_dashboard_statistics">
                  <div className="statistics_wrapper">
                    <div className="heading_month">
                      <h5 className="font26">Statistics</h5>
                      <select
                        name="month"
                        id="statistics_month"
                        onChange={(e) => setStatsSelectValue(e?.target?.value)}
                      >
                        <option value="thisMonth">This Month</option>
                        <option value="lastMonth">Last Month</option>
                      </select>
                    </div>
                    <Row>
                      <Col md={6} className="chart-main-div">
                        <div className="statistics_main_wrapper">
                          <div className="dot_icon"></div>
                          <div className="gendertxt">
                            <p>Male</p>
                            <h5>{parseFloat(statsData[0]).toFixed(2)}%</h5>
                          </div>
                        </div>
                        <div className="statistics_main_wrapper">
                          <div className="dot_icon_red"></div>
                          <div className="gendertxt">
                            <p>Female</p>
                            <h5>{parseFloat(statsData[1]).toFixed(2)}%</h5>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} className="chart-main-div">
                        <ApexChart2 statsData={statsData} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={isMobile ? "4" : "3"} className="doctor-large-screen">
            <ProfileRightBox />
          </Col>
        </Row>
      </Container>

      {graphApiCall && <BodyLoader />}
    </>
  );
};

export default DoctorDashboard;
