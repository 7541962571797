import React from "react";
import { Col } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { fallbackUser, imageUrl } from "../Config/apiUrl";
import { useHistory } from "react-router-dom";

const DoctorMapper = ({ doctor, commission }) => {
  const history = useHistory();

  return (
    <Col
      md={4}
      sm={12}
      className="mt-4 c-p"
      onClick={() =>
        history.push({
          pathname: `/profile`,
          search: `?id=${doctor?._id}`,
        })
      }
    >
      <div className="doctorsNearsCard">
        <div className="doctorsNearsCard-imgBox">
          <img
            src={`${imageUrl}${
              doctor?.photo == "default.jpg" ? "/test/" : doctor?.photo
            }`}
            className="doctorsNearsImg"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = fallbackUser;
            }}
          />
        </div>
        <div className="ms-3">
          <h6 className="t-t-c">{`${doctor?.firstName || ""} ${
            doctor?.middleName || ""
          } ${doctor?.lastName || ""}`}</h6>

          <p>
            Pediatrist{" "}
            <span
              style={{
                color: "#EF464B",
                fontSize: "14px",
                marginLeft: "5px",
              }}
            >
              {doctor?.totalExp || 0} years experience
            </span>
          </p>

          <p>
            <LocationOnIcon style={{ color: "#EF464B", fontSize: "14px" }} />
            {doctor?.address || "Some Address"}
          </p>

          <p>
            Fees{" "}
            <span style={{ color: "#EF464B", fontSize: "14px" }}>
              {parseFloat(doctor?.consultationFee || 1) +
                parseInt(
                  (doctor?.consultationFee *
                    commission[2].adminCommission?.rate) /
                    100
                ) +
                commission[3]?.adminCommission?.rate}
              (SlSh)
            </span>
          </p>
          <p>
            Visitors{" "}
            <span style={{ color: "#EF464B", fontSize: "14px" }}>
              {doctor?.visitors || 0}
            </span>
          </p>
          <p>
            Education{" "}
            <span style={{ color: "#EF464B", fontSize: "14px" }}>
              {Object.keys(doctor?.education).length > 0
                ? doctor?.education
                : "N/A"}
            </span>
          </p>
        </div>
      </div>
    </Col>
  );
};

export default DoctorMapper;
