import {
  ADD_CART_ITEM,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEM,
  CLEAR_CART,
} from "../Actions/actionType";

const initialState = {
  cartItems: [],
  totalAmount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //   Add new item to cart and adjust price
    case ADD_CART_ITEM:
      const newItem = action.payload;
      return {
        ...state,
        cartItems: [...state.cartItems, newItem],
        totalAmount: state.totalAmount + newItem.price,
      };

    //  Update existing item in cart
    case UPDATE_CART_ITEM:
      const incomingItem = action.payload;
      const cartAction = action.cartAction;
      const cartItemsCopy = [...state.cartItems];
      const itemInCartIndex = cartItemsCopy.findIndex(
        (e) => e.id === incomingItem.id
      );
      const itemInCart = cartItemsCopy[itemInCartIndex];

      // Remove: find element and remove it from cartItems and adjust price
      if (cartAction === "REMOVE") {
        //   if quantity is 1 => remove the item completely
        if (itemInCart.quantity === 1) {
          cartItemsCopy.splice(itemInCartIndex, 1);
        } else {
          itemInCart.quantity -= 1;
          cartItemsCopy[itemInCartIndex] = itemInCart;
        }
        return {
          ...state,
          cartItems: cartItemsCopy,
          totalAmount: state.totalAmount - incomingItem.price,
        };
      }
      if (cartAction === "ADD") {
        itemInCart.quantity += 1;
        cartItemsCopy[itemInCartIndex] = itemInCart;
        return {
          ...state,
          cartItems: cartItemsCopy,
          totalAmount: state.totalAmount + incomingItem.price,
        };
      }

    case DELETE_CART_ITEM:
      const incomingItemToDelete = action.payload;
      const cartItemsCopyToDelete = [...state.cartItems];
      const itemInCartIndexToDelete = cartItemsCopyToDelete.findIndex(
        (e) => e.id === incomingItemToDelete.id
      );
      const itemtoDeleteTotalPrice =
        cartItemsCopyToDelete[itemInCartIndexToDelete].price *
        cartItemsCopyToDelete[itemInCartIndexToDelete].quantity;
      cartItemsCopyToDelete.splice(itemInCartIndexToDelete, 1);
      return {
        ...state,
        cartItems: cartItemsCopyToDelete,
        totalAmount: state.totalAmount - itemtoDeleteTotalPrice,
      };

    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
        totalAmount: 0,
      };
    default:
      return state;
  }
};
