import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Get } from "../Axios/AxiosFunctions";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";
import DoctorBoxs from "../components/DoctorBoxs";
import Explorebycategory from "../components/Explorebycategory";
import { imageUrl, URL as baseUrl } from "../Config/apiUrl";

const DoctorPage = () => {
  const [isApiCall, setApiCall] = useState(true);

  useEffect(() => {
    setApiCall(true);
    let timeOut = setTimeout(() => {
      setApiCall(false);
    }, 2000);
    return () => clearTimeout(timeOut);
  }, []);

  const [cmsData, setCmsData] = useState([]);
  const [
    fourEasyStepsToFindYourSolutionCmsData,
    setFourEasyStepsToFindYourSolutionCmsData,
  ] = useState([]);
  const [exploreByCategoryCmsData, setExploreByCategoryCmsData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [appStoreLinkcmsData, setAppStoreLinkcmsData] = useState([]);

  const getHomeCmsData = async () => {
    const url = baseUrl(
      `cms/page/doctor?fourEasyStepsToFindYourSolution=true&exploreByCategory=true&doctor=true&&appStoreLinks=true`
    );

    const response = await Get(url);
    if (response !== undefined) {
      setCmsData(response?.data?.data);
      setFourEasyStepsToFindYourSolutionCmsData(
        response?.data?.fourEasyStepsToFindYourSolutionData
      );
      setExploreByCategoryCmsData(response?.data?.exploreByCategoryData);
      setDoctorData(response?.data?.doctorData);
      setAppStoreLinkcmsData(response?.data?.appStoreLinksData);
    }
  };

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getHomeCmsData();
  }, []);

  return (
    <>
      <div className={`${isApiCall && "opacity-zero"}`}>
        <Container className={`home_banner_b`}>
          <Row className="home_bg_picture home-bg">
            <Col md={6} sm={12} className="home_first_sec_left">
              <h3 className="m-0 text-color font-60 fw-bold whito">
                {cmsData?.sec1Heading}
              </h3>
              <p>{cmsData?.sec1Description}</p>
            </Col>

            <Col md={6}>
              <div className="home-img-container">
                <img
                  className="home-right-first"
                  src={`${imageUrl}${cmsData?.sec1Image}`}
                  style={{ maxWidth: "100%" }}
                  alt="vector mobile"
                />
              </div>
            </Col>
          </Row>

          <Row className="doctor_second">
            <Col md={12}>
              <div className="easySteps_Solution">
                <h3>{cmsData?.sec2Heading}</h3>
                <div className="border_boxes_parent">
                  <Row>
                    {fourEasyStepsToFindYourSolutionCmsData.map(
                      (item, index) => {
                        return (
                          <Col
                            lg={3}
                            md={3}
                            sm={6}
                            xs={6}
                            className={`border_boxes ${
                              index % 2 === 0
                                ? "border_boxes3"
                                : "border_boxes2"
                            } mx-2`}
                          >
                            <div className="innerBorder_box">
                              <h6>{item.title}</h6>
                              <p>{item.description}</p>
                            </div>
                          </Col>
                        );
                      }
                    )}
                  </Row>
       
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <section className="doctorRedBg">
          <Container className="custmContainer">
            <Row>
              <Col md={6}>
                <div className="redBgImg"></div>
                <div className="doctorImg home_laptopImg">
                  <img src={`${imageUrl}${cmsData?.sec3Image}`} alt="" />
                </div>
              </Col>
              <Col md={6}>
                <div className="consult_doctor">
                  <h3>{cmsData?.sec3Heading}</h3>
                  <p>{cmsData?.sec3Description}</p>
                  <div className="storeImg">
                    {appStoreLinkcmsData.map((item, index) => {
                      return (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img
                            fluid
                            src={`${imageUrl}${item?.image}`}
                            className="me-4 storeImages"
                            style={{ maxWidth: "100%" }}
                            alt="Vector png"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <DoctorBoxs doctorData={doctorData} />
        <section className="exploreByCategory-section">
          <Container className="exploreByCategory_Container">
            <Row>
              <Col>
                <div className="exploreByCategory">
                  <h3>{cmsData?.sec4Heading}</h3>
                  <Explorebycategory
                    exploreByCategoryCmsData={exploreByCategoryCmsData}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="book-doc-container">
              <Col md={6}>
                <div className="bookDoctor_Img">
                  <img
                    src={`${imageUrl}${cmsData?.sec5Image}`}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="bookDoctor_text">
                  <h3>{cmsData?.sec5Heading}</h3>
                  <p>{cmsData?.sec5Description}</p>
                  <div className="storeImg">
                    {appStoreLinkcmsData.map((item, index) => {
                      return (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img
                            fluid
                            src={`${imageUrl}${item?.image}`}
                            className="me-4 storeImages"
                            style={{ maxWidth: "100%" }}
                            alt="Vector png"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {isApiCall && <BeforeLoadContentLoader />}
    </>
  );
};

export default DoctorPage;
