import React  from "react";
import { Col, Container, Row, Modal  } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { imageUrl } from "../Config/apiUrl";


const AppointmentDescriptionModal = (props) => {
  let hoursDiff;
  const user = useSelector((state) => state?.authReducer?.user);

  const appointmentWith = ["doctor", "covid19"].includes(user?.role)
    ? props.modalData?.patientId
    : props.modalData?.appointmentWith == "covid19"
    ? props.modalData?.covid19Id
    : props.modalData?.doctorId;

  if (user?.role === "doctor") {
    let endTimeArray = props?.modalData?.appointmentEndTime.split(" ");
    console.log("endTimeArray", "endTimeArray", endTimeArray);
    let endTime = endTimeArray[0];
    endTime = +endTime.split(":")[0];
    let endTimeAMPM = endTimeArray[1];
    if (endTimeAMPM.toLowerCase() === "pm") endTime += endTime;

    let time = moment(props?.modalData?.appointmentDate).toDate();

    let duration = moment.duration(moment().diff(moment(time)));
    hoursDiff = duration.asHours();
  }

  console.log(props);

  return (
    <>
      <style jsx>{`
        .close-icon {
          display: none;
        }
        .modal-backdrop {
          width: 100%;
          height: 100%;
        }
      `}</style>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box appointment_Des_modal small-modal-x"
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="appointmentDespModalBody">
                <div className="appointmentDesrModal_inner">
                  <h4>Appointment Details</h4>
                  <div className="appointmentDesrModal_imgOuter">
                    <img src={`${imageUrl}${appointmentWith?.photo}`} alt="" />
                  </div>
                  <div className="appointmentDespModal_text">
                    {user?.role == "doctor" && (
                      <p className="big">
                        Patient Name:{" "}
                        <span className="paraTimingDes">
                          {" "}
                          {props.modalData?.patientId?.displayName}
                        </span>
                      </p>
                    )}
                    {user?.role == "patient" && (
                      <p className="big">
                        Doctor Name:{" "}
                        <span className="paraTimingDes">
                          {" "}
                          {props.modalData?.doctorId?.displayName}
                        </span>
                      </p>
                    )}
                    <p className="big">
                      Appointment Date:{" "}
                      <span className="paraTimingDes">
                        {" "}
                        {moment(props.modalData?.appointmentDate).format(
                          "YYYY-MM-DD"
                        )}
                      </span>
                    </p>
                    <p className="big">
                      Appointment Time:{" "}
                      <span className="paraTimingDes">
                        {props.modalData?.appointmentStartTime}
                      </span>{" "}
                    </p>
                    {user?.role == "patient" && (
                      <p className="big">
                        Appointment With:{" "}
                        <span className="paraTimingDes">
                          {" "}
                          {props.modalData?.appointmentWith}
                        </span>
                      </p>
                    )}

                    {props.modalData?.otherPatient ? (
                      <>
                        <p className=" anotherPatient">
                          <u>This appointment is book for another patient</u>
                        </p>
                        <p className="big">
                          Patinet Name:{" "}
                          <span className="paraTimingDes">
                            {" "}
                            {props.modalData?.otherPatientName}
                          </span>
                        </p>
                        <p className="big">
                          Patinet Number:{" "}
                          <span className="paraTimingDes">
                            {" "}
                            {props.modalData?.otherPatientNumber}
                          </span>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <span className="paraTimingDes"></span>

                <div
                  className={`modal-btn apppointmentDest_closeBtn ${
                    user?.role === "doctor" &&
                    hoursDiff > 0 &&
                    props?.modalData?.status !== "completed" &&
                    "xd-htc"
                  }`}
                >
                  <button onClick={props.onHide}>{"Close"}</button>
                  {user?.role === "doctor" &&
                    hoursDiff > 0 &&
                    props?.modalData?.status !== "completed" && (
                      <button
                        disabled={props?.isLoading}
                        onClick={props.markAsCompleteHandler.bind(
                          this,
                          props.modalData
                        )}
                      >
                        {props?.isLoading
                          ? "Please Wait..."
                          : "Mark as Complete"}
                      </button>
                    )}
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AppointmentDescriptionModal;
