import React, { useEffect, useState } from "react";
import { Col, Container, FormControl, Row } from "react-bootstrap";
import logo from "../assets/images/logoWhite.png";
import Signup from "../assets/images/Illustration Signup.png";
import loginBgImg from "../assets/images/loginBgImg.png";
import {
  URL,
  apiHeader,
  formRegEx,
  formRegExReplacer,
  validateEmail,
} from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { useDispatch} from "react-redux";
import { saveLoginUserData, saveRequestId } from "../store/Actions/authAction";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const SignUpPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCodeState] = useState("+252");
  const [isApiCall, setIsApiCall] = useState(false);
  const [role] = useState(history.location.state);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);

  useEffect(() => {
    if (!history.location.state) {
      history.goBack();
    }
  }, []);
  // register user
  const registerUser = async (e) => {
    e.preventDefault();
    const url = URL("users/signup");
    let params = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
      phoneNumber: phoneNumber,
      countryCode: countryCodeState,
      role,
    };

 

    // validate
    for (let key in params) {
      if (params[key] == "") {
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} can not be empty.`
        );
      }
    }

    params.completePhoneNumber = `${countryCodeState}${phoneNumber}`;

    // validate email
    if (!validateEmail(email)) return toast.warn("Invalid email address");

    // validate password
    if (password.length < 8)
      return toast.warn("Password must be at least 8 characters long");


    params.middleName = middleName;

    setIsApiCall(true);
    const response = await Post(url, params, apiHeader());
    setIsApiCall(false);
    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data));
      dispatch(saveRequestId(response?.data?.data?.requestId));

      history.replace("/verify");
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="signUpForm">
          <Col lg={6} md={12}>
            <div className="loginImg">
              <img src={Signup} />
            </div>
          </Col>

          <Col lg={6} md={12} className="loginFormSection">
            <div className="loginLogo">
              <img src={logo} />
            </div>

            <h3 className="text-center text-white" style={{ fontSize: "40px" }}>
              Sign Up
            </h3>
            <form className="loginForm" onSubmit={registerUser}>
              <div className="mb-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label c-w"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name Here"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <label htmlFor="exampleInputEmail1" className="form-label c-w">
                  Middle name
                </label>
                <input
                  type="text"
                  placeholder="Middle Name Here"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label c-w">
                  Last name
                </label>
                <input
                  type="text"
                  placeholder="Last Name Here"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label c-w">
                  Email address
                </label>
                <input
                  type="email"
                  placeholder="Email Address Here"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label c-w">
                  Mobile number
                </label>
                {/* Code */}
                <div className="code-input-container">
                  <div className="step_form_col_one">
                    <div className="me-2">
                      <input
                        type="text"
                        placeholder="Country Code"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={`SO ${countryCodeState}`}
                        disabled
                      />
                    </div>
                  </div>
                  <input
                    type="number"
                    placeholder="63xxxxx"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-3 p-r">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label c-w"
                >
                  Password
                </label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="xxxxxxxxxxx"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-eyeLogin"
                  onClick={() => setIsPasswordVisible((p) => !p)}
                >
                  {isPasswordVisible ? (
                    <AiOutlineEyeInvisible size={25} color="#3e5dc1" />
                  ) : (
                    <AiOutlineEye size={25} color="#3e5dc1" />
                  )}
                </span>
              </div>

              <div className="mb-3 p-r">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label c-w"
                >
                  Re-Password
                </label>
                <input
                  type={isRePasswordVisible ? "text" : "password"}
                  placeholder="Retype password"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <span
                  className="password-eyeLogin"
                  onClick={() => setIsRePasswordVisible((p) => !p)}
                >
                  {isRePasswordVisible ? (
                    <AiOutlineEyeInvisible size={25} color="#3e5dc1" />
                  ) : (
                    <AiOutlineEye size={25} color="#3e5dc1" />
                  )}
                </span>
              </div>

            
              <div class="d-grid gap-2 mt-4">
                <button
                  class="btn loginBtn"
                  type="Submit"
                  onClick={registerUser}
                  disabled={isApiCall}
                >
                  {isApiCall ? "Please  Wait..." : "Sign Up"}
                </button>
              </div>
              <h5 className="text-center my-5">
                Already have an account?{" "}
                <Link
                  to="/login"
                  style={{ color: "red", textDecoration: "none" }}
                >
                  SignIn{" "}
                </Link>
              </h5>
              <img src={loginBgImg} className="LoginBgImg" />
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignUpPage;
