import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { apiHeader, URL, recordsLimit } from "../Config/apiUrl";
import { Get, Patch, } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import moment from "moment";
import BodyLoader from "../components/BodyLoader";
import PaginationB from "../components/PaginationB";

import OrderDescriptionModal from "../components/OrderDescriptionModal";
import NullDataComponent from "../components/NullDataComponent";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";
import { toast } from "react-toastify";
import AreYouSureModal from "../components/AreYouSureModal";

var isInitial = false;

const Orders = () => {
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const [isApiCall, setIsApiCall] = useState(false);
  const [myOrders, setMyOrders] = useState([]);
  const [status, setStatus] = useState("all");

  const [openModal, SetOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // cancel appointment
  const [isCancelVerificationModalOpen, setIsCancelVerificationModalOpen] =
    useState(false);
  const [cancelOrderData, setCancelOrderData] = useState(null);
  const [isCancelApiCall, setIsCancelApiCall] = useState(false);

  // get Appointments
  const getAppointments = async (_page) => {
    const url =
      status == "all"
        ? URL(`order?limit=${recordsLimit}&page=${_page}`)
        : URL(`order?limit=${recordsLimit}&page=${_page}&status=${status}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setMyOrders(response?.data?.data);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
  };

  useEffect(() => {
    getAppointments(page);
  }, [page, status]);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  // handleCancelOrder
  const handleCancelOrder = async () => {
    const url = URL(`order/cancel/${cancelOrderData?._id}`);
    setIsCancelApiCall(true);
    const response = await Patch(url, {}, apiHeader(accessToken));
    setIsCancelApiCall(false);

    if (response !== undefined) {
      const ordersCopy = [...myOrders];
      const index = ordersCopy.findIndex(
        (order) => order._id === cancelOrderData._id
      );
      ordersCopy[index].status = "cancelled";
      setMyOrders(ordersCopy);
      toast.success("Order Cancelled Successfully");
      setIsCancelVerificationModalOpen(false);
    }
  };

  return (
    <div>
      <Container fluid className="padding-fluid-container">
        <Row className="margin_zero">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? ("11"):("9")}>
            <Row className="mt-5 margin_zero navbar-padding-x">
              <Col md={12}>
                <DashboardTopBar />
              </Col>
            </Row>

            {/* Screen header */}
            <Row className="my-4 w-100 orders-s-head-sp g-0">
              {/* <Col md={2}></Col> */}
              <Col lg={4}>
                <div className="order-x">
                <h5>My Orders</h5>
                </div>
              </Col>
              <Col lg={3} md={12}>
                <div className="select_place">
                  <select
                    className="form-select b-round t-t-c"
                    aria-label="Default select example"
                    style={{ Width: "100%" }}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={"all"} selected>
                      All Statuses
                    </option>
                    {["active", "completed"].map((e) => (
                      <option value={e} className="t-t-c">
                        {e}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            {/* Table */}
            <Row className="appointmentsTables m-auto mb-5 margin_zero">
              <Col className="p-0">
                <div>
                  <Table responsive="" className="AppointmentTables">
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "#fff",
                          }}
                        >
                          Date
                        </th>
                        <th>No. of Items</th>
                        <th>Estimated Total</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myOrders.map((e, i) => {
                        let duration = moment.duration(
                          moment().diff(e?.createdAt)
                        );
                        let hoursDiff = duration.asHours();
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {moment(e?.createdAt).format("YYYY-MM-DD")}
                              </td>
                              <td>{e?.items?.length} Products</td>
                              <td>
                                {parseFloat(e?.estimatedTotal).toFixed(2)}{" "}
                                (SlSh)
                              </td>
                              <td
                                className={`
                              ${e?.status == "cancelled" && "red-row"} 
                              ${e?.status == "active" && "blue-row"}
                              ${e?.status == "completed" && "green-row"}
                              `}
                              >
                                {e?.status}
                              </td>
                              <td>
                                <VisibilityIcon
                                  className="c-p"
                                  onClick={() => {
                                    setModalData(e);
                                    SetOpenModal(true);
                                  }}
                                />{" "}
                                {/* cancel appointment */}
                                {user?.role === "patient" &&
                                  e?.status === "active" &&
                                  hoursDiff < 24 && (
                                    <span title="Cancel Order">
                                      <ClearIcon
                                        className="c-p"
                                        onClick={() => {
                                          setCancelOrderData(e);
                                          setIsCancelVerificationModalOpen(
                                            true
                                          );
                                        }}
                                      />
                                    </span>
                                  )}{" "}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>

                  {!isApiCall && myOrders.length == 0 && (
                    <div className="orders-s-null-data">
                      <NullDataComponent text={"No Order Found."} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {totalCount > 1 && (
              <PaginationB
                page={page}
                setPage={setPage}
                totalCount={totalCount}
              />
            )}
          </Col>
        </Row>

        <Row className="Appointmentstop"></Row>
      </Container>
      {openModal && (
        <OrderDescriptionModal
          show={openModal}
          modalData={modalData}
          onHide={() => SetOpenModal(false)}
        />
      )}

      {isApiCall && <BodyLoader />}

      {isCancelVerificationModalOpen && (
        <AreYouSureModal
          show={isCancelVerificationModalOpen}
          onHide={() => setIsCancelVerificationModalOpen(false)}
          message={"Do you really you want to cancel this order?"}
          isLoading={isCancelApiCall}
          handleYes={handleCancelOrder}
        />
      )}
    </div>
  );
};

export default Orders;
