import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import item_img1 from "../assets/images/parmacyImage2.png";
import { imageUrl } from "../Config/apiUrl";
import { deleteCartItem, updateCartItem } from "../store/Actions/cartActions";
import numeral from "numeral";

const CartItem = ({ data }) => {
  const dispatch = useDispatch();
  var numeral = require('numeral');
  const cartItems = useSelector((state) => state?.cartReducer?.cartItems);
  let quantityOfThisItemInCart = cartItems.find(
    (e) => e?.id == data?.id
  )?.quantity;

  return (
    <tr>
      <td>
        <div className="order_item">
          <div className="item_img">
            <img src={`${imageUrl}${data?.image}`} />
          </div>
          <div className="item_content">
            <h6>{data?.name}</h6>
            <p>Category: {data?.catId?.catName}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="order_quantity">
          <span
            className="minus_btn c-p"
            onClick={() => dispatch(updateCartItem(data, "REMOVE"))}
          >
            -
          </span>
          <span className="number">{data?.quantity}</span>
          <span
            className="plus_btn c-p"
            onClick={() => {
              if (quantityOfThisItemInCart === data?.stock) {
                return toast.info(
                  "You have reached the maximum stock of this product."
                );
              }
              dispatch(updateCartItem(data, "ADD"));
            }}
          >
            +
          </span>
        </div>
      </td>
      <td>
        <div className="unit_price">
          <h5 className="">{numeral(data?.price).format('0.0a')}(SlSh)</h5>
        </div>
      </td>
      <td>
        <div className="total_price">
          <h5 className="">
             {numeral(data?.price * data?.quantity).format('0.0a')}(SlSh)
          </h5>
        </div>
      </td>
      <td>
        <div className="delete_icon">
          <RiDeleteBin6Line
            className="delete_icon c-p"
            onClick={() => dispatch(deleteCartItem(data))}
          />
        </div>
      </td>
    </tr>
  );
};

export default CartItem;
