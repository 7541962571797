import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ClearIcon from "@mui/icons-material/Clear";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { apiHeader, URL, recordsLimit } from "../Config/apiUrl";
import { Patch, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import moment from "moment";
import BodyLoader from "../components/BodyLoader";
import PaginationB from "../components/PaginationB";
import { IoIosClose } from "react-icons/io";
import AppointmentDescriptionModal from "../components/AppointmentDescriptionModal";
import NullDataComponent from "../components/NullDataComponent";
import { useHistory } from "react-router-dom";
import AreYouSureModal from "../components/AreYouSureModal";
import { toast } from "react-toastify";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

var isInitial = false;

const Appointments = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const [isApiCall, setIsApiCall] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [status, setStatus] = useState("All Statuses");
  const [search, setsearch] = useState("");
  const [date, SetDate] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, SetOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  // cancel appointment
  const [isCancelVerificationModalOpen, setIsCancelVerificationModalOpen] =
    useState(false);
  const [cancelAppointmentData, setCancelAppointmentData] = useState(null);
  const [isCancelApiCall, setIsCancelApiCall] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // get Appointments
  const getAppointments = async (_page) => {
    const url = URL(`appointment?limit=${recordsLimit}&page=${_page}`);
    setIsApiCall(true);
    const response = await Post(
      url,
      {
        ...(status !== "All Statuses" && { status: status }),
        ...(search !== "" && { search: search }),
        ...(date !== null && status !== "previous" && { date: date }),
      },
      apiHeader(accessToken)
    );
    setIsApiCall(false);

    if (response !== undefined) {
      setAppointments(response?.data?.appointments);
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
    }
    isInitial = true;
  };

  useEffect(() => {
    getAppointments(1);
  }, [status, date]);

  useEffect(() => {
    if (isInitial == false) {
      return;
    }
    const timeOut = setTimeout(() => {
      getAppointments(1);
    }, 1000);

    return () => clearTimeout(timeOut);
  }, [search]);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  useEffect(() => {
    if (isInitial == false) {
      return;
    }
    getAppointments(page);

    return () => {
      isInitial = false;
    };
  }, [page]);

  // handleCancelAppointment
  const handleCancelAppointment = async () => {
    const url = URL(`appointment/cancel/${cancelAppointmentData?.id}`);
    setIsCancelApiCall(true);
    const response = await Patch(url, {}, apiHeader(accessToken));
    setIsCancelApiCall(false);

    if (response !== undefined) {
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex(
        (appointment) => appointment.id === cancelAppointmentData.id
      );
      appointmentsCopy[index].status = "cancelled";
      setAppointments(appointmentsCopy);
      toast.success("Appointment Cancelled Successfully");
      setIsCancelVerificationModalOpen(false);
    }
  };

  // markAsCompleteHandler
  const markAsCompleteHandler = async (appointment) => {
    const url = URL(`appointment/complete/${appointment?.id}`);
    setIsCancelApiCall(true);
    const response = await Patch(url, {}, apiHeader(accessToken));
    setIsCancelApiCall(false);

    if (response !== undefined) {
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex(
        (aptmnt) => aptmnt.id === appointment.id
      );
      appointmentsCopy[index].status = "completed";
      setAppointments(appointmentsCopy);
      toast.success("Appointment Marked as Completed Successfully");
      SetOpenModal(false);
    }
  };

  return (
    <div>
      <Container fluid className="padding-fluid-container">
        <Row className="remove-gutter">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="mt-4 navbar-padding-x">
              <Col lg={12}>
                <DashboardTopBar />
              </Col>
            </Row>

            {/* Screen header */}
            <Row className=" my-4 m-auto flex-center appointment_searchBars">
              <Col lg={3} md={12}>
                <h5>Appointments</h5>
              </Col>
              <Col lg={5} className="appointment_main">
                {user?.role != "patient" ? (
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control b-round close_icon-parent"
                      placeholder="Search by name or Phone number"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                    <IoIosClose
                      className="close-icon"
                      onClick={() => setsearch("")}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </Col>
              <Col lg={2}>
                <select
                  className="form-select b-round t-t-c"
                  aria-label="Default select example"
                  style={{ Width: "100%" }}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value={"All Statuses"} selected>
                    All Statuses
                  </option>
                  {[
                    "active",
                    "cancelled",
                    "completed",
                    "upcoming",
                    "previous",
                  ].map((e) => (
                    <option value={e} className="t-t-c">
                      {e}
                    </option>
                  ))}
                </select>
              </Col>
              <Col lg={2}>
                <input
                  type="date"
                  className="datePicker"
                  value={moment(date).format("YYYY-MM-DD")}
                  onChange={(e) => SetDate(e.target.value)}
                />
              </Col>
            </Row>

            {/* Table */}
            <Row className="appointmentsTables  m-auto mb-5">
              <Col className="p-0">
                <div className="appointment_table">
                  <Table
                    responsive="sm"
                    className="AppointmentTables appointTable"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "#fff",
                          }}
                        >
                          Date
                        </th>
                        <th>Time</th>
                        <th>
                          {user?.role === "patient"
                            ? "Doctor Name"
                            : "Patient Name"}
                        </th>
                        <th>Patient Phone</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appointments.map((e, i) => {
                        let duration = moment.duration(
                          moment().diff(e?.createdAt)
                        );
                        let hoursDiff = duration.asHours();
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {moment(e?.appointmentDate).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              <td>{e?.appointmentStartTime}</td>
                              {user?.role === "patient" && (
                                <td>
                                  {e?.appointmentWith == "doctor"
                                    ? e?.doctorId?.displayName
                                    : e?.covid19Id?.displayName}
                                </td>
                              )}
                              {["doctor", "covid19"].includes(user?.role) && (
                                <td>{e?.patientName}</td>
                              )}
                              <td>{e?.patientNumber}</td>
                              <td
                                className={`
                              ${e?.status == "cancelled" && "red-row"} 
                              ${e?.status == "active" && "blue-row"}
                              ${e?.status == "completed" && "green-row"}
                              `}
                              >
                                {e?.status}
                              </td>
                              <td>
                                {/* Show detail */}
                                <span title="Show Detail">
                                  <VisibilityIcon
                                    className="c-p"
                                    onClick={() => {
                                      setModalData(e);
                                      SetOpenModal(true);
                                    }}
                                  />
                                </span>{" "}
                                {/* Reschedule */}
                                {user?.role === "patient" &&
                                  e?.status == "completed" && (
                                    <span title="Reschedule">
                                      <EventNoteIcon
                                        className="c-p"
                                        onClick={() =>
                                          history.push({
                                            pathname: `/profile`,
                                            search: `?id=${
                                              e?.appointmentWith == "covid19"
                                                ? e?.covid19Id?._id
                                                : e?.doctorId?._id
                                            }`,
                                          })
                                        }
                                      />
                                    </span>
                                  )}{" "}
                                {/* cancel appointment */}
                                {user?.role === "patient" &&
                                  e?.status === "active" &&
                                  hoursDiff < 12 && (
                                    <span title="Cancel Appointment">
                                      <ClearIcon
                                        className="c-p"
                                        onClick={() => {
                                          setCancelAppointmentData(e);
                                          setIsCancelVerificationModalOpen(
                                            true
                                          );
                                        }}
                                      />
                                    </span>
                                  )}{" "}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                  {!isApiCall && appointments.length == 0 && (
                    <div className="orders-s-null-data">
                      <NullDataComponent text={"No Appointment Found."} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {totalCount > 1 && (
              <PaginationB
                page={page}
                setPage={setPage}
                totalCount={totalCount}
              />
            )}
          </Col>
        </Row>

        <Row className="Appointmentstop"></Row>
      </Container>
      {openModal && (
        <AppointmentDescriptionModal
          show={openModal}
          modalData={modalData}
          onHide={() => SetOpenModal(false)}
          markAsCompleteHandler={markAsCompleteHandler}
          isLoading={isCancelApiCall}
        />
      )}

      {isCancelVerificationModalOpen && (
        <AreYouSureModal
          show={isCancelVerificationModalOpen}
          onHide={() => setIsCancelVerificationModalOpen(false)}
          message={"Do you really you want to cancel this appointment?"}
          isLoading={isCancelApiCall}
          handleYes={handleCancelAppointment}
        />
      )}

      {isApiCall && <BodyLoader />}
    </div>
  );
};

export default Appointments;
