import React from "react";

const BodyLoader = ({ dark }) => {
  return (
    <div
      className="body-loader"
      style={
        dark && {
          backgroundColor: "rgba(50, 82, 187, 0.95)",
        }
      }
    >
      <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default BodyLoader;
