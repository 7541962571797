import React from "react";
import img from "../assets/images/box.png";

const NullDataComponent = ({ text, noImage }) => {
  return (
    <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
    }}>
      {!noImage && (
        <img
          src={img}
          style={{
            width: "50px",
            marginBottom: '10px'
          }}
        />
      )}
      <p>{text || "No data found."}</p>
    </div>
  );
};

export default NullDataComponent;
