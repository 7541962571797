import React from "react";
import { Container, Row, Col, Image, Table } from "react-bootstrap";

const AboutBox = ({ data }) => {
  return (
    <>
      <section className="about_boxs">
        <Container>
          <Row className="about_boxsRow">
            {data?.map((e, i) => (
              <Col lg={2} md={3} sm={4} xs={12}  key={i}>
                <div className={i % 2 === 0 ? "aboutboxx" : "aboutboxx2"}>
                  <p className="big">{e.title}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutBox;
