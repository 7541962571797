import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";

import { RiEdit2Line } from "react-icons/ri";
import doctor_img from "../assets/images/DoctorMedicine.png";
import visitDoctorImg from "../assets/images/VisitDoctor.png";
import PharmacyImg from "../assets/images/pharmacy.png";
import covidImg from "../assets/images/Covid19.png";
import doctorpic from "../assets/images/drPic.png";
import DashboardTopBar from "../components/dashboardTopBar";
import { useSelector } from "react-redux";
import { apiHeader, imageUrl, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import BodyLoader from "../components/BodyLoader";
import AppointmentSm from "../components/AppointmentSm";
import { useHistory, Link } from "react-router-dom";
import NullDataComponent from "../components/NullDataComponent";
import AppointmentDescriptionModal from "../components/AppointmentDescriptionModal";
import AreYouSureModal from "../components/AreYouSureModal";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const PatientDashboard = () => {
  const history = useHistory();
  const user = useSelector((state) => state?.authReducer?.user);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isApiCall, setIsApiCall] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [appointmentModalData, setAppointmentModalData] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [
    isAppointmentDescriptionModalOpen,
    setIsAppointmentDescriptionModalOpen,
  ] = useState(false);

  // get Appointments
  const getAppointments = async () => {
    const url = URL("appointment");
    setIsApiCall(true);
    const response = await Post(
      url,
      {
        status: "upcoming",
      },
      apiHeader(accessToken)
    );
    setIsApiCall(false);

    if (response !== undefined) {
      setAppointments(response?.data?.appointments);
    }
  };

  useEffect(() => {
    getAppointments();
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  return (
    <>
      <Container fluid className="padding-fluid-container">
        <Row className="doctor-Dashboard-top">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "6"} className="for-mobile-screen">
            <DashboardTopBar noRight />
          </Col>
          <Col
            md={isMobile ? "11" : "3"}
            className="profile_screen for-mobile-screen"
          >
            <div className="dashboard-rightSide">
              <div className="dashborad-profile-box ">
                <div className="dashborad-profile-img-box">
                  <img
                    src={`${imageUrl}${user?.photo}`}
                    alt="img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = doctorpic;
                    }}
                  />
                </div>
                <h6 className="t-t-c">
                  {user?.firstName} {user?.middleName} {user?.lastName}
                </h6>
                <p className="t-t-c">{user?.role}</p>
                <div className="dashborad-profile-inner-box">
                  <span>
                    {" "}
                    <Link
                
                      to={{
                        pathname: `/settings`,
                      }}
                    >
                      {" "}
                      Edit Profile
                    </Link>
                  </span>
                  <span
                    className="icon-red c-p"
                    onClick={() => history.push("/settings")}
                  >
                    <RiEdit2Line className="dashboard-icons" />
                  </span>
                </div>
              </div>
              <div className="Appointments-box">
                <div className="Appointments-box-top">
                  <h6>Appointments</h6>
                  <p
                    className="c-p"
                    onClick={() => history.push("/Appointments")}
                  >
                    See all
                  </p>
                </div>

                {appointments.map((e, i) => (
                  <AppointmentSm
                    key={e?.id}
                    data={e}
                    onClick={() => {
                      setAppointmentModalData(e);
                      setIsAppointmentDescriptionModalOpen(true);
                    }}
                  />
                ))}

                {/* when we dont have any appointment */}
                {!isApiCall && appointments.length === 0 && (
                  <div className="p-d-null-data">
                    <NullDataComponent text={"No Upcoming Appointment."} />
                  </div>
                )}

                {/* when we are loadingintment */}
                {isApiCall && (
                  <div className="p-d-null-data">
                    <NullDataComponent text={"Loading..."} noImage />
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md={isMobile ? "11" : "6"}>
            <div className="doctor-Dashboard-mid-section mt-5">
              {/* top bar */}
              <div className="for-large-screen">
                <DashboardTopBar noRight />
              </div>
              <Row className="dashboard-mid-top-section">
                <Col lg={9} className="address_col">
                  <div className="dashboard-mid-section1">
                    <h5>Book Online Nearby Doctors & Medical Labs</h5>
                    <p>My City: {user?.city || "Nil"}</p>
                  </div>
                </Col>
                <Col lg={3} className="addressImg_col">
                  <div className="doctor-img-box">
                    <img src={doctor_img} alt="img" />
                  </div>
                </Col>
              </Row>
              <Row className="dashboard-visitDoctors-box-top">
                <Col lg={6}>
                  <div
                    className="dashboard-visitDoctors-box c-p"
                    onClick={() => history.push("/search-preferences")}
                  >
                    <div className="visitDoctors-img-box">
                      <img src={visitDoctorImg} alt="img" />
                    </div>
                    <h5>Visit A Doctor</h5>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className="dashboard-Pharmacy-box c-p"
                    onClick={() => history.push("/category")}
                  >
                    <div className="Pharmacy-img-box">
                      <img src={PharmacyImg} alt="img" />
                    </div>
                    <h5>Pharmacy</h5>
                  </div>
                </Col>
              </Row>
              <Row className="dashboard-covid19-box-top">
                <Col lg={12}>
                  <div
                    className="dashboard-covid19-box c-p"
                    onClick={() =>
                      history.push("/profile?id=62e22ce018c4b22370056ef0")
                    }
                  >
                    <div className="covid19-img-box">
                      <img src={covidImg} alt="img" />
                    </div>
                    <h5>Home Visit</h5>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            md={isMobile ? "8" : "3"}
            className="profile_screen for-large-screen"
          >
            <div className="dashboard-rightSide">
              <div className="dashborad-profile-box ">
                <div className="dashborad-profile-img-box">
                  <img
                    src={`${imageUrl}${user?.photo}`}
                    alt="img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = doctorpic;
                    }}
                  />
                </div>
                <h6 className="t-t-c">
                  {user?.firstName} {user?.middleName} {user?.lastName}
                </h6>
                <p className="t-t-c">{user?.role}</p>
                <div className="dashborad-profile-inner-box">
                  <span>
                    {" "}
                    <Link
                 
                      to={{
                        pathname: `/settings`,
                      }}
                    >
                      {" "}
                      Edit Profile
                    </Link>
                  </span>
                  <span
                    className="icon-red c-p"
                    onClick={() => history.push("/settings")}
                  >
                    <RiEdit2Line className="dashboard-icons" />
                  </span>
                </div>
              </div>
              <div className="Appointments-box">
                <div className="Appointments-box-top">
                  <h6>Appointments</h6>
                  <p
                    className="c-p"
                    onClick={() => history.push("/Appointments")}
                  >
                    See all
                  </p>
                </div>

                {appointments.map((e, i) => (
                  <AppointmentSm
                    key={e?.id}
                    data={e}
                    onClick={() => {
                      setAppointmentModalData(e);
                      setIsAppointmentDescriptionModalOpen(true);
                    }}
                  />
                ))}

                {/* when we dont have any appointment */}
                {!isApiCall && appointments.length === 0 && (
                  <div className="p-d-null-data">
                    <NullDataComponent text={"No Upcoming Appointment."} />
                  </div>
                )}

                {/* when we are loadingintment */}
                {isApiCall && (
                  <div className="p-d-null-data">
                    <NullDataComponent text={"Loading..."} noImage />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {isAppointmentDescriptionModalOpen && (
        <AppointmentDescriptionModal
          show={isAppointmentDescriptionModalOpen}
          onHide={() => setIsAppointmentDescriptionModalOpen(false)}
          modalData={appointmentModalData}
        />
      )}
      {false && (
        <AreYouSureModal
          show={false}
        />
      )}

      {isApiCall && <BodyLoader />}
    </>
  );
};

export default PatientDashboard;
