import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SignIn from "../assets/images/Illustration SignIn.png";
import logo from "../assets/images/logoWhite.png";
import loginBgImg from "../assets/images/loginBgImg.png";
import { apiHeader, URL, validateEmail } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";
import { saveLoginUserData, saveRequestId } from "../store/Actions/authAction";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import ForgotPasswordModal from "../components/ForgotPasswordModal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // handelSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = isNaN(email)
      ? {
          email: email.trim(),
          password: password,
        }
      : {
          phoneNumber: `${email}`,
          password: password,
        };

    const isValidEmail = !isNaN(email) ? validateEmail(email) : "";
    if (isValidEmail != "") {
      if (!isValidEmail) {
        return toast.warn(
          "Not a valid email",
          "Please input a valid email address."
        );
      }
    }

    // validate
    if (!validateEmail) return toast.warn("Please enter a valid address");

    if (password.trim() == "") return toast.warn("Please enter your password");

    // call api
    const url = URL("users/login");
    setIsApiCall(true);
    const response = await Post(url, params, apiHeader());
    setIsApiCall(false);
    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data));
      dispatch(saveRequestId(response?.data?.data?.requestId));
      if (response?.data?.data?.user?.isVerified == false) {
        console.log(
          response?.data?.data?.user?.isVerified,
          "response?.data?.data?.user?.isVerified"
        );
        history.push("/verify");
      }

      if (response?.data?.data?.user?.role == "patient")
        return history.push("/Dashboard");

      if (response?.data?.data?.user?.role == "doctor")
        return history.push("/Dashboard");

      if (response?.data?.data?.user?.role == "covid19")
        return history.push("/Dashboard");
    }
  };

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Container fluid className="login-container">
        <Row className="LoginForm">
          <Col lg={6} md={12}>
            <div className="loginImg">
              <img src={SignIn} />
            </div>
          </Col>

          <Col lg={6} md={12} className="loginFormSection">
            <div className="loginLogo">
              <img src={logo} />
            </div>

            <h3 className="text-center text-white" style={{ fontSize: "40px" }}>
              Sign In
            </h3>
            <form className="loginForm" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label c-w">
                  Email address/Phone number
                </label>
                <input
                  type="text"
                  placeholder="example@gmail.com/63xxxxx"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3 p-r">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label c-w"
                >
                  Password
                </label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password here"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-eyeLogin"
                  onClick={() => setIsPasswordVisible((p) => !p)}
                >
                  {isPasswordVisible ? (
                    <AiOutlineEyeInvisible size={25} color="#3e5dc1" />
                  ) : (
                    <AiOutlineEye size={25} color="#3e5dc1" />
                  )}
                </span>
              </div>
             
              <div class="d-grid gap-2 mt-5">
                <button class="btn loginBtn" type="Submit" disabled={isApiCall}>
                  {isApiCall ? "Please Wait..." : "Sign In"}
                </button>
              </div>
              <h5 className="text-center mt-5 login-para">
                Forgot password?{" "}
                <span
                  className="c-p"
                  style={{ color: "red", textDecoration: "none" }}
                  onClick={() => {
                    setIsForgotPasswordModalOpen(true);
                  }}
                >
                  Click Here
                </span>
              </h5>
              <h5 className="text-center mt-3 login-para2">
                Don't have an account?{" "}
                <Link
                  to="/signup-role"
                  style={{ color: "red", textDecoration: "none" }}
                >
                  Signup
                </Link>
              </h5>
              <img src={loginBgImg} className="LoginBgImg" />
            </form>
          </Col>
        </Row>
      </Container>

      <ForgotPasswordModal
        openModal={isForgotPasswordModalOpen}
        closeModal={() => setIsForgotPasswordModalOpen(false)}
      />
    </>
  );
};

export default Login;
