import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../assets/images/footer-logo.png";

import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

import { imageUrl, URL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";

function Footer() {
  const [footerData, setFooterData] = React.useState(null);
  const [socialLinkData, setSocialLinkData] = React.useState([]);

  // getFooterData
  const getFooterData = async () => {
    const url = URL("support?socialLink=true");
    const response = await Get(url);
    if (response !== undefined) {
      setFooterData(response?.data?.data);
      setSocialLinkData(response?.data?.socialLinkData);
    }
  };

  useEffect(() => {
    getFooterData();
  }, []);

  return (
    <>
      <Container fluid className="text-left footer_main footer-bg">
        <Row className="text-left">
          <Col lg={2}></Col>
          <Col
            sm={12}
            lg={3}
            md={12}
            className="footer_first_col footertxtCent footertxtCent1"
          >
            <div className="footer-logo">
              <Image src={logo} className="w-100 h-100" />
            </div>
            <p
              className="font-18 text-white mt-5 text-left"
              style={{ maxWidth: "349px" }}
            >
              {footerData?.footerDesc}
            </p>
            <ul className="list-unstyled text-left mt-4">
              <li className="mb-3">
                <BsFillTelephoneFill className="text-white footer-ul-list-icon" />
                <span className="text-white footer-ul-icon">
                  {footerData?.contact}
                </span>
              </li>
              <li className="">
                <MdEmail className="text-white footer-ul-list-icon" />
                <span className="text-white footer-ul-icon">
                  {footerData?.email}
                </span>
              </li>
            </ul>
          </Col>

          <Col sm={12} lg={2} md={12} className=" footertxtCent footertxtCent2">
            <h6>Quick Links</h6>
            <div className="d-flex flex-column">
              <Link
                to=""
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                About
              </Link>
              <Link
                to="/doctor"
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                Doctor
              </Link>
              <Link
                to="/covid19"
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                Covid-19
              </Link>
              <Link
                to="/pharmacypage"
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                Pharmacy
              </Link>
            </div>
          </Col>
          <Col sm={12} lg={4} md={12} className="footertxtCent footertxtCent3">
            <h6 className="mt-5"></h6>
            <div className="mt-4 d-flex flex-column footer-div-xx">
              <Link
                to="/support"
                className="text-white font-18 text-decoration-none height-40 fw-600"
                style={{
                  marginTop: "8px",
                }}
              >
                Support
              </Link>

              <Link
                to="/login"
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                Login
              </Link>
              <Link
                to="/signup-role"
                className="text-white font-18 text-decoration-none height-40 fw-600"
              >
                Sign Up
              </Link>
            </div>
            <div className="mt-2 d-flex align-items-center footer-socail-icon">
              <p className="text-white m-0">Follow us: </p>
              <div className="text-white">
                {socialLinkData?.map((item, index) => (
                  <a
                    key={index}
                    href={item?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    <img
                      src={`${imageUrl}${item?.image}`}
                      className="footer-ul-list-icon ml-1"
                    />
                  </a>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <p className="text-center text-white copyrights">
          {" "}
          &copy; Copyrights 2022 Daweeye. All rights reserved.
        </p>
      </Container>
    </>
  );
}

export default Footer;
