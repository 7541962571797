import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/images/logoWhite.png";
import Signup from "../assets/images/Illustration Signup.png";
import loginBgImg from "../assets/images/loginBgImg.png";
import { Link } from "react-router-dom";

import pharmacyImg from "../assets/images/patient.png";
import doctorImg from "../assets/images/doctor.png";
import { useHistory } from "react-router-dom";

const SignupChoice = () => {
  const history = useHistory();
  const [role, setRole] = useState("patient");

  // handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    role === "patient"
      ? history.push({
          pathname: "/signup",
          state: role,
        })
      : history.push({
          pathname: "/doctor-signup",
          state: role,
        });
  };

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="signUpForm">
          <Col lg={6} md={12}>
            <div className="loginImg">
              <img src={Signup} />
            </div>
          </Col>

          <Col lg={6} md={12} className="loginFormSection">
            <div className="loginLogo">
              <img src={logo} />
            </div>

            <h3 className="text-center text-white" style={{ fontSize: "40px" }}>
              Sign Up
            </h3>
            <form className="loginForm" onSubmit={handleSubmit}>
              <Row className="cards-row-x">
                <Col md={5} className={"card-x"}>
                  <div
                    className={`card-container-x ${
                      role == "patient" && "card-container-x-selected"
                    }`}
                    onClick={() => setRole("patient")}
                  >
                    <div className="card-img-container-x">
                      <img src={pharmacyImg} alt="pharmacy" />
                    </div>
                    <h5>Patient</h5>
                  </div>
                </Col>
                <Col md={5} className={"card-x"}>
                  <div
                    className={`card-container-x ${
                      role == "doctor" && "card-container-x-selected"
                    }`}
                    onClick={() => setRole("doctor")}
                  >
                    <div className="card-img-container-x">
                      <img src={doctorImg} alt="pharmacy" />
                    </div>
                    <h5>Doctor</h5>
                  </div>
                </Col>
              </Row>
              <div class="d-grid gap-2 mt-4">
                <button
                  class="btn loginBtn"
                  type="Submit"
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              </div>
              <h5 className="text-center my-5">
                Already have an account?{" "}
                <Link
                  to={"/login"}
                  style={{ color: "red", textDecoration: "none" }}
                >
                  SignIn{" "}
                </Link>
              </h5>
              <img src={loginBgImg} className="LoginBgImg" />
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignupChoice;
