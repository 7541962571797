import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { Get } from "../Axios/AxiosFunctions";
import ReactHtmlParser from "react-html-parser";

import { URL as baseUrl } from "../Config/apiUrl";
import BodyLoader from "../components/BodyLoader";

const Support = () => {
  const [loading, setIsLoading] = useState(false);
  const [supportData, setSupportData] = useState();

  const getData = async () => {
    const url = baseUrl(`support`);
    setIsLoading(true);
    const response = await Get(url);
    setIsLoading(false);

    if (response !== undefined) {
      setSupportData(response?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="support_mainContainer">
      <div className="support_Head">
        <h1>Support </h1>
        <p>
          Digital healthcare platforms are proven to be time-saving and an
          appropriate <br /> way of getting telehealth
        </p>
      </div>

      <div className="support_body">
        <Container>
          <Col>
            <div className="support_Div">
              {ReactHtmlParser(supportData?.text)}
            </div>
          </Col>
        </Container>
      </div>

      {loading && <BodyLoader />}
    </div>
  );
};

export default Support;
