import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import chatBoxImage from "../assets/images/chatBoxImage.png";
import { Patch, Get } from "../Axios/AxiosFunctions";

import { apiHeader, imageUrl, URL as baseUrl } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateUser } from "../store/Actions/authAction";

const WithdrawModal = (props) => {
  const [myActiveStep, setMyActiveStep] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [isApiCall, setIsApiCall] = useState(false);
  const [btnDisable, setBtndisable] = useState(true);
  const [commision, setCommision] = useState();

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const dispatch = useDispatch();

  console.log(user);

  const steps = ["", ""];
  // step0
  // step1

  const [isWApiCall, setIsWApiCall] = useState(false);
  const withDrawRequest = async () => {
    if (withdrawAmount > 29 && accountNumber > 0) {
      console.log("first");
      const url = baseUrl("users/withdraw-request");
      setIsApiCall(true);

      const obj = {
        withdrawAmount: withdrawAmount,
        accountNo: accountNumber,
      };
      setIsWApiCall(true);
      const response = await Patch(url, obj, apiHeader(accessToken));
      setIsWApiCall(false);
      console.log("response,", response);

      if (response !== undefined) {
        dispatch(updateUser(response?.data?.data));
        await props?.onHide();
        toast.success("Withdraw request successfully");
      }
      setIsApiCall(false);
    }
  };

  const handleNext = () => {
    if (user?.userWallet?.availableBalance === 0) {
      toast.error("Your Account balance is insufficient");
    } else if (withdrawAmount < 30 || withdrawAmount === undefined) {
      toast.error("Amount to withdraw (Min: $30.00 US)");
    } else if (accountNumber === 0 || accountNumber === undefined) {
      toast.error("Account Number Required");
    }
    if (user?.userWallet?.availableBalance < withdrawAmount) {
      return toast.error("Insufficient Balance");
    } else if (
      withdrawAmount >= 30 &&
      accountNumber > 0 &&
      accountNumber !== undefined
    ) {
      if (myActiveStep === steps.length - 1) {
        return;
      }
      setMyActiveStep(myActiveStep + 1);
    }
  };

  const getData = async () => {
    console.log("function RUn");
    const url = baseUrl(`config/commissions`);
    const response = await Get(url, accessToken);
    console.log("asda", response);

    if (response !== undefined) {
      console.log(response);
      setCommision(response?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box"
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="custmModal_Body">
                <h5>Withdrawing Credit</h5>
                <Stepper
                  activeStep={myActiveStep}
                  nonLinear
                  className="stepperModal"
                >
                  {steps.map((label, index) => (
                    <Step key={label} className={`step${index}`}>
                      <StepLabel></StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {/* Step 1 */}
                {myActiveStep == 0 && (
                  <div className="withdrawModal">
                    <Container className="my-box-s main_cont">
                      <Row className="mainRow_modal">
                        <Col lg={6} className="mainRow_border">
                          <div className="balacnceAmount">
                            <p>
                              Amount to withdraw (Min: 30.00 SLSH) Available
                              Balance:
                            </p>
                          </div>
                          <div className="modal-inp">
                            <input
                              type="number"
                              value={withdrawAmount}
                              onChange={(e) => {
                                setWithdrawAmount(e.target.value);
                              }}
                              placeholder="Enter Ammount"
                            />
                          </div>
                        
                          <div className="paymentMethod">
                            <p className="big">
                              Enter Your WafiPay Account Number{" "}
                            </p>
                            <div className="modal-inp">
                              <input
                                type="number"
                                value={accountNumber}
                                onChange={(e) => {
                                  setAccountNumber(e.target.value);
                                }}
                                placeholder="Enter Your WafiPay Account Number"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <Row>
                            <Col lg={12}>
                              <div className="custmCredit_Withdraw">
                                <p className="big">Withdrawing Credits</p>
                                <p>
                                  Withdrawals are processing twice per month.
                                  You can submit one withdrawal request per
                                  payment cycle.
                                </p>
                              </div>
                              <Row>
                                <Col lg={6}>
                                  <div className="withdrawl_inner">
                                    <p className="big">
                                      If you submit a request...
                                    </p>
                                    <p>Between 1st and 15th of the month</p>
                                    <p>Between 16th and 30/31st of the month</p>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="withdrawl_inner">
                                    <p className="big">
                                      Payment will be sent......
                                    </p>
                                    <p>
                                      Between 16th and 26th (26th at the latest)
                                    </p>
                                    <p>
                                      Between 1st and 10th (10th at the latest)
                                      of the next month
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className="payment_statusCont">
                                    <p className="big">
                                      How to Check Your Payment Status
                                    </p>
                                    <p>
                                      Check your payment status by clicking
                                      Withdrawal History on your My Wallet page.
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                )}

                {/* Step 2 */}
                {myActiveStep == 1 && (
                  <div className="withdrawModal">
                    <div className="prevBtn">
                      <button onClick={() => setMyActiveStep(myActiveStep - 1)}>
                        <ArrowBackIosIcon style={{ marginRight: "-10px" }} />
                      </button>
                    </div>
                    {/* Profile */}
                    <Row className="ProfileRow">
                      <Col lg={12}>
                        <div className="profile_img">
                          <div className="profile_imgRadius">
                            <img src={`${imageUrl}${user?.photo}`} alt="" />
                          </div>
                          <p className="big">Name: {user?.displayName}</p>
                          <p className="big">
                            Amount: {withdrawAmount} Shilling
                          </p>
                          <p className="big">
                            Commision: {commision[0]?.adminCommission?.rate}%
                            (Deduction Amount: {""}
                      
                            {(withdrawAmount *
                              commision[0]?.adminCommission?.rate) /
                              100}
                            )
                          </p>
                          <div className="withdrwaBtnn">
                            <button
                              disabled={isWApiCall}
                              onClick={withDrawRequest}
                            >
                              {isWApiCall ? "Please Wait" : "Withdraw"}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {myActiveStep === 0 && (
                  <div className="modal_nextBtn">
                    <button
                      onClick={() => {
                        handleNext();
                      }}
                      //   disabled={btnDisable}
                      //   style={
                      //     btnDisable
                      //       ? { backgroundColor: "#ccc", cursor: "pointer" }
                      //       : ""
                      //   }
                    >
                      Next
                    </button>
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WithdrawModal;
