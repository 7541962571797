export const LOGIN_SUCCESS = "login-success";
export const LOGOUT_REQUEST = "logout-request";
export const SAVE_REQUEST_ID = "SAVE_REQUEST_ID";
export const UPDATE_USER = "UPDATE_USER";

export const SET_CART_ALL_ITEMS = "SET_CART_ALL_ITEMS";
export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const CLEAR_CART = "CLEAR_CART";
