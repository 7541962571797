import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import moment from "moment";
import { useSelector } from "react-redux";
import DoctorSelectTimeModal from "../components/DoctorSelectTimeModal";
import { Post, Get, Patch } from "../Axios/AxiosFunctions";
import { URL, apiHeader } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BodyLoader from "../components/BodyLoader";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";
import NullDataComponent from "../components/NullDataComponent";

const LabSchedule = () => {
  const [modalShow1, setModalShow1] = React.useState(false);
  const [isApicall, setIsApiCall] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const role = user?.role;
  const [completeDay, setCompleteDay] = useState();
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);

  const [day, setDay] = useState();
  const [finalSchedule, setFinalSchedule] = useState([]);

  // get Schedule
  const getTime = async () => {
    const url = URL("schedule");

    setIsApiCall(true);
    const response = await Get(url, accessToken);

    setIsApiCall(false);
    if (response !== undefined) {
      const res = response?.data;
      let _s = [...schedule];
      schedule.map((item, index) => {
        res?.schedule.map((innerItem, innerIndex) => {
          if (item.day.toLowerCase() == innerItem.day.toLowerCase()) {
            _s.splice(index, 1, innerItem);
          }
        });
      });

      setFinalSchedule(_s);
    }
  };
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  useEffect(() => {
    getTime();
  }, []);

  const addOrUpdateSchedule = async (noOfPatients, startTime, endTime) => {
    const url = completeDay?._id
      ? URL(`schedule/${completeDay?._id}`)
      : URL("schedule");

    let _timings = completeDay?._id
      ? [
          ...completeDay?.timings,
          {
            startTime: moment(startTime).format("hh:mm A"),
            endTime: moment(endTime).format("hh:mm A"),
          },
        ]
      : [
          {
            startTime: moment(startTime).format("hh:mm A"),
            endTime: moment(endTime).format("hh:mm A"),
          },
        ];

    // create params
    let params = {
      day: day,
      timings: _timings,
      numberOfPatients: noOfPatients,
      scheduleFor: user?.role,
    };

    // ai call
    let fn = completeDay?._id ? Patch : Post;

    setIsDeleteApiCall(true);
    const response = await fn(url, params, apiHeader(accessToken));
    setIsDeleteApiCall(false);
    if (response !== undefined) {
      const finalScheduleCopy = [...finalSchedule];
      const index = finalScheduleCopy.findIndex(
        (item) => item.day.toLowerCase() == day.toLowerCase()
      );

      if (index !== -1) {
        finalScheduleCopy[index].timings = response?.data?.data?.timings;
        finalScheduleCopy[index].numberOfPatients =
          response?.data?.data?.numberOfPatients;
        finalScheduleCopy[index]._id = response?.data?.data?._id;
        setFinalSchedule(finalScheduleCopy);
      }

      console.log(finalScheduleCopy[index], "finalScheduleCopy[index]");

      setModalShow1(false);
      toast.success(
        `schedule ${completeDay?.day ? "Updated" : "Added"} successfully.`
      );
      setDay();
      setCompleteDay();
    }
  };

  // handleDeleteSchedule
  const handleDeleteSchedule = async (id, _completedDay) => {
    const url = URL(`schedule/${_completedDay?._id}`);
    let timingsCopy = [..._completedDay?.timings];
    timingsCopy = timingsCopy.filter((e) => e?._id !== id);

    let params = {
      ..._completedDay,
      timings: timingsCopy,
    };

    setIsDeleteApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsDeleteApiCall(false);
    if (response !== undefined) {
      const finalScheduleCopy = [...finalSchedule];
      const selectedDayIndex = finalScheduleCopy.findIndex(
        (e) => e?.day === _completedDay?.day
      );
      finalScheduleCopy[selectedDayIndex] = params;
      setFinalSchedule(finalScheduleCopy);
      toast.success(`schedule deleted successfully.`);
      setDay();
      setCompleteDay();
    }
  };

  return (
    <>
      <Container fluid className="dashboard ">
        <Row>
          <Col lg={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col lg={isMobile ? "11" : "9"}>
            <Row className="mt-4 ">
              <Col lg={12} className="navbar-padding-xx">
                <DashboardTopBar />
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-between mt-5 Schedule-x"
              >
                <h5>Schedule</h5>
              </Col>
              {/* map */}
              <Col lg={12}>
                <Row className="schdulors">
                  {finalSchedule?.map((e, i) => {
                    return (
                      <Col className="schedule g-0" key={i}>
                        {role == "doctor" || role == "covid19" ? (
                          <button
                            type="button"
                            className="schedule-btn-blue"
                            onClick={() => {
                              setCompleteDay(e);
                              setDay(e.day);
                              setModalShow1(true);
                            }}
                          >
                            {e?.day}
                          </button>
                        ) : (
                          <button type="button" className="schedule-btn-blue">
                            {e?.day}
                          </button>
                        )}


                        {e?.timings?.map((sch, i2) => {
                          return (
                            <button
                              key={i2}
                              type="button"
                              style={{
                                position: "relative",
                              }}
                              className="schedule-btn-pink timing-close-icon-parent"
                            >
                              {sch?.startTime} - {sch?.endTime}
                              <div
                                className="timing-close-icon"
                                onClick={() => {
                                  console.log(sch?._id, e);
                                  handleDeleteSchedule(sch?._id, e);
                                }}
                              >
                                ❌
                              </div>
                            </button>
                          );
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </Col>

              {finalSchedule?.every((e) => e?.timings?.length === 0) && (
                <div className="mt-5">
                  <NullDataComponent text={"Schedule not added yet."} />
                </div>
              )}

              {modalShow1 && (
                <DoctorSelectTimeModal
                  show={modalShow1}
                  onHide={() => setModalShow1(false)}
                  day={day}
                  completeDay={completeDay}
                  isLoading={isDeleteApiCall}
                  onSubmit={addOrUpdateSchedule}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Container>

      {isApicall && <BodyLoader dark />}
      {isDeleteApiCall && <BodyLoader />}
    </>
  );
};

export default LabSchedule;

let schedule = [
  {
    day: "Saturday",
    timings: [],
  },
  {
    day: "Sunday",
    timings: [],
  },
  {
    day: "Monday",
    timings: [],
  },
  {
    day: "Tuesday",
    timings: [],
  },
  {
    day: "Wednesday",
    timings: [],
  },
  {
    day: "Thursday",
    timings: [],
  },
  {
    day: "Friday",
    timings: [],
  },
];
