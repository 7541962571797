import "./App.css";
import Home from "./Pages/Home";
import "./assets/styles/style.css";
import "./assets/styles/AboutUs.css";
import "./assets/styles/header.css";
import "./assets/styles/solutionInOneApp.css";
import "./assets/styles/footer.css";
import "./assets/styles/peoplesSaysAboutUs.css";
import "./assets/styles/signupChoice.css";
import "./assets/styles/notificationMenu.css";
import "./assets/styles/checkout.css";
import "./assets/styles/withdraw.css";
import "./assets/styles/responsive.css";
import "./assets/styles/responsive2.css";
import "./assets/styles/chatScreen.css";
import "./assets/styles/doctorPage.css";
import "./assets/styles/covid19.css";
import "./assets/styles/Support.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Dashboard from "./Pages/Settings";
import About from "./Pages/AboutUs";
import Appointments from "./Pages/Appointments";
import DoctorsNear from "./Pages/DoctorsNear";
import MedicalLab from "./Pages/MedicalLab";
import Pharmacy from "./Pages/Pharmacy";
import BookOnline from "./Pages/BookOnline";
import DoctorScheduleScreen from "./Pages/DoctorScheduleScreen";
import ChatScreen from "./Pages/ChatScreen";
import PatientDashboard from "./Pages/PatientDashboard";
import SelectPreferencesForSearch from "./Pages/SelectPreferencesForSearch";
import DoctorDetail from "./Pages/DoctorDetail";
import LabSchedule from "./Pages/LabSchedule";
import EnterCode from "./Pages/EnterCode";
import SignupChoice from "./Pages/SignupChoice";
import DoctorSignin from "./Pages/DoctorSignin";
import DoctorDashboard from "./Pages/Doctordashboard";
import DashboardProfile from "./Pages/DashboardProfile";
import Checkout from "./Pages/Checkout";
import Setting from "./Pages/Settings";
import PaymentScreen from "./Pages/PaymentScreen";
import ShippingDetails from "./Pages/ShippingDetails";
import LabServices from "./Pages/LabServices";
import DoctorPage from "./Pages/DoctorPage";
import "./assets/styles/responsive148.css";
import { Beforeunload  } from "react-beforeunload";

// toast.configure
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Redux
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/lib/integration/react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Orders from "./Pages/Orders";
import ProductCategory from "./Pages/ProductCategory";
import Covid19 from "./Pages/Covid19";
import PharmacyPage from "./Pages/PharmacyPage";
import Support from "./Pages/Support";

function App() {
  const history = useHistory();
  const user = useSelector((state) => state?.authReducer?.user);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);

 
  useEffect(() => {
    const initialValue = document.body.style.zoom;

    // Change zoom level on mount
    document.body.style.zoom = "90%";

    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    };
  }, []);

  const removeApplicationData = () => {
    if (window) {
      // NextJS is ServerSideRendering, therefore the window-check.
      localStorage.clear();
    }
  };

  return (
    <Beforeunload onBeforeunload={removeApplicationData}>
      <div>
        <ToastContainer />
        <Router>
          {user?.isVerified === false && accessToken ? (
            <Switch>
              <Route exact path="/verify">
                <EnterCode />
              </Route>
              <Redirect to="/verify" />
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/">
                <Header />
                <Home />
                <Footer />
              </Route>
              <Route exact path="/doctor">
                <Header />
                <DoctorPage />
                <Footer />
              </Route>
              {/* login */}
              <Route exact path="/login">
                <Header />
                <Login />
                <Footer />
              </Route>
              {/* signup-role */}
              <Route exact path="/signup-role">
                <SignupChoice />
                <Footer />
              </Route>
              {/* DoctorLogin */}
        
              {/* SignUp */}
              <Route exact path="/SignUp">
                <Header />
                <SignUp />
                <Footer />
              </Route>

              {/* DoctorLogin */}
              <Route exact path="/doctor-signup">
                <DoctorSignin />
                <Footer />
              </Route>

              {/* DoctorDashboard */}
        

              <Route exact path="/verify">
                <EnterCode />
              </Route>
              {/* Dashboard => protected */}
              <Route exact path="/Dashboard">
                {user?.role == "patient" ? (
                  <PatientDashboard />
                ) : (
                  <DoctorDashboard />
                )}
                <Footer />
              </Route>
              {/* about */}
              <Route exact path="/about">
                <Header />
                <About />
                <Footer />
              </Route>
              {/* DoctorsNear => protected*/}
              <Route exact path="/DoctorsNear">
                <DoctorsNear />
                <Footer />
              </Route>
              {/* Appointments */}
              <Route exact path="/Appointments">
                <Appointments />
                <Footer />
              </Route>
              {/* orders */}
              <Route exact path="/orders">
                <Orders />
                <Footer />
              </Route>
              {/* Medicallab */}
              <Route exact path="/Medicallab">
                <MedicalLab />
                <Footer />
              </Route>
              {/* Doctor => protected */}
              <Route exact path="/search-preferences">
                <SelectPreferencesForSearch />
                <Footer />
              </Route>
              {/* DoctorDetail */}
              <Route exact path="/profile">
                <DoctorDetail />
                <Footer />
              </Route>
              {/* Pharmacy */}
              <Route exact path="/Pharmacy">
                {/* <Header /> */}
                <Pharmacy />
                <Footer />
              </Route>
              <Route exact path="/category">
                {/* <Header /> */}
                <ProductCategory />
                <Footer />
              </Route>
              {/* LabSchedule */}
              <Route exact path="/manage-schedule">
                <LabSchedule />
                <Footer />
              </Route>
              {/* BookOnline */}
              <Route exact path="/BookOnline">
                <Header />
                <BookOnline />
                <Footer />
              </Route>
              {/* DoctorScheduleScreen */}
              <Route exact path="/doctor-schedule">
                <DoctorScheduleScreen />
                <Footer />
              </Route>
              {/* ChatScreen */}
              <Route exact path="/ChatScreen">
                <ChatScreen />
                {/* <Footer /> */}
              </Route>
              {/* settings */}
              <Route exact path="/settings">
                <Setting />
                <Footer />
              </Route>
              {/* Checkout */}
              <Route exact path="/checkout">
                <Checkout />
                <Footer />
              </Route>
              {/* payment */}
              <Route exact path="/payment">
                <PaymentScreen />
                <Footer />
              </Route>
              {/* ShippingDetails */}
              <Route exact path="/shippingdetails">
                <ShippingDetails />
                <Footer />
              </Route>

              {/* chat screen */}
              <Route exact path="/chatscreen">
                <ChatScreen />
                <Footer />
              </Route>
              {/* chat screen */}
              <Route exact path="/services">
                <LabServices />
                <Footer />
              </Route>
              <Route exact path="/covid19">
                <Header />
                <Covid19 />
                <Footer />
              </Route>
              <Route exact path="/pharmacypage">
                <Header />
                <PharmacyPage />
                <Footer />
              </Route>
              <Route exact path="/support">
                <Header />
                <Support />
                <Footer />
              </Route>
            </Switch>
          )}
        </Router>
      </div>
    </Beforeunload>
  );
}

export default App;
