import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { apiHeader, URL, validateEmail } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";

const ForgotPasswordModal = ({ closeModal, openModal }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      toast.error(`Email is not valid`);
      return;
    }

    const url = URL("users/forgotPasswordWithLink");
    setIsLoading(true);
    const response = await Post(url, { email }, apiHeader());
    setIsLoading(false);

    if (response !== undefined) {
      closeModal();
      setEmail("");
      toast.success("Password reset link sent to your email");
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box appointment_Des_modal small-modal-x-w100 modal-msg"
              // size="md"
              show={openModal}
              onHide={closeModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <h5 className="f-m-h">Forgot Password</h5>
              </Modal.Header>
              <Modal.Body className="appointmentDespModalBody msg ">
                <form className="loginForm" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-3"
                    >
                      Enter your email, we will send you a password link to
                      reset your password.
                    </label>
                    <input
                      type="email"
                      placeholder="Your email here"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="modal-btn apppointmentDest_closeBtn d-flex align-items-center justify-content-center mt-4">
                    <button
                      className="px-290"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending Email" : "Send Email"}
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPasswordModal;
