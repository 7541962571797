import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";

import "../assets/styles/doctordashboard.css";
import DashboardTopBar from "../components/dashboardTopBar";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiShoppingCartLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import CartItem from "../components/CartItem";
import NullDataComponent from "../components/NullDataComponent";
import { useHistory } from "react-router-dom";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";
import numeral from "numeral";

const Checkout = () => {
  const history = useHistory();
  const cartItems = useSelector((state) => state?.cartReducer?.cartItems);
  const totalAmount = useSelector((state) => state?.cartReducer?.totalAmount);
  const [isMobile,setIsMobile]=useState(false)
  const [isOpen,setIsOpen]=useState(false)
  var numeral = require('numeral');
  //   handleProceedToPayment
  const handleProceedToPayment = () => {
    if (cartItems.length > 0) {
      history.push("/shippingdetails");
    }
  };
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  return (
    <>
      <Container fluid className="">
        <Row>
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />
          </Col>
          <Col md={isMobile ? "11" : "9"} className="main_content mt-4">
            <Row>
              <Col md={12}>
                <DashboardTopBar />
              </Col>
              <Col md={12}>
                <div className="checkout-x">
                  <h5 className="font40 bold_custom checkout_heading">
                    Checkout
                  </h5>
                </div>
              </Col>
              <Col lg={8} md={12} >
                <div className="checkout_table">
                  <table className="table">
                    <tr>
                      <th className="font24">
                        <span>
                          <RiShoppingCartLine className="cart_icon" />
                        </span>
                        Order Summary
                      </th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Total Price</th>
                    </tr>
                    {cartItems.map((item) => {
                      return <CartItem data={item} key={item?.id} />;
                    })}
                  </table>

                  {cartItems.length === 0 && (
                    <div className="null-data-checkout-x">
                      <NullDataComponent
                        text={"Please add an item in cart to continue."}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={4} md={12}>
                <div className="summary_block">
                  <h4 className="font40 bold_custom">Summary</h4>
                  <hr />
                  <div className="summary_content">
                  
                    <div className="summary__content">
                      <p>Total Items</p>
                      <p>{cartItems?.length}</p>
                    </div>
                    <div className="summary__content">
                      <p>Total</p>
                      <p>{numeral(totalAmount).format('0.0a')} (SlSh)</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`proceed_btn c-p ${
                    cartItems?.length === 0 && "proceed_btn_disabled"
                  }`}
                  onClick={handleProceedToPayment}
                >
                  <a>Proceed to Payment</a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Checkout;
