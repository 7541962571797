import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";
import {
  apiHeader,
  fallbackUser,
  imageUrl,
  URL as baseUrl,
} from "../Config/apiUrl";
import { Get, Patch } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { saveLoginUserData, updateUser } from "../store/Actions/authAction";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import moment from "moment";
import InputPassword from "../components/InputPassword";

const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authReducer?.user);
  const role = useSelector((state) => state?.authReducer?.user?.role);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading] = useState(false);
  const [city, setCity] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [firstName, setFirstName] = useState(user?.firstName);
  const [midName, setMidName] = useState(user?.middleName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [usercity, setUserCity] = useState(user?.city);
  const [gender, setGender] = useState(user?.gender);
  const [bio, setBio] = useState(user?.shortBio);

  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");

  // {doctor states}
  const [doctorAddress, setDoctorAddress] = useState(user?.hospitalAddress);
  const [dob, setDob] = useState(user?.dob);
  const [address, setAddress] = useState(user?.address);
  const [undergraduateCollege, setUndergraduateCollege] = useState(
    user?.undergraduateCollege
  );
  const [undergraduateMajor, setUndergraduateMojar] = useState(
    user?.undergraduateMajor
  );
  const [graduateCollege, setGraduateCollege] = useState(user?.graduateCollege);
  const [typeOfDegree, setTypeOfDegree] = useState(user?.typeOfDegree);
  const [totalExperience, setTotalExperience] = useState(user?.totalExp);
  const [consultationFee, setConsultationFee] = useState(user?.consultationFee);
  const [doctorSpecialization, setDoctorSpecialization] = useState(() =>
    user?.doctorSpecialization?.map((e) => {
      return {
        name: e,
      };
    })
  );
  const [yearOfCompletion, setYearOfCompletion] = useState(
    user?.yearOfCompletion
  );

  const [updateImage, setUpdateImage] = useState(`${imageUrl}${user?.photo}`);
  const inputRef = useRef(null);

  // Covid19 states
  const [labName] = useState(user?.labName);
  const [ownerName, setOwnerName] = useState(user?.ownerName);
  const [liscenceNumber, setLiscenceNumber] = useState(user?.licenseNumber);
  const [labWorkingHours] = useState(user?.labWorkingHours);
  const [labAddress, setLabAddress] = useState(user?.labAddress);
  const [education, setEducation] = useState(user?.education);
  const [loadings, setLoadings] = useState(false);

  // get setting
  const getCity = async () => {
    const url = baseUrl(`city?noPagination=true`);
    setLoadings(true);
    const response = await Get(url, accessToken);
    setLoadings(false);

    if (response !== undefined) {
      setCity(response?.data?.data);
    }
  };

  // get Specialization
  const getSpecialization = async () => {
    const url = baseUrl(`specialization?noPagination=true`);
    setLoadings(true);
    const response = await Get(url, accessToken);
    setLoadings(false);

    if (response !== undefined) {
      setSpecialization(response?.data?.data);
    }
  };
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  useEffect(() => {
    getCity();
    getSpecialization();
  }, []);

  // update profile
  const updateProfile = async () => {
    const url = baseUrl(`users/updateMe`);
    let params = {
      firstName: firstName,
      lastName: lastName,
      middleName: midName,
      displayName: `${firstName} ${midName} ${lastName}`,
      city: usercity,
      shortBio: bio,
      ...(["doctor", "patient"].includes(role) && { gender }),
      ...(role === "covid19" && { labName }),
      ...(role === "covid19" && { ownerName }),
      ...(role === "covid19" && { licenseNumber: liscenceNumber }),
      ...(role === "covid19" && { labAddress }),
      ...(role === "covid19" && { startTime: labWorkingHours?.startTime }),
      ...(role === "covid19" && { endTime: labWorkingHours?.endTime }),
      ...(role === "doctor" && { dob: dob }),
      ...(role === "doctor" && { hospitalAddress: doctorAddress }),
      ...(role === "doctor" && { address: address }),
      ...(role === "doctor" && { typeOfDegree: typeOfDegree }),
      ...(role === "doctor" && { undergraduateMajor: undergraduateMajor }),
      ...(role === "doctor" && { graduateCollege: graduateCollege }),
      ...(role === "doctor" && { yearOfCompletion: yearOfCompletion }),
      ...(role === "doctor" && { totalExp: totalExperience }),
      ...(role === "doctor" && { consultationFee: consultationFee }),
      ...(role === "doctor" && { education: education }),
    };

    for (let key in params) {
      if (
        params[key] == "" ||
        params[key] == null ||
        params[key] == undefined
      ) {
        return toast.warn(`${key} is required.`);
      }
    }

    // formData here
    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }

    if (typeof updateImage == "object") {
      formData.append("photo", updateImage);
    }

    // only if doctor
    if (user?.role == "doctor") {
      if (doctorSpecialization.length == 0)
        return toast.warn("Please select atleast one specialization.");
      let _specialization = [];
      doctorSpecialization.map((e) => _specialization.push(e?.name));
      for (let key in _specialization) {
        formData.append("doctorSpecialization[]", _specialization[key]);
      }
    }

    setLoadings(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setLoadings(false);

    if (response !== undefined) {
      toast.success("Profile updated successfully");
      dispatch(updateUser(response?.data?.data?.user));
    }
  };
  // handlePasswordSubmit
  const handlePasswordChange = async () => {
    const url = baseUrl("users/updateMyPassword");

    const params = {
      passwordCurrent,
      password,
      passwordConfirm,
    };

    for (let key in params)
      if (params[key] === "") return toast.warn(`All fields are required.`);

    setIsApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsApiCall(false);

    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data));
      toast.success("Password updated successfully.", "top-right");
      // empty states
      setPasswordCurrent("");
      setPasswordConfirm("");
      setPassword("");
    }
  };

  return (
    <div>
      <Container fluid className="dashboard padding-fluid-container">
        <Row className="m-0">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="mt-5 navbar-padding-x">
              <Col md={12}>
                <DashboardTopBar />
              </Col>
            </Row>
            <Row className="my-4 setting-x">
              <Col>
                <h5>Settings</h5>
              </Col>
            </Row>
            <Row className="d-flex dashboadrd_setting mb-5 me-2">
              <Row className="personalInfo m-auto ">
                <Col>
                  <h2 className="text-white p-3 ps-5">Personal Information</h2>
                </Col>
              </Row>

              {/* Image */}
              <Row className="my-5 m-auto ">
                <Col>
                  <Row className="d-flex ">
                    <Col md={2}>
                      {typeof updateImage === "object" ? (
                        <div className="cameraicon m-auto">
                          <img src={URL.createObjectURL(updateImage)} />
                        </div>
                      ) : updateImage !== "" ? (
                        <div className="cameraicon m-auto">
                          <img
                            src={
                              user?.photo == "default.jpg"
                                ? fallbackUser
                                : updateImage
                            }
                          />
                        </div>
                      ) : (
                        <div className="cameraicon m-auto">
                          <CameraAltIcon className="CameraAltIcon" />
                        </div>
                      )}
                    </Col>

                    <Col md={10}>
                      <div className="text-left ms-4 UploadProfile">
                        <label
                          class="setting_fileupload "
                          onClick={() => {
                            inputRef.current.click();
                          }}
                        >
                          <div className="setting-innerDiv_fileUpload ">
                            <div className="d-flex j-center a-center  c-p">
                              <span>Upload Image </span>
                            </div>
                          </div>
                        </label>
                        <p>
                          This will be displayed to other users when they view
                          your profile. Max size: 2MB
                        </p>
                        <input
                          ref={inputRef}
                          type="file"
                          size="2000000"
                          className="file_upload_form3rd"
                          onChange={(e) => {
                            if (e.target.files?.length > 0) {
                              if (
                                ![
                                  "image/jpeg",
                                  "image/png",
                                  "image/jpg",
                                ].includes(e.target.files[0].type)
                              ) {
                                return toast.error(
                                  "Please upload a valid image. [jpg and png formats only]"
                                );
                              }
                            }

                            // max size 2MB
                            if (e.target.files[0]?.size / 1024 / 1024 > 2)
                              return toast.error(
                                "Please upload a valid image. [Max size: 2MB]"
                              );
                            setUpdateImage(e.target.files[0]);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="m-auto">
                {/* firstname */}
                <Col md={6} sm={12}>
                  <div className="mb-4">
                    <label className="mb-2 ms-2">First Name :</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </Col>

                {/* middle name */}
                <Col md={6} sm={12}>
                  <div className="mb-4">
                    <label className="mb-2 ms-2">Middle Name :</label>
                    <input
                      type="text"
                      placeholder="Middle Name"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={midName}
                      onChange={(e) => setMidName(e.target.value)}
                    />
                  </div>
                </Col>

                {/* last name */}
                <Col md={6} sm={12}>
                  <div className="mb-4">
                    <label className="mb-2 ms-2">Last Name :</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </Col>

                {/* email */}
                <Col md={6} sm={12}>
                  <div className="mb-4">
                    <label className="mb-2 ms-2">Email Address :</label>
                    <input
                      type="email"
                      value={user?.email}
                      placeholder="Email Address"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                    />
                  </div>
                </Col>

                {/* country code */}
                <Col md={6} sm={12}>
                  <label className="mb-2 ms-2">Phone Number :</label>
                  <div className="input-group mt-2 mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text city-code-span">
                        SO +252
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control city-code-inp"
                      aria-label="Large"
                      aria-describedby="inputGroup-sizing-sm"
                      value={user?.phoneNumber}
                      disabled
                    />
                  </div>
                </Col>

                {/* city */}
                <Col md={6} sm={12}>
                  <div className="mb-4">
                    <label className="mb-2 ms-2">Select city :</label>

                    <select
                      className="form-select"
                      aria-label="Default select example"
                      placeholder="Select city...."
                      value={usercity}
                      onChange={(e) => setUserCity(e.target.value)}
                    >
                      {city.map((e, i) => (
                        <option value={e?.name}>{e?.name}</option>
                      ))}
                    </select>
                  </div>
                </Col>

                {/* gender */}
                {["patient", "doctor"].includes(role) && (
                  <Col md={6} sm={12}>
                    <div className="mb-4">
                      <label className="mb-2 ms-2">Select Gender :</label>

                      <select
                        class="form-select"
                        aria-label="Default select example"
                        placeholder="Select Gender...."
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </Col>
                )}

                {role === "doctor" && (
                  <>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Date of birth :</label>

                        <input
                          type="date"
                          placeholder="Date of birth"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={moment(dob).format("YYYY-MM-DD")}
                          onChange={(e) => setDob(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Doctor Address :</label>

                        <input
                          type="text"
                          placeholder="Doctor Address"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={doctorAddress}
                          onChange={(e) => setDoctorAddress(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Address :</label>

                        <input
                          type="text"
                          placeholder="Address"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">
                          Undergraduate College :
                        </label>

                        <input
                          type="text"
                          placeholder="Undergraduate College"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={undergraduateCollege}
                          onChange={(e) =>
                            setUndergraduateCollege(e.target.value)
                          }
                        />
                      </div>
                    </Col>

                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">
                          Undergraduate Major :
                        </label>

                        <input
                          type="text"
                          placeholder="Undergraduate Major"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={undergraduateMajor}
                          onChange={(e) =>
                            setUndergraduateMojar(e.target.value)
                          }
                        />
                      </div>
                    </Col>

                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Graduate College :</label>

                        <input
                          type="text"
                          placeholder="Graduate College"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={graduateCollege}
                          onChange={(e) => setGraduateCollege(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Type of Specailty :</label>

                        <input
                          type="text"
                          placeholder="Type of Degree"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={typeOfDegree}
                          onChange={(e) => setTypeOfDegree(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">
                          Date of Graduation :
                        </label>

                        <input
                          type="date"
                          placeholder="Year of Completion"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={moment(yearOfCompletion).format("YYYY-MM-DD")}
                          onChange={(e) => setYearOfCompletion(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Total Experience :</label>

                        <input
                          type="text"
                          placeholder="Total Experience"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={totalExperience}
                          onChange={(e) => setTotalExperience(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">
                          Higest Education/degree :
                        </label>

                        <input
                          type="text"
                          placeholder="Higest Education/degree"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={education}
                          onChange={(e) => setEducation(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Consultation Fee :</label>

                        <input
                          type="text"
                          placeholder="Consultation Fee"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={consultationFee}
                          onChange={(e) => setConsultationFee(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={12} sm={12}>
                      {/* <label className="mb-2 ms-2">Middle Name :</label> */}

                      <div className="my-4">
                        <label>Specialization</label>
                        <Select
                          isMulti
                          name="colors"
                          options={specialization}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={doctorSpecialization}
                          onChange={(e) => setDoctorSpecialization(e)}
                        />
                      </div>
                    </Col>
                  </>
                )}

                {role === "covid19" && (
                  <>
             
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">
                          Enter Services Type :
                        </label>

                        <input
                          type="text"
                          placeholder="Enter Services Type"
                          className="form-control"
                          value={ownerName}
                          onChange={(e) => setOwnerName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">
                          Enter Emergency call Number :
                        </label>

                        <input
                          type="text"
                          placeholder="Enter Emergency call Number"
                          className="form-control"
                          value={liscenceNumber}
                          onChange={(e) => setLiscenceNumber(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="mb-4">
                        <label className="mb-2 ms-2">Enter lab Address :</label>

                        <input
                          type="text"
                          placeholder="Enter lab Address"
                          className="form-control"
                          value={labAddress}
                          onChange={(e) => setLabAddress(e.target.value)}
                        />
                      </div>
                    </Col>

                   
                  </>
                )}

                {/* short Bio */}
                <Col md={12} sm={12}>
                  <div className="mb-4">
                    <label className="mb-2 ms-2">Bio :</label>

                    <textarea
                      type="text-ae"
                      placeholder="Bio"
                      rows="4"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </div>
                </Col>

                {/* submit */}
                <Col className="text-end">
                  <button
                    className="settingEditBtn"
                    onClick={() => {
                      updateProfile();
                    }}
                  >
                    {isLoading ? "Please Wait...." : "Save"}
                  </button>
                </Col>
              </Row>
            </Row>
            <Row className="my-4 setting-x">
              <Col lg={12}>
                <h5>Change Password</h5>
              </Col>
            </Row>
            <Row className="d-flex dashboadrd_setting mb-5 me-2">
              {/* Image */}

              <Row className="m-auto mt-5">
                {/* firstname */}
                <Col md={6} sm={12}>
                  <label className="mb-2 ms-2">Current Password :</label>

                  <InputPassword
                    placeHoldertext={"Password"}
                    value={passwordCurrent}
                    newValue={setPasswordCurrent}
                  />
                </Col>

                {/* middle name */}
                <Col md={6} sm={12}>
                  <label className="mb-2 ms-2">New Password :</label>

                  <InputPassword
                    placeHoldertext={"Enter New Password"}
                    value={password}
                    newValue={setPassword}
                  />
                </Col>

                {/* last name */}
                <Col md={6} sm={12}>
                  <label className="mb-2 ms-2">Retype New Password :</label>

                  <InputPassword
                    placeHoldertext={"Retype New Password"}
                    value={passwordConfirm}
                    newValue={setPasswordConfirm}
                  />
                </Col>

                {/* submit */}
                <Col className="text-end">
                  <button
                    className="settingEditBtn"
                    onClick={() => handlePasswordChange()}
                  >
                    {isApiCall ? "Please Wait...." : "Save"}
                  </button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;

const _days = [
  "Saturday",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];
