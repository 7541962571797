import React from "react";


import item_img1 from "../assets/images/parmacyImage2.png";
import { imageUrl } from "../Config/apiUrl";

const OrderItem = ({ data }) => {
  return (
    <tr>
      <td>
        <div className="order_item">
          <div className="item_img">
            <img
              src={`${imageUrl}${data?.productId?.image}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = item_img1;
              }}
              className='order-item-x-img'
            />
          </div>
          <div className="item_content">
            <h6>{data?.productId?.name || "Nil"}</h6>
            <p>Category: {data?.productId?.catId?.catName || "Nil"}</p>
          </div>
        </div>
      </td>
      <td>
        <div className="order_quantity">
          <span className="number">{data?.quantity}</span>
        </div>
      </td>
      <td>
        <div className="unit_price">
          <h5 className="">{parseFloat(data?.price).toFixed(2)} (SlSh)</h5>
        </div>
      </td>
      <td>
        <div className="total_price">
          <h5 className="">
            {parseFloat(data?.price * data?.quantity).toFixed(2)} (SlSh)
          </h5>
        </div>
      </td>
  
    </tr>
  );
};

export default OrderItem;
