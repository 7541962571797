import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useSelector } from "react-redux";
import DashboardtopbarRight from "./DashboardtopbarRight";
import { GiHamburgerMenu } from "react-icons/gi";
const Header = () => {
  const accessToken = useSelector((state) => state?.authReducer?.access_token);

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="navbarBrand navbarBrand2"
      >
        <Container className="logo_container containerLogo">
          <Navbar.Brand href="#home">
            <img src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <span>
              <GiHamburgerMenu />
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto navLink">
              <Nav.Link
                as={Link}
                to="/"
                href="#features"
                className={` ${
                  window.location.pathname === "/" ? "web-color" : "textBlack"
                }`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className={` ${
                  window.location.pathname === "/about"
                    ? "web-color"
                    : "textBlack"
                }`}
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/doctor"
                className={` ${
                  window.location.pathname === "/doctor"
                    ? "web-color"
                    : "textBlack"
                }`}
              >
                Doctor
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/covid19"
                className={` ${
                  window.location.pathname === "/covid19"
                    ? "web-color"
                    : "textBlack"
                }`}
              >
                Covid-19
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/pharmacypage"
                className={` ${
                  window.location.pathname === "/pharmacypage"
                    ? "web-color"
                    : "textBlack"
                }`}
              >
                Pharmacy
              </Nav.Link>
            </Nav>
            {accessToken ? (
              <>
                <Nav className="navLink">
                  <DashboardtopbarRight itemsColor={true} />
                </Nav>
              </>
            ) : (
              <>
                <Nav className="navLink">
                  <Nav.Link
                    as={Link}
                    to="/login"
                    className={`login-btn ${
                      (window.location.pathname == "/login" ||
                        window.location.pathname == "/signup" ||
                        window.location.pathname == "/support") &&
                      "login-btn-white-x"
                    }`}
                    style={{
                      color: "#fff",
                    }}
                  >
                    Login
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/signup-role"
                    className="sign-up-btn"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Sign Up
                  </Nav.Link>
                </Nav>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
