
import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function ModalSkeleton({ open, setOpen, children, type }) {
  const handleClose = () => setOpen(false);

  const customStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    // border: "2px solid #0B30A9",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    maxHeight: "80vh",
    overflowY: "auto",
    border: "none",
    outline: "none",
  };

  return (
    <div className={`${type == "big" && "big_modal_new"}`}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={customStyle} className="modal-scroll">
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
