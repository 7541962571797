import React from "react";
import { Col } from "react-bootstrap";

import { imageUrl } from "../Config/apiUrl";

const LatestUpdateCovid19 = ({ item, setFun }) => {
  return (
    <>
      <Col
        className="latest-update-col"
        md={4}
        onClick={() => setFun(item.image, item.title, item.description)}
      >
        <div className="card_Main">
          <div className="card_Image">
            <img src={`${imageUrl}${item.image}`} alt="" />
          </div>
          <div className="card_body">
            <div className="card_title">
              <p>{item.title}</p>
            </div>
            <div className="card_description">
              <p>{item.description}</p>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default LatestUpdateCovid19;
