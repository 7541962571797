import React from "react";
import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import DashboardtopbarRight from "./DashboardtopbarRight";
const DashboardTopBar = ({ noRight, noIcon }) => {
  return (
    <>
      <Container fluid className="p-0 ">
        <Row className="margin_zero g-0">
          <Col md={12}>
            <Navbar expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto navLink">
                    <Nav.Link
                      as={Link}
                      to="/"
                      href="#features"
                      className="textBlack"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link as={Link} to="/" className="textBlack">
                      About
                    </Nav.Link>
                    <Nav.Link as={Link} to="/" className="textBlack">
                      Doctor
                    </Nav.Link>
                    <Nav.Link as={Link} to="/" className="textBlack">
                      Covid-19
                    </Nav.Link>
                    <Nav.Link as={Link} to="/" className="textBlack">
                      Pharmacy
                    </Nav.Link>
                  </Nav>
                  <DashboardtopbarRight
                    itemsColor={false}
                    noRight={noRight}
                    noIcon={noIcon}
                  />
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
    
        </Row>
      </Container>
    </>
  );
};

export default DashboardTopBar;
