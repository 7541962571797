import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal} from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

const DoctorScheduleModal = (props) => {
  const {
    appointmentDate: _appointmentDate,
    appointmentTime: _appointmentTime,
    consultationFee,
    handleYes,
    isLoading,
    isCovid,
    covid19Services,
    doctorCommission,
    covidCommission,
    wafiPayCommission,
  } = props;

  const user = useSelector((state) => state?.authReducer?.user);



  const [patientName, setPatientName] = useState(
    `${user?.firstName} ${user?.middleName} ${user?.lastName}`
  );
  const [patientNumber, setPatientNumber] = useState(
    `${user?.countryCode || "+1"} ${user?.phoneNumber}`
  );

  const [covid19Service, setCovid19Service] = useState("");
  const [covid19Price, setCovid19Price] = useState("");

  // otherPatient
  const [otherPatient, setOtherPatient] = useState(false);
  const [otherPatientName, setOtherPatientName] = useState("");
  const [otherPatientNumber, setOtherPatientNumber] = useState("");

  useEffect(() => {
    if (covid19Services?.length > 0) {
      setCovid19Service(covid19Services[0].serviceName);
      setCovid19Price(
        covid19Services[0].servicePrice +
          parseInt((covid19Services[0].servicePrice * covidCommission) / 100) +
          wafiPayCommission
      );
    }
  }, []);

  // handleSubmit
  const handleSubmit = () => {
    handleYes(
      patientName,
      patientNumber,
      covid19Service,
      covid19Price,
      // accountNo,
      otherPatient,
      otherPatientName,
      otherPatientNumber
    );
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box"
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <h4>Book an Appointment</h4>
                <div className="modal-inp inp-gray">
                  <input
                    type="text"
                    value={`Appointment Date: ${moment(_appointmentDate).format(
                      "Do MMM YYYY"
                    )}`}
                    disabled
                  />
                </div>
                <div className="modal-inp inp-gray">
                  <input
                    type="text"
                    disabled
                    value={`Appointment Time: ${_appointmentTime?.startTime} - ${_appointmentTime?.endTime}`}
                  />
                </div>
                <div className="modal-inp">
                  <input
                    type="text"
                    placeholder="Patient Name"
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                  />
                </div>
                <div className="modal-inp">
                  <input
                    type="text"
                    placeholder="Patient Number"
                    value={patientNumber}
                    onChange={(e) => setPatientNumber(e.target.value)}
                  />
                </div>

                {!isCovid && (
            
                  <div className="modal-inp inp-gray">
                    <input
                      type="text"
                      value={`Fees ${parseFloat(
                        consultationFee +
                          parseInt((consultationFee * doctorCommission) / 100) +
                          wafiPayCommission
                      ).toFixed(2)} (SlSh)`}
                      disabled
                    />
                  </div>
                )}
                {isCovid && (
                  <div className="mb-4">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      placeholder="Select Service...."
                      value={covid19Service}
                      onChange={(e) => {
                        setCovid19Service(e.target.value);
                        setCovid19Price(() => {
                          const selectedService = covid19Services.find(
                            (service) => service.serviceName === e.target.value
                          );
                          return (
                            selectedService?.servicePrice +
                            parseInt(
                              (selectedService?.servicePrice *
                                covidCommission) /
                                100
                            ) +
                            wafiPayCommission
                          );
                        });
                      }}
                    >
                      {covid19Services.map((e, i) => (
                        <option value={e?.serviceName} key={i}>{`Service: ${
                          e?.serviceName
                        } - Price: ${parseFloat(
                          e?.servicePrice +
                            parseInt(
                              (e?.servicePrice * covidCommission) / 100
                            ) +
                            wafiPayCommission
                        ).toFixed(2)}`}</option>
                      ))}
                    </select>
                  </div>
                )}

                {/* onbehalf of other person */}

                <div className={"mb-10-px"}>
                  <input
                    type={"checkbox"}
                    className="c-f-b-s"
                    value={otherPatient}
                    onChange={(e) => setOtherPatient(e.target.checked)}
                  />
                  <label className="f-s-18">
                    I'm booking on the behalf of another person.
                  </label>
                </div>

                {otherPatient && (
                  <>
                    <div className="modal-inp xpd">
                      <input
                        type="text"
                        placeholder="Patient Name"
                        value={otherPatientName}
                        onChange={(e) => setOtherPatientName(e.target.value)}
                      />
                    </div>
                    <div className="modal-inp xpd">
                      <input
                        type="text"
                        placeholder="Patient Number"
                        value={otherPatientNumber}
                        onChange={(e) => setOtherPatientNumber(e.target.value)}
                      />
                    </div>
                  </>
                )}

              
                <div className="modal-btn">
                  <button
                    onClick={
                      () => {
                        if (
                          otherPatient &&
                          otherPatientName === "" &&
                          otherPatientNumber === ""
                        ) {
                          return toast.warn(
                            "Other Person Name and Number is required"
                          );
                        }

                        handleSubmit();
                      }
                      // otherPatient &&
                      // otherPatientName === "" &&
                      // otherPatientNumber === ""
                      //   ? toast.warn("Other Person Name and Number is required")
                      //   : isAccountNoVisible
                      //   ? handleSubmit()
                      //   : setIsAccountNoVisible(true)
                    }
                  >
                    {isLoading ? "Please Wait..." : "Proceed to Payment"}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DoctorScheduleModal;
