import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import { apiHeader, imageUrl, URL } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { useHistory, Link } from "react-router-dom";
import { RiEdit2Line } from "react-icons/ri";
import AppointmentSm from "../components/AppointmentSm";
import BodyLoader from "../components/BodyLoader";
import NullDataComponent from "../components/NullDataComponent";
import AppointmentDescriptionModal from "../components/AppointmentDescriptionModal";
import AreYouSureModal from "../components/AreYouSureModal";

const ProfileRightBox = () => {
  const history = useHistory();
  const user = useSelector((state) => state?.authReducer?.user);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isApiCall, setIsApiCall] = useState(false);

  const [appointments, setAppointments] = useState([]);
  const [appointmentModalData, setAppointmentModalData] = useState({});
  const [
    isAppointmentDescriptionModalOpen,
    setIsAppointmentDescriptionModalOpen,
  ] = useState(false);

  // get Appointments
  const getAppointments = async () => {
    const url = URL("appointment");
    setIsApiCall(true);
    const response = await Post(
      url,
      {
        status: "upcoming",
      },
      apiHeader(accessToken)
    );
    setIsApiCall(false);
    console.log(response);
    if (response !== undefined) {
      setAppointments(response?.data?.appointments);
    }
  };

  useEffect(() => {
    getAppointments();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={2} className="ProfileRightBox">
            <div className="ProfileRightBoxTop mt-4">
              <div className="ProfileRightBoxTopImg">
                <img src={`${imageUrl}${user?.photo}`} />
              </div>
              <div className="profille-text">
                <h4>
                  {user?.firstName} {user?.middleName} {user?.lastName}
                </h4>
                <p className="">{user?.role}</p>
              </div>
              <div className="mb-3">
                <div className="dashborad-profile-inner-box">
                  <span>
                    {" "}
                    <Link
                      to={{
                        pathname: `/profile`,
                        search: `?id=${user?._id}`,
                      }}
                    >
                      {" "}
                      Go to Profile
                    </Link>
                  </span>
                  <span
                    className="icon-red c-p"
                    onClick={() => history.push("/settings")}
                  >
                    <RiEdit2Line className="dashboard-icons" />
                  </span>
                </div>
              </div>
            </div>
            <div className="ProfileRightBoxSeeAll">
              <h6>Appointments </h6>

              {appointments?.length > 0 && (
                <p
                  className="c-p"
                  onClick={() => history.push("/Appointments")}
                >
                  See all
                </p>
              )}
            </div>

            <div className="appointmentRightSide_scroll">
              {appointments.map((e, i) => (
                <AppointmentSm
                  key={e?._id}
                  data={e}
                  onClick={() => {
                    setAppointmentModalData(e);
                    setIsAppointmentDescriptionModalOpen(true);
                  }}
                />
              ))}
              {/* when we dont have any appointment */}
              {!isApiCall && appointments.length === 0 && (
                <div className="p-d-null-data">
                  <NullDataComponent text={"No Upcoming Appointment."} />
                </div>
              )}

              {/* when we are loadingintment */}
              {isApiCall && (
                <div className="p-d-null-data">
                  <NullDataComponent text={"Loading..."} noImage />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* AppointmentDescriptionModal */}
      {isAppointmentDescriptionModalOpen && (
        <AppointmentDescriptionModal
          show={isAppointmentDescriptionModalOpen}
          onHide={() => setIsAppointmentDescriptionModalOpen(false)}
          modalData={appointmentModalData}
        />
      )}
      {false && <AreYouSureModal show={false} />}

      {isApiCall && <BodyLoader />}
    </>
  );
};

export default ProfileRightBox;
