import { CleaningServices } from "@mui/icons-material";
import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  SAVE_REQUEST_ID,
  UPDATE_USER,
} from "../Actions/actionType";

const INITIAL_STATE = {
  access_token: "",
  isLogin: false,
  user: null,
  mode: "light",
  requestId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload?.data.user,
        isLogin: true,
        access_token: action.payload?.token,
      };
      break;

    case LOGOUT_REQUEST:
      return {
        ...state,
        access_token: "",
        isLogin: false,
        user_type: "",
        user: null,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };

    case SAVE_REQUEST_ID:
      return {
        ...state,
        requestId: action.payload,
      };
    default:
      return state;
  }
};
