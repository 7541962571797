import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import TimeRange from "react-time-range";

import { toast } from "react-toastify";


const DoctorSelectTimeModal = ({
  onHide,
  show,
  completeDay,
  onSubmit,
  isLoading,
}) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [noOfPatients, setNoOfPatients] = useState(
    completeDay?.numberOfPatients || 0
  );

  const returnFunctionStart = (event) => {
    setStartTime(event.startTime);
  };

  const returnFunctionEnd = (event) => {
    setEndTime(event.endTime);
  };

  // submit
  const SubmitTime = async () => {
    if (!startTime || !endTime) return toast.error("Please select time range.");

    if (!completeDay?._id && !noOfPatients)
      return toast.error("Please enter number of patients");
    if (noOfPatients == 0)
      return toast.error("Please enter number of patients");
    await onSubmit(noOfPatients, startTime, endTime);
    setNoOfPatients();
    setStartTime();
    setEndTime();
  };


  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box appointment_Des_modal small-modal-x"
              show={show}
              onHide={onHide}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="appointmentDespModalBody">
                <div className="appointmentDesrModal_inner">
                  <h4>Schedule Details</h4>
                  <div className="appointmentDespModal_text selectModal-body">
                    <TimeRange
                      onStartTimeChange={returnFunctionStart}
                      onEndTimeChange={returnFunctionEnd}
                      startMoment={startTime}
                      endMoment={endTime}
                      minuteIncrement={60}
                      // use24Hours
                    />
                  </div>
                  <label className="patients_label">Number of Patients :</label> <br />
                  <input
                    type="number"
                    className="selectModal-inp"
                    placeholder="Number of Patients"
                    min={1}
                    value={noOfPatients}
                    onChange={(e) => setNoOfPatients(e.target.value)}
                  />
                </div>
                <div className="modal-btn apppointmentDest_closeBtn selectModal-btn">
                  <button
                    onClick={() => {
                      SubmitTime();
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Please Wait..." : "Submit"}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DoctorSelectTimeModal;
