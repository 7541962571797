import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/images/logoWhite.png";

import loginBgImg from "../assets/images/loginBgImg.png";
import { URL, apiHeader } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ReactCodeInput from "react-code-input";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { saveLoginUserData, signOutRequest } from "../store/Actions/authAction";
const EnterCode = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pinCode, setPinCode] = useState("");
  const [showPinCode,] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();
  const [timer, setTimer] = useState(30);

  const [isResendCodeApiCall, setIsResendCodeApiCall] = useState(false);

  const user = useSelector((state) => state?.authReducer?.user);
  const requestId = useSelector((state) => state?.authReducer?.requestId);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  useEffect(() => {
    const _t = setTimeout(() => {
      if (timer === 0) return;
      setTimer((p) => p - 1);
    }, 1000);

    return () => {
      clearTimeout(_t);
    };
  }, [timer]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Are you sure you want to leave?";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // handleSubmit
  const handleSubmit = async () => {
    // if (timer !== 0) return;
    if (pinCode.length < 6)
      return toast.warning("Please enter valid pin code!");

    const params = {
      code: pinCode,
      requestId: requestId,
      phoneNumber: user?.phoneNumber,
    };

    const url = URL("users/verify-otp");
    setIsLoading(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsLoading(false);
    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data));
      // if (response?.data?.data?.user?.role == "patient") {
      history.push("/Dashboard");
      // }
      // history.replace("/verify");
      // TODO: navigate conditionally
    }
  };

  // handleResendOtpCode
  const handleResendOtpCode = async () => {
    const params = {
      phoneNumber: user?.phoneNumber,
    };
    setIsResendCodeApiCall(true);
    const url = URL("users/resend-otp");
    const response = await Post(url, params, apiHeader(accessToken));
    setIsResendCodeApiCall(false);

    if (response !== undefined) {
      toast.success("OTP code sent successfully!");
      setTimer(59);
    }
  };

  return (
    <>
      <Container
        fluid
        className="optScreen"
        style={{
          height: "100vh",
        }}
      >
        <Row>
          <Container>
            <Col lg={12}>
              <div className="logo-btn-x">
                <img src={logo} alt="logo" />
                <Button
                  className="sign-up-btn"
                  onClick={() => {
                    dispatch(signOutRequest());
                    history.push("/");
                  }}
                >
                  Logout
                </Button>
              </div>
            </Col>
          </Container>
          <Col lg={12} className="opt-outer-div">
            <div className="otp-container">
              <h4>OTP Verification</h4>
              <p>Enter the one Time password Send to</p>
              <h6>{user?.phoneNumber}</h6>
              <ReactCodeInput
                ref={inputRef}
                type={showPinCode ? "text" : "password"}
                fields={6}
                onChange={(code) => setPinCode(code)}
                value={pinCode}
              />
              <p>Didn't recevice an OTP?</p>
              {timer > 0 ? (
                <p className={"c-n-d"}>
                  Resend in <span>00:{timer}</span>
                </p>
              ) : (
                <p className={"c-p c-pink"} onClick={handleResendOtpCode}>
                  {isResendCodeApiCall ? "Please Wait" : "Resend Code"}
                </p>
              )}
              <button disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? "Please Wait" : "Submit your OTP Code"}
              </button>
            </div>
            <div className="otp-img2">
              <img src={loginBgImg} alt="img" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EnterCode;
