import React from "react";
import { Container, Row, Col, Image, Table } from "react-bootstrap";

const AboutVisitDoctor = ({ data1 }) => {
  return (
    <>
      <section className="aboutVistDoctor_sect">
        <Container>
          <Row>
            {data1?.map((e, i) => {
              return (
                <Col lg={2} md={4} sm={6} key={i}>
                  <div className="squareBox">
                    <h6>{e.title}</h6>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutVisitDoctor;
