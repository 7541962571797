import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { apiHeader, imageUrl, URL } from "../Config/apiUrl";
import { Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const MessageModal = ({ showModal, closeModal, openModal, profileData }) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const [message, setMessage] = useState("");
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const userId = useSelector((state) => state?.authReducer?.user?.id);

  // send Message
  const sendMessage = async () => {
    if (message.trim() === "") return toast.warning("Please enter message");
    let newMsg = {
      _id: uuidv4(),
      text: message,
      createdAt: moment().format(),
      user: {
        _id: userId,
        name: user?.displayName,
        avatar: `${imageUrl}${user?.photo}`,
      },
    };

    const url = URL(`chat/send-message`);
    let params = {
      message: newMsg,
      to: profileData?._id,
      from: userId,
    };
    setIsApiCall(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsApiCall(false);
    if (response !== undefined) {
      toast.success("Message Send Sucessfully!");
      setMessage("");
      closeModal();
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box appointment_Des_modal small-modal-x-w100 modal-msg"
              show={openModal}
              onHide={closeModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="appointmentDespModalBody msg ">
                <div className="appointmentDesrModal_inner">
                  <h4>Message:</h4>
                  <div className="checkout_table">
                    <textarea
                      className="w-100 p-2 my-3"
                      rows={6}
                      placeholder="Type Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>

                <div className="modal-btn apppointmentDest_closeBtn">
                  <button
                    className="px-290"
                    disabled={isApiCall}
                    onClick={() => sendMessage()}
                  >
                    {isApiCall ? "Plase Wait..." : "Send"}
                  </button>
                  <button onClick={() => closeModal()} className="px-290 ms-2">
                    {"Close"}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MessageModal;
