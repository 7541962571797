import React, { useState, useEffect, useRef, createRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Avatar } from "@mui/material";
import EmptyChat from "../assets/images/empty-chat (1).png";
import { useSelector } from "react-redux";
import moment from "moment";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";
import { Get, Post } from "../Axios/AxiosFunctions";
import { URL as BaseURL, imageUrl, apiHeader, apiUrl } from "../Config/apiUrl";
import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { useLocation } from "react-router-dom";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

function OtherMessage({ message, time, user }) {


  return (
    <div className="left-message">
      <div>
        <Avatar src={`${user?.avatar}`} />
      </div>
      <div>
        <p className="message">
          {message}
          <span className="arrow" />
        </p>
        <span className="time">{moment(time).format("hh:mm a")}</span>
      </div>
    </div>
  );
}

function MyMessage({ message, time }) {
  return (
    <div className="right-message">
      <div>
        <p className="message">
          {message}
          <span className="arrow" />
        </p>
        <span className="time">{moment(time).format("hh:mm a")}</span>
      </div>
    </div>
  );
}

// Chat List
function ChatList({ data, role, onClick }) {
  const userData = useSelector((state) => state?.authReducer?.user);
  let undread =
    userData?._id == data?.user1?._id
      ? data?.user1UnreadCount
      : data?.user2UnreadCount;

  return (
    <div className="chat-list" onClick={() => onClick(data?._id)}>
      <Container className="h-100 ">
        <Row className="h-100">
          <Col lg={3} md={3} sm={3} xs={3}>
            <div className="d-flex a-center h-100">
              <Avatar
                src={`${imageUrl}${
                  userData?._id == data?.user1?._id
                    ? data?.user2?.photo
                    : data?.user1?.photo
                  }`}
                sx={{ width: 50, height: 50 }}
              />
            </div>
          </Col>
          <Col lg={7} md={7} sm={7} xs={7}>
            <div className="username">
              <h6>
                {userData?._id == data?.user1?._id
                  ? data?.user2?.displayName
                  : data?.user1?.displayName}
           
              </h6>
              <span>{data?.lastMessage?.text}</span>
            </div>
          </Col>
          <Col lg={2} md={2} sm={2} xs={2}>
            <div className="time-box">
              <span>
                {moment(data?.lastMessage?.createdAt).format("hh:mma")}
              </span>
              {undread > 0 && <span className="remaining-msgs">{undread}</span>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// Chat time Line
function ChatTimeline({ time }) {
  return (
    <Row className="chat-timeline">
      <Col span={5}>
        <p className="left-border" />
      </Col>
      <Col span={2} className="text-center">
        <span className="time">{moment(time).format("DD MMM YYYY")}</span>
      </Col>
      <Col span={5}>
        <p className="right-border" />
      </Col>
    </Row>
  );
}

// Chat Screen
function ChatScreen() {
  const dynamicChat = useLocation()?.state;
  const socket = useRef(null);
  const chatsRef = createRef();
  const [messageText, setMessageText] = useState("");
  const [rooms, setRooms] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen,setIsOpen]=useState(false);

  const { access_token: accessToken, user } = useSelector(
    (state) => state.authReducer
  );
  const role = user?.role;
  const [roomData, setRoomData] = useState(null);
  const [messages, setMessages] = useState([]);
  const userId = user?._id;

  // scrollToBottom chat container
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  // get Rooms
  async function getRooms() {
    const url = `chat/chat-rooms?_id=${userId}`;
    const response = await Get(BaseURL(url), accessToken);
    if (response !== undefined) {
      setRooms(response?.data?.data);
      console.log(response);
    }
  }

  //getRoomData
  async function getRoomData(id) {
    const url = `chat/get-messages?roomId=${id}`;
    const response = await Get(BaseURL(url), accessToken);
    if (response !== undefined) {
      socket.current.emit("mark-as-read", id, role);
      setMessages(response?.data?.data?.reverse());
      let newRooms = [...rooms];
      newRooms.filter((item, index) => {
        if (item?.id === id) {
          if (role === "patient") newRooms[index].user2UnreadCount = 0;
          else newRooms[index].user1UnreadCount = 0;
        }
      });

      setRooms([...newRooms]);
      scrollToBottom();
    }
  }
  // Mobile message
  // useEffect(() => {
  //   isMobileViewHook(setIsMobile)
  // }, [])
  
useEffect(()=>{
  isMobileViewHook(setIsMobile);
  setIsOpen(isMobile&&false)
},[]);
  useEffect(() => {
    console.log("isMobile", isMobile);
  }, [isMobile])


  // dynamicChat
  useEffect(() => {
    if (dynamicChat) {
      setRoomData(dynamicChat);
      getRooms();
      getRoomData(dynamicChat?._id);
    }
  }, [dynamicChat]);

  useEffect(() => {
    getRooms();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // send chat messagee
  async function onSend() {
    if (messageText === "") {
      return toast.error("Please write the message", {
        position: "top-center",
      });
    }

    let newMsg = {
      _id: uuidv4(),
      text: messageText,
      createdAt: moment().format(),
      user: {
        _id: userId,
        name: role === "doctor" ? user?.displayName : user?.displayName,
        avatar: `${imageUrl}${user?.photo}`,
      },
    };
    setMessages((prev) => [...prev, newMsg]);
    let msgTo =
      roomData?.user2?._id == user?._id
        ? roomData?.user1?._id
        : roomData.user2?._id;
    // let msgTo = role == "doctor" ? roomData?.user1?._id : roomData.user2?._id;
    socket.current.emit("msg", newMsg, msgTo, roomData?._id, role);
    setMessageText("");
    scrollToBottom();
  }

  //  Socket Connection
  useEffect(() => {
    socket.current = io(
      apiUrl
      // , { transports: ["websocket"] }
    );
    socket.current.emit("join", userId, role);
    console.log(socket.current);
  }, []);

  useEffect(() => {
    if (roomData !== null) {
      socket.current.emit("chatJoin", userId, roomData?._id, role);

      // socket.current.emit("mark-as-read", roomData?.id, role);
      socket.current.on("msg", (msg, socketRoomId) => {
        if (roomData?._id === socketRoomId && msg?.user?._id !== userId) {
          setMessages((prev) => [...prev, msg]);
          console.log(roomData?._id, role);
          socket.current.emit("mark-as-read", roomData?._id, role);
        }
      });
    }
  }, [roomData]);

  return (
    <section className="chat-section">
      <Container fluid className="padding-fluid-container">
        <Row className="m-0">
          <Col md={isMobile ? ("1"):("3")}>
            <SideNavbar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile}/>
          </Col>
          <Col md={isMobile ? ("11"):("9")}>
            <Row className="my-2">
              <Col lg={12} md={12} xl = {12} className="mt-4 navbar-padding-xx">
                <DashboardTopBar />
              </Col>
            </Row>
            <Row className="chatbox-row">
              <Col  md={4} sm={12} className={`chatbox-col ${roomData != null && isMobile}`}>
                <div className="chats-left-box">
                  <div className="chat-header">
                    <h5>Chat</h5>
                  </div>
                  <div className="chats-left-box-messages">
                    {rooms?.length === 0 ? (
                      <div className="empty-chat-list">
                        <span>No engaged chats</span>
                      </div>
                    ) : (
                      rooms?.map((item) => {
                        return (
                          <ChatList
                            data={item}
                            role={user?.role}
                            onClick={(id) => {
                              // debugger;
                              setRoomData(item);
                              getRoomData(id);
                            }}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              </Col>
              {/* {console.log({ condition: roomData == null && isMobile == true, roomData, isMobile })} */}
              <Col md={8} sm={12} className={`chatbox-col ${(roomData == null && isMobile == true) ? 'd-none' : 'd-block'}`}>
                {!roomData ? (
                  <div className="chats-right-box">
                    <div className="center-empty-icon">
                      <h3>Welcome to chat</h3>
                      <img src={EmptyChat} />
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <div className="chats-right-box">
                    <div className="chat-header">
                      <Avatar
                        src={`${imageUrl}${roomData?.user1?._id !== user?._id
                          ? roomData?.user1?.photo
                          : roomData?.user2?.photo
                          }`}
                        sx={{ width: 50, height: 50 }}
                      />
                      <div className="ms-3">
                        <h6>
                          {roomData?.user1?._id !== user?._id
                            ? roomData?.user1?.displayName
                            : roomData?.user2?.displayName}
                        </h6>
                      </div>
                    </div>

                    <div className="chats-container" ref={chatsRef}>
                      <Container>
                        <Row>
                          {messages?.map((item, index) => {
                            let prevMessage = index > 0 && messages[index - 1];

                            return (
                              <>
                                {index === 0 && (
                                  <ChatTimeline time={item?.createdAt} />
                                )}
                                {index > 0 &&
                                  moment(prevMessage?.createdAt).format(
                                    "DD MMM YYYY"
                                  ) !==
                                  moment(item?.createdAt).format(
                                    "DD MMM YYYY"
                                  ) && (
                                    <ChatTimeline time={item?.createdAt} />
                                  )}

                                {item?.user?._id === user?._id ? (
                                  <Col lg={12} key={item?._id}>
                                    <MyMessage
                                      message={item?.text}
                                      time={item?.createdAt}
                                      user={item?.user}
                                    />
                                  </Col>
                                ) : (
                                  <Col lg={12} key={item?._id}>
                                    <OtherMessage
                                      message={item?.text}
                                      time={item?.createdAt}
                                      user={item?.user}
                                    />
                                  </Col>
                                )}
                              </>
                            );
                          })}
                        </Row>
                        <div ref={messagesEndRef} />
                      </Container>
                    </div>
                    <div className="chat-footer">
                      <Row>
                        <Col xl={10} lg={10} md={10} xs={9}>
                          <input
                            placeholder="Type your message"
                            className="message-input"
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                onSend();
                              }
                            }}
                          />
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={3}>
                          <div className="d-flex a-center h-100 float-end">
                            <button
                              className="send-btn"
                              onClick={() => onSend()}
                            >
                              <FiSend className="icon" />
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default ChatScreen;
