import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const SelectCity = ({
  cities,
  specialization,
  setSelectedSpecialization,
  setSelectedCity,
  selectedCity,
  selectedSpecialization,
}) => {
  return (
    <>
      <Container className="dashboard SelectCityMain">
        <Row>
          <Col>
            <h5>Select a City</h5>
            <p>Where should you visit your Doctor?</p>
          </Col>
        </Row>

        <Row className=" mt-4 ">
          <Col md={12} >
            <div className="preferences-x-c">
              {cities?.map((e) => (
                <div className="mb-4 SelectCityBtnDiv me-3" key={e?._id}>
                  <button
                  title={e?.name}
                    type="button"
                    className={`text-left ${
                      e?.name == selectedCity && "active"
                    }`}
                    onClick={() => setSelectedCity(e?.name)}
                  >
                    {e?.name}
                  </button>
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5>Select Specialization</h5>
          </Col>
        </Row>

        <Row className="m-auto mt-4">
          <Col md={12}>
            <div className="preferences-x-c">
              {specialization?.map((e) => (
                <div className="mb-4 SelectCityBtnDiv me-3" key={e?._id}>
                  <button
                   title={e?.name}
                    type="button"
                    className={`text-left ${
                      e?.name == selectedSpecialization && "active"
                    }`}
                    onClick={() => setSelectedSpecialization(e?.name)}
                  >
                    {e?.name}
                  </button>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SelectCity;
