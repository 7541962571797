import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";

import "../assets/styles/doctordashboard.css";
import DashboardTopBar from "../components/dashboardTopBar";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { URL as NODE_URL, apiHeader } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import Select from 'react-select';

import { toast } from "react-toastify";
import { clearCart } from "../store/Actions/cartActions";

import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const ShippingDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const cartItems = useSelector((state) => state?.cartReducer?.cartItems);
  const totalAmount = useSelector((state) => state?.cartReducer?.totalAmount);
  const [firstName, setFirstName] = useState(user?.firstName);
  const [middleName ,setMiddleName] =useState(user?.middleName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber);
  const [address, setAddress] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);
  const [isApiCall2, setIsApiCall2] = useState(false);
  const [selectCity , setSelectCity] = useState([]);
  const [isMobile,setIsMobile]=useState(false)
  const [isOpen,setIsOpen]=useState(false)
  var numeral = require('numeral');

  const [prescriptionImage, setPrescriptionImage] = useState(null);
  const inputRef = useRef(null);

  const [city,setCity] = useState("") ;

  //   handlePlaceOrder
  const handlePlaceOrder = async () => {
    if (isApiCall) return;
    if (cartItems.length > 0) {
      const params = {
        firstName,
        middleName,
        lastName,
        email,
        phone,
        address,
        city:city?.name
      };

      // validate
      for (let key in params) {
        if (params[key] === "") {
          return toast.warn("Please fill all the fields");
        }
      }



      let items = [];
      cartItems.map((item) => {
        items.push({
          productId: item.id,
          quantity: item.quantity,
          price: item.price,
          stock: item.stock,
          totalPrice: item.price * 1 * item.quantity * 1,
        });
      });

      //  data to send on server=
      const order = {
        items,
        estimatedTotal: totalAmount,
        shipmentDetails: {
          name: `${firstName} ${middleName} ${lastName}`,
          email,
          phoneNumber: phone,
          address,
          city:city?.name,
        },
      };

      const formData = new FormData();
      formData.append("order", JSON.stringify(order));
      formData.append("image", prescriptionImage);

      const url = NODE_URL("order");
      setIsApiCall2(true);
      const response = await Post(url, formData, apiHeader(accessToken));
      setIsApiCall2(false);
      if (response !== undefined) {
        dispatch(clearCart());
        toast.success("Order Placed Successfully");
        history.replace("/Dashboard");
      } else {
        formData.delete("image");
        formData.delete("order");
      }
    }
  };
   
      // get city
      const getCity = async () => {
        const url = NODE_URL(`city`);
        setIsApiCall(true);
        const response = await Get(url, accessToken);
        setIsApiCall(false);
    
        if (response !== undefined) {
          setSelectCity(response?.data?.data);
        }
      }
  useEffect(()=>{
    getCity();
  },[])

  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  return (
    <>
      <Container fluid className="">
        <Row>
          <Col md={isMobile ? "1" : "3"}>
          <SideNavbar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />

          </Col>
          <Col md={isMobile ? "11" : "9"} className="main_content mt-3 mb-5">
            <Row >
              <Col lg={12} className="navbar-padding-xx">
                <DashboardTopBar />
              </Col>
              <Col lg={7}>
                <div className="shipping_details_block">
                  <h5>Payment</h5>
                  <div className="shipping_details">
                    <h5 className="font24 color_white">Shipping Details</h5>
                    <div className="form_fields">
                      <div class="row">
                        <Col lg={6}>
                          <label>First Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                        <label>Middle Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Middle name"
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                        <label>Last Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                        <label>Email Address:</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                        <label>Phone Number:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                        <label>Full Address:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </Col>
                        <Col lg={6}>
                        <label>Select City:</label>
                        <Select options={selectCity}
                         getOptionLabel={(option) => option.name}
                         getOptionValue={(option) => option.name}
                         onChange={(e)=>setCity(e)}
                         />
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Image */}
                <div className="shipping_details_block my-5">
                  <div className="shipping_details">
                    <h5 className="font24 color_white">Add Prescription</h5>
                    <div className="form_fields">
                      {prescriptionImage !== null ? (
                        <div className="cameraicon m-auto">
                          <img src={URL.createObjectURL(prescriptionImage)} />
                        </div>
                      ) : (
                        <div className="cameraicon m-auto">
                          <CameraAltIcon className="CameraAltIcon" />
                        </div>
                      )}

                      <div className="text-left UploadProfile shipping-deyail-prescription">
                        <label
                          class="setting_fileupload w-50"
                          onClick={() => {
                            inputRef.current.click();
                          }}
                        >
                          <div className="setting-innerDiv_fileUpload ">
                            <div className="d-flex j-center a-center  c-p">
                              <span>Upload Image</span>
                            </div>
                          </div>
                        </label>
                  
                        <input
                          ref={inputRef}
                          type="file"
                          size="2000000"
                          className="file_upload_form3rd"
                          onChange={(e) => {
                            if (e.target.files?.length > 0) {
                              if (
                                ![
                                  "image/jpeg",
                                  "image/png",
                                  "image/jpg",
                                ].includes(e.target.files[0].type)
                              ) {
                                return toast.error(
                                  "Please upload a valid image. [jpg and png formats only]"
                                );
                              }
                              setPrescriptionImage(e.target.files[0]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="summary_block order_details">
                  <h4 className="font40 bold_custom">Order Details</h4>
                  <hr />
                  <div className="summary_content">
                    <div className="summary__content">
                      <p>Total Items</p>
                      <p>{cartItems?.length}</p>
                    </div>
                    <div className="summary__content">
                      <p>Total</p>
                      <p>{numeral(totalAmount).format('0.0a')}(SlSh)</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`proceed_btn c-p ${
                    cartItems?.length === 0 && "proceed_btn_disabled"
                  }`}
                  onClick={handlePlaceOrder}
                >
                  <a>{isApiCall2 ? "Please Wait..." : "Place Order"}</a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShippingDetails;
