import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "sat",
    amt: 1000,
  },
  {
    name: "sun",

    amt: 1500,
  },
  {
    name: "mon",

    amt: 1000,
  },
  {
    name: "tue",

    amt: 2000,
  },
  {
    name: "wed",

    amt: 2500,
  },
  {
    name: "thu",

    amt: 1500,
  },
  {
    name: "fri",

    amt: 1800,
  },
];

export default function Chart({ earningGraphData }) {
  // console.log(
  //   "🚀 ~ file: Chart.js ~ line 50 ~ Chart ~ earningGraphData",
  //   earningGraphData
  // );
  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <AreaChart
        data={earningGraphData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="coloramt" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0b30a9" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#0b30a9" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="day" interval={"preserveStartEnd"} />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="amount"
          activeDot={{ r: 5 }}
          stroke="#0b30a9"
          fillOpacity={1}
          fill="url(#coloramt)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
