import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Container } from "react-bootstrap";

const PaginationB = ({ page, setPage, totalCount }) => {
  const [pageCount, setPageCount] = useState([2]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const totalPageMinusOne = pageCount[0] - 1;
  const hideLast = totalPageMinusOne == page;
  useEffect(() => {
    setPageCount(totalCount * 1);
  }, [totalCount]);

  return (
    <div>
      <Container>
        <Stack>
          <div
            className="d-flex "
            style={{ justifyContent: "flex-end", marginBottom: "20px" }}
          >
            <div className="d-flex">
              <Pagination
                count={pageCount}
                page={page}
                onChange={handleChange}
                siblingCount={0}
              />
            </div>
          </div>
        </Stack>
      </Container>
    </div>
  );
};

export default PaginationB;
