import React, { useState } from "react";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";

const InputPassword = ({placeHoldertext, value , newValue}) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="input-password mb-4">
      <input
        type={isShow ? "text":"password"}
        placeholder={placeHoldertext}
        autocomplete="new-password"
        className="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={value}
        onChange={(e) => newValue(e.target.value)}
      />
      {isShow ? (
        <IoEyeSharp
          size={20}
          className={"password-icon"}
          onClick={() => setIsShow((p) => !p)}
        />
      ) : (
        <BsFillEyeSlashFill
          size={20}
          className={"password-icon"}
          onClick={() => setIsShow((p) => !p)}
        />
      )}

    </div>
  );
};

export default InputPassword;
