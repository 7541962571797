import React from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";

const AreYouSureModal = (props) => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box areYouSureModal"
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="areYouSureModal_Body">
                <div className="areYouSureModal_head">
                  <h4>{props?.title || "Are You Sure?"}</h4>
                  <div className="areYouSureModal_text">
                    <p>
                      {props?.message ||
                        "Do You really want to delete this item?"}
                    </p>
                  </div>
                </div>

                <div className="modal-btn areYouSureBtn">
                  <button
                    disabled={props?.isLoading}
                    onClick={props?.handleYes}
                  >
                    {props?.isLoading ? "Please wait..." : "Yes"}
                  </button>
                  <button disabled={props?.isLoading} onClick={props?.onHide}>{"No"}</button>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AreYouSureModal;
