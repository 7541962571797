import { LOGIN_SUCCESS, LOGOUT_REQUEST, SAVE_REQUEST_ID , UPDATE_USER} from "./actionType";

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: response };
}

export function signOutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}
export function saveRequestId(requestId) {
  return {
    type: SAVE_REQUEST_ID,
    payload: requestId,
  };
}

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    user,
  };
};
