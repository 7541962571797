import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { imageUrl } from "../Config/apiUrl";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";

const DoctorBoxs = ({ doctorData }) => {
  return (
    <>
      <section>
        <Container>
          <Row className="custmDoctorBox">
            {doctorData.map((item, i) => (
              <Col md={4} sm={6} xs={12} key={i}>
                <div className="doctorBox">
                  <img
                    src={`${imageUrl}${item.photo}`}
                    className="doctorBox_image"
                    alt=""
                  />
                  <h5>{item.displayName}</h5>
                  <p>
                    <ShowMoreShowLessText text={item.shortBio} visibility={60} />
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DoctorBoxs;
