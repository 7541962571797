import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { apiHeader, getDate, sorter, URL } from "../Config/apiUrl";
import moment from "moment";
import DoctorScheduleModal from "../components/DoctorScheduleModal";
import { useLocation } from "react-router-dom";
import { Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AppointmentDescriptionModal from "../components/AppointmentDescriptionModal";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const DoctorScheduleScreen = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [modalShow, setModalShow] = useState(false);
  const doctorSchedule = useLocation()?.state?.schedule;
  const consultationFee = useLocation()?.state?.consultationFee;
  const doctorCommission = useLocation()?.state?.doctorCommission;
  const covidCommission = useLocation()?.state?.covidCommission;
  const profileData = useLocation()?.state?.profileData;
  const wafiPayCommission = useLocation()?.state?.wafiPayCommission;

  const [finalSchedule, setFinalSchedule] = useState([]);

  const [isBookingAppointment, setIsBookingAppointment] = useState(false);

  const [appointmentModalData, setAppointmentModalData] = useState({
    appointmentDate: null,
    appointmentTime: null,
    scheduleId: null,
  });

  // booked modal detail
  const [bookedModalData, setBookedModalData] = useState(null);
  const [isBookedModalOpen, setIsBookedModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (bookedModalData) {
      setIsBookedModalOpen(true);
    }
  }, [bookedModalData]);

  useEffect(() => {
    let _s = [...schedule];
    schedule.map((item, index) => {
      doctorSchedule.map((innerItem, innerIndex) => {
        if (item.day.toLowerCase() == innerItem.day.toLowerCase()) {
          _s.splice(index, 1, innerItem);
        }
      });
    });

    setFinalSchedule(_s);
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  // handleDoctorScheduler
  const handleDoctorScheduler = async (
    patientName,
    patientNumber,
    serviceName,
    serviceCharges,
    // accountNo,
    otherPatient,
    otherPatientName,
    otherPatientNumber
  ) => {
    if (!patientName || !patientNumber) {
      return toast.error("Please fill all the fields");
    }

    if (profileData?.role == "covid19" && (!serviceName || !serviceCharges))
      return toast.error("Please fill all the fields");

  

    if (otherPatient && (otherPatientName === "" || otherPatientNumber === ""))
      return toast.error("Other patient name and number is required");

    const url = URL("appointment/book");
    const params = {
      ...(profileData?.role == "doctor" && { doctorId: profileData?._id }),
      ...(profileData?.role == "covid19" && { covid19Id: profileData?._id }),
      scheduleId: appointmentModalData?.scheduleId,
      appointmentWith: profileData?.role,
      appointmentDay: moment(appointmentModalData.appointmentDate).format(
        "dddd"
      ),
      timeId: appointmentModalData?.appointmentTime?._id,
      patientName: patientName,
      patientNumber: patientNumber,
      ...(profileData?.role == "doctor" && {
        serviceCharges:
          consultationFee +
          parseInt((consultationFee * doctorCommission) / 100) +
          wafiPayCommission,
      }),
      ...(profileData?.role == "covid19" && { serviceName: serviceName }),
      ...(profileData?.role == "covid19" && { serviceCharges: serviceCharges }),
      otherPatient,
      ...(otherPatient && { otherPatientName }),
      ...(otherPatient && { otherPatientNumber }),
    };
    let response;

    setIsBookingAppointment(true);
    response = await Post(url, params, apiHeader(accessToken));
    setIsBookingAppointment(false);

    if (response !== undefined) {
      setModalShow(false);
      toast.success("Appointment booked successfully!");
      setBookedModalData(response?.data?.data);
    }
  };

  return (
    <>
      <Container fluid className="dashboard ">
        <Row className="">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row>
              <Col md={12} className="navbar-padding-xx">
                <DashboardTopBar />
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-between mt-5 heading-schedul-x"
              >
                <h5>Schedule</h5>
              </Col>
              {/* map */}
              <Col md={12}>
                <Row className="schedule-row">
                  {finalSchedule?.map((e, i) => {
                    return (
                      <Col className="schedule g-0" key={i}>
                        <button type="button" className="schedule-btn-blue">
                          {e?.day}
                        </button>

                        {/* schedule */}
                        {e?.timings?.map((sch, i2) => {
                          return (
                            <button
                              key={i2}
                              type="button"
                              className="schedule-btn-pink"
                              onClick={() => {
                                const dayNum = moment(
                                  moment().day(e?.day).format()
                                ).day();
                                const apptmentDate = moment(
                                  getDate(dayNum)
                                ).format("YYYY-MM-DD");
                                setAppointmentModalData({
                                  appointmentDate: apptmentDate,
                                  appointmentTime: sch,
                                  scheduleId: e?._id,
                                });
                                setModalShow(true);
                              }}
                            >
                              {sch?.startTime} - {sch?.endTime}
                            </button>
                          );
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              {/* end map */}
              {modalShow && (
                <DoctorScheduleModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  appointmentDate={appointmentModalData?.appointmentDate}
                  appointmentTime={appointmentModalData?.appointmentTime}
                  consultationFee={consultationFee}
                  handleYes={handleDoctorScheduler}
                  isLoading={isBookingAppointment}
                  isCovid={profileData?.role == "covid19"}
                  covid19Services={profileData?.covid19Services}
                  covidCommission={covidCommission}
                  doctorCommission={doctorCommission}
                  wafiPayCommission={wafiPayCommission}
                />
              )}
            </Row>
          </Col>
        </Row>
      </Container>

      {isBookedModalOpen && (
        <AppointmentDescriptionModal
          show={isBookedModalOpen}
          modalData={bookedModalData}
          onHide={() => {
            setIsBookedModalOpen(false);
            history.replace("/Dashboard");
          }}
        />
      )}
    </>
  );
};

export default DoctorScheduleScreen;

let schedule = [
  {
    day: "Saturday",
    timings: [],
  },
  {
    day: "Sunday",
    timings: [],
  },
  {
    day: "Monday",
    timings: [],
  },
  {
    day: "Tuesday",
    timings: [],
  },
  {
    day: "Wednesday",
    timings: [],
  },
  {
    day: "Thursday",
    timings: [],
  },
  {
    day: "Friday",
    timings: [],
  },
];

