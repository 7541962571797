import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DoctorMedicine from "../assets/images/DoctorMedicine.png";
import VisitDoctor from "../assets/images/VisitDoctor.png";
import pharmacy from "../assets/images/pharmacy.png";
import Covid19 from "../assets/images/Covid19.png";
import SideNavbar from "./SideNavbar";

const BookOnline = () => {
  return (
    <>
      <Container>
        {/* <Row>
          <Col>

        <SideNavbar/>
          </Col> */}

          {/* <Col> */}
        <Row className="bookOnline">

          <Col md={9}>
            <h6>Book Online Nearby Doctors & Medical Labs</h6>
            <p>5141 Broadway, New York, NY 10034</p>
          </Col>
          <Col md={3} className="text-end">
            <img src={DoctorMedicine} />
          </Col>
        </Row>

        {/* </Col> */}
        
        <Row className="bookOnlineBox">
          <Col md={6} className="bookOnlineBoxDiv">
            <img src={VisitDoctor} />
            <h6 className="mt-3">Visit Doctors</h6>
          </Col>
          <Col md={6} className="bookOnlineBoxDiv">
            <img src={pharmacy} />

            <h6 className="mt-3">Pharmacy</h6>
          </Col>
        </Row>

        <Row className="bookOnlineFooter">
          <Col>
            <img src={Covid19} />

            <h6 className="mt-3">Covid 19</h6>
          </Col>
        </Row>

        {/* </Row> */}
      </Container>
    </>
  );
};

export default BookOnline;
