import React, { useState, useEffect, useRef } from "react";
import {  NavLink } from "react-router-dom";
import { IoMdNotifications } from "react-icons/io";

import doctorpic from "../assets/images/drPic.png";
import NotificationMenu from "./Notification";
import {  useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {  imageUrl, URL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import $ from "jquery";

const DashboardtopbarRight = ({ noRight, itemsColor, noIcon }) => {
  const history = useHistory();
  const user = useSelector((state) => state?.authReducer?.user);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(null);
  const [isNotificationWindowOpen, setIsNotificationWindowOpen] =
    useState(false);
  const [notiEvent, setNotiEvent] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(0);

  const openNot = Boolean(openNotifications);
  const handleOpenNotifications = (event) => {
    setIsNotificationWindowOpen((p) => !p);
    setNotiEvent(event.currentTarget);

    if (newNotifications !== 0 && accessToken) {
      Get(URL("notifications/seen-all-notfs"), accessToken, false).then((res) =>
        setNewNotifications(0)
      );
    }
  };
  useEffect(() => {
    if (isNotificationWindowOpen) {
      setOpenNotifications(notiEvent);
    } else {
      setOpenNotifications(null);
    }
  }, [isNotificationWindowOpen]);


  const open = Boolean(anchorEl);
 
  const handleState = () => {
    history.push("/Dashboard");
  };

  // notifications API
  const getNotifications = async () => {
    const response = await Get(URL("notifications/all"), accessToken);
    if (response !== undefined) {
      setNotifications(response?.data?.data);
      setNewNotifications(response?.data?.newNotifications);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  // ******************************* Translate *****************************
  const ref = useRef();

  function googleTranslateElementInit() {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        includedLanguages: "en,so",
      },
      "google_translate_element"
    );
  }

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    $(".goog-te-combo > option[value='']").text("English");
    setTimeout(() => {
      $(".goog-te-combo > option[value='']").text("English");
    }, 1800);
  }, []);

  useEffect(() => {
    let _d = document.querySelectorAll(".goog-te-combo");
  });



  return (
    <>
    
        <div className="dashboard-topbar-rightside-box">
   
          <div
            ref={ref}
            id="google_translate_element"
            className="googleLangContainer"
          ></div>

          {user?.role == "doctor" && (
            <NavLink
              to="#"
              className="bell-icon-link"
              onClick={handleOpenNotifications}
              id="basic-button"
              aria-controls={openNot ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openNot ? "true" : undefined}
            >
              <IoMdNotifications
                className={`dashboard-icons ${
                  itemsColor ? "item-color-white" : ""
                }`}
              />
              {newNotifications != 0 && (
                <div className="badge-x">{newNotifications}</div>
              )}
              <NotificationMenu
                openNot={openNot}
                notifications={notifications}
                openNotifications={openNotifications}
                setOpenNotifications={setOpenNotifications}
                setAnchorEl={setAnchorEl}
              />
            </NavLink>
          )}
          {!noRight && (
            <div
              className="dashboard-topbar-rightside c-p"
              onClick={handleState}
            >
              <div className="dashboard-topbar-img-box">
                <img
                  src={`${imageUrl}${user?.photo}`}
                  alt="img"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = doctorpic;
                  }}
                />
              </div>
              <div
                className={`dashboard-topbar-rightside-text ${
                  itemsColor ? "item-color-white" : ""
                }`}
              >
                <span className="small">
                  {" "}
                  {user?.firstName} {user?.middleName} {user?.lastName}{" "}
                </span>
                <span className="small">{user?.role}</span>
              </div>
            </div>
          )}
        </div>

    </>
  );
};

export default DashboardtopbarRight;
