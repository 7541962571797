import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DrPic from "../assets/images/drPic.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { useHistory, useLocation } from "react-router-dom";
import { apiHeader, recordsLimit, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import DoctorMapper from "../components/DoctorMapper";
import PaginationB from "../components/PaginationB";
import BodyLoader from "../components/BodyLoader";
import NullDataComponent from "../components/NullDataComponent";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const DoctorsNear = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [doctors, setDoctors] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);
  const pageParams = new URLSearchParams(window.location.search);
  const city = pageParams.get("city");
  const specialization = pageParams.get("specialization");
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [commission, setCommission] = useState([]);
  console.log("here is commision result ======>", commission);
  // Pagination
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const getCommissions = async () => {
    const url = URL(`config/commissions`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setCommission(response.data?.data);
    }
  };

  useEffect(() => {
    getCommissions();
  }, []);

  useEffect(() => {
    if (!city || !specialization) {
      return history.goBack();
    }

    getData(city, specialization.replaceAll("-", "&"));
  }, [page]);

  // getData
  const getData = async (city, specialization) => {
    const url = URL(`users?limit=${recordsLimit}&page=${page}`);
    const params = {
      city: city,
      specialization: [specialization],
      role: "doctor",
    };
    setIsApiCall(true);
    const response = await Post(url, params, apiHeader(accessToken));
    setIsApiCall(false);

    if (response !== undefined) {
      setTotalCount(Math.ceil(response?.data?.recordsLimit / recordsLimit)); //=> this
      setDoctors(response?.data?.data);
    }
  };

  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  return (
    <div>
      <Container fluid className="dashboard">
        <Row className="">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="doc-near-card">
              <Col md={12} className="navbar-padding-xx">
                <DashboardTopBar />
              </Col>
              <Col md={12} className="mt-5">
                <h5>Nearby Doctor</h5>
              </Col>
              {doctors?.map((doctor) => {
                return (
                  <DoctorMapper
                    doctor={doctor}
                    commission={commission}
                    key={doctor?._id}
                  />
                );
              })}
            </Row>
            {/* this */}
            {totalCount > 1 && (
              <PaginationB
                page={page}
                setPage={setPage}
                totalCount={totalCount}
              />
            )}
          </Col>
        </Row>

        {!isApiCall && doctors.length == 0 && (
          <div className="doctor-near-null-data-c">
            <NullDataComponent text="No doctor found." />
          </div>
        )}
      </Container>

      {isApiCall && <BodyLoader />}
    </div>
  );
};

export default DoctorsNear;
