import React from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { imageUrl } from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { addCartItem, deleteCartItem } from "../store/Actions/cartActions";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import numeral from "numeral";

const PharmacyCard = ({ data }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.cartReducer?.cartItems);
  var numeral = require("numeral");
  return (
    <>
      <div className="pharmacy-card">
        <div className="pharmacyCard p-0">
          <div className="pharmacy-img">
            <img
              src={`${imageUrl}${data?.image}`}
              className="card-img-top"
              alt=""
            />
          </div>
          <div className="card-body p-2">
            <p className="card-text pharmacyPara">
              Category: {data?.catId?.catName}
            </p>
            <h6 className="card-title m-0 t-t-c">{data?.name}</h6>
            <p className="pharmacyMoney m-0">
              {numeral(data?.price).format("0.0a")} (SlSh)
            </p>
            <div className="w-100" style={{ textAlign: "center" }}>
              <button
                className={`pharmacyCardAddBtn ${
                  cartItems.some((e) => e?.id == data?.id) &&
                  "pharmacyCardDeleteBtn"
                }`}
                onClick={() => {
                  {
                    if (cartItems.some((e) => e?.id == data?.id)) {
                      dispatch(deleteCartItem(data));
                    } else {
                      if (data?.stock === 0) {
                        return toast.info("This product is out of stock");
                      }
                      dispatch(
                        addCartItem({
                          quantity: 1,
                          ...data,
                        })
                      );
                    }
                  }
                }}
              >
                {cartItems.some((e) => e?.id == data?.id) ? (
                  <AiFillDelete />
                ) : (
                  <ShoppingCartIcon />
                )}{" "}
                {cartItems.some((e) => e?.id == data?.id)
                  ? "Remove"
                  : "Add to Cart"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyCard;
