export function isMobileViewHook(setIsMobile) {
    getWidthAndDecideScreen(setIsMobile);
    window.addEventListener("load", () => {
        getWidthAndDecideScreen(setIsMobile);
    });
    window.addEventListener("resize", () => {
        getWidthAndDecideScreen(setIsMobile);
    });
}

function getWidthAndDecideScreen(setIsMobile) {
    console.log(window.screen.width);
    if (window.screen.width < 993) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
}