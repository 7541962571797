import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/images/logoWhite.png";
import loginBgImg from "../assets/images/loginBgImg.png";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Select from "react-select";
import {
  URL as NODE_URL,
  apiHeader,
  validateEmail,
  formRegEx,
  formRegExReplacer,
} from "../Config/apiUrl";

import { Get, Post } from "../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { saveLoginUserData, saveRequestId } from "../store/Actions/authAction";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import InputPassword from "../components/InputPassword";

const DoctorSignin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [stepFormCounter, setStepformCounter] = useState(1);
  const [updateImage, setUpdateImage] = useState();
  const inputRef = useRef(null);
  // step 1 states
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countrycode, setCountrycode] = useState("+252");
  const [city, setCity] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // step 2 states
  const [undergraduateCollege, setUndergraduateCollege] = useState("");
  const [undergraduateMajor, setUndergraduateMajor] = useState("");
  const [graduateCollege, setGraduateCollege] = useState("");
  const [typeOfDegree, setTypeOfDegree] = useState("");
  const [degreeYearOfCompletion, setDegreeYearOfCompletion] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [totalExperience, setTotalExperience] = useState("");
  const [shortBio, setShortBio] = useState("");

  // step 3 states
  const [specialization, setSpecialization] = useState([]);
  const [consultationFee, setConsultationFee] = useState("");
  const [address, setAddress] = useState("");
  const [hospitalAddress, setHospitalAddress] = useState("");
  const [education, setEducation] = useState();

  // array states
  const [cityArray, setCityArray] = useState([]);
  const [specializationArray, setSpecializationArray] = useState([]);

  // main states
  const [params1, setParams1] = useState({});
  const [isApiCall, setIsApiCall] = useState(false);

  // get data
  const getData = async () => {
    const url1 = NODE_URL("city?noPagination=true");
    const url2 = NODE_URL("specialization?noPagination=true");
    const [response1, response2] = await Promise.all([
      Get(url1, apiHeader()),
      Get(url2, apiHeader()),
    ]);

    if (response1 && response2) {
      setCityArray(response1.data?.data);
      setSpecializationArray(response2.data?.data);

      setCity(response1.data?.data[0].name);
      setGender("male");
      setSpecialization(response1.data?.data[0].name);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // handleNext
  const handleNext = async (e) => {
    e.preventDefault();
    if (stepFormCounter === 1) {
      const params1 = {
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber,
        countryCode: countrycode,
        city,
        dob,
        gender,
        shortBio,
        completePhoneNumber: `${countrycode}${phoneNumber}`,
        password,
        passwordConfirm: confirmPassword,
      };

      // validate all
      for (let key in params1) {
        if (params1[key] === "")
          return toast.warn(
            `${key.replace(formRegEx, formRegExReplacer)} is required`
          );
      }

      if (!updateImage) {
        return toast.warn("Please upload a profile image");
      }

      // validate email
      if (!validateEmail(email))
        return toast.warn("Please enter a valid email address");

      if (password.length < 8)
        return toast.warn("Password must be at least 8 characters long");

      // validate password
      if (password !== confirmPassword)
        return toast.warn("Password does not match");

      setParams1(params1);
      setStepformCounter((p) => p + 1);
    }

    if (stepFormCounter === 2) {
      const params2 = {
        undergraduateCollege,
        undergraduateMajor,
        // graduateCollege,
        education: education,
        typeOfDegree: typeOfDegree,
        // yearOfCompletion: degreeYearOfCompletion,
        // membershipNumber,
        totalExp: totalExperience,
      };

      // validate all
      for (let key in params2) {
        if (params2[key] === "")
          return toast.warn(
            `${key.replace(formRegEx, formRegExReplacer)} is required`
          );
      }

      setParams1((p) => ({
        ...p,
        ...params2,
        membershipNumber: membershipNumber,
        graduateCollege: graduateCollege,
        yearOfCompletion: degreeYearOfCompletion,
      }));
      setStepformCounter((p) => p + 1);
    }

    if (stepFormCounter === 3) {
      const params3 = {
        consultationFee,
        address,
        hospitalAddress,
        role: "doctor",
      };

      // validate all
      for (let key in params3) {
        if (params3[key] === "")
          return toast.warn(
            `${key.replace(formRegEx, formRegExReplacer)} is required`
          );
      }

      if (specialization.length === 0)
        return toast.warn("Please select at least one specialization");

      const _specialization = specialization.map((item) => item.name);

      // add to formData
      const formData = new FormData();
      for (let key in params1) {
        formData.append(key, params1[key]);
      }
      for (let key in params3) {
        formData.append(key, params3[key]);
      }
      for (let key in _specialization) {
        formData.append("doctorSpecialization", _specialization[key]);
      }
      formData.append("photo", updateImage);

      // api call
      const url = NODE_URL("users/signup");
      setIsApiCall(true);
      const response = await Post(url, formData, apiHeader());
      setIsApiCall(false);
      if (response !== undefined) {
        dispatch(saveLoginUserData(response?.data));
        dispatch(saveRequestId(response?.data?.data?.requestId));

        history.replace("/verify");
      } else {
        // iff error => clear form :)
        formData.delete("photo");
        for (let key in params1) {
          formData.delete(key);
        }
        for (let key in params3) {
          formData.delete(key);
        }
        for (let key in _specialization) {
          formData.delete("specialization");
        }
      }
    }
  };

  return (
    <>
      <Container fluid className="optScreen">
        <Row>
          <Col lg={12}>
            <div className="logo-btn-x">
              <img src={logo} alt="logo" />
            </div>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={2}></Col>
              <Col lg={8} className="">
                <div className="opt-outer-div">
                  <Row className="d-flex dashboadrd_setting  info_div">
                    <Row className="personalInfo2 m-auto ">
                      <Col>
                        <h2 className="text-white p-3 ps-5">
                          {stepFormCounter == 1 && "Personal Information"}
                          {stepFormCounter == 2 && "Educational Information"}
                          {stepFormCounter == 3 &&
                            "Services and Specialization"}
                        </h2>
                      </Col>
                    </Row>

                    {/* Step 1 */}
                    {stepFormCounter == 1 && (
                      <>
                        {/* Image */}
                        <Row className="my-5 m-auto  ">
                          <Col>
                            <Row className="d-flex ">
                              <Col md={2}>
                                {typeof updateImage === "object" ? (
                                  <div className="cameraicon m-auto">
                                    <img
                                      src={URL.createObjectURL(updateImage)}
                                    />
                                  </div>
                                ) : (
                                  <div className="cameraicon m-auto">
                                    <CameraAltIcon className="CameraAltIcon" />
                                  </div>
                                )}
                              </Col>

                              <Col md={10} className="persnolInfo_upload">
                                <div className="text-left ms-4 UploadProfile">
                                  <label
                                    class="setting_fileupload "
                                    onClick={() => {
                                      inputRef.current.click();
                                    }}
                                  >
                                    <div className="setting-innerDiv_fileUpload ">
                                      <div className="d-flex j-center a-center  c-p">
                                        <span>Upload Image </span>
                                      </div>
                                    </div>
                                  </label>
                                  <p>
                                    This will be displayed to other users when
                                    they view your profile. Max size: 2MB
                                  </p>
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    size="2000000"
                                    className="file_upload_form3rd"
                                    //   name="image2"
                                    onChange={(e) => {
                                      if (e.target.files?.length > 0) {
                                        if (
                                          ![
                                            "image/jpeg",
                                            "image/png",
                                            "image/jpg",
                                          ].includes(e.target.files[0].type)
                                        ) {
                                          return toast.error(
                                            "Please upload a valid image. [jpg and png formats only]"
                                          );
                                        }
                                      }
                                      setUpdateImage(e.target.files[0]);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        {/* First Name and Last name */}
                        <Row className="m-auto">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">First Name :</label>
                              <input
                                type="text"
                                placeholder="First Name"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Middle Name :</label>
                              <input
                                type="text"
                                placeholder="Middle Name"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                              />
                            </div>
                          </Col>

                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Last Name :</label>
                              <input
                                type="text"
                                placeholder="Last Name"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Email Address :</label>
                              <input
                                type="email"
                                placeholder="Email Address"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </Col>
                          {/* country code */}
                          <Col md={6} sm={12}>
                            <label className="mb-2">Phone Number :</label>
                            <div className="input-group mb-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text city-code-span2">
                                  {`SO${countrycode}`}
                                </span>
                              </div>
                              <input
                                type="number"
                                placeholder="63xxxxx"
                                className="form-control city-code-inp2"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            </div>
                          </Col>

                          
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="">Select City :</label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              >
                                {cityArray.map((city) => (
                                  <option value={city?.name} className="t-t-c">
                                    {city?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <label className="mb-2">Passowrd :</label>
                            <InputPassword
                              placeHoldertext={"Passowrd"}
                              value={password}
                              newValue={setPassword}
                            />
                          </Col>

                          <Col md={6} sm={12}>
                            <label className="mb-2">Confirm Password :</label>
                            <InputPassword
                              placeHoldertext={"Confirm Password"}
                              value={confirmPassword}
                              newValue={setConfirmPassword}
                            />
                          </Col>
                
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Date of Birth :</label>

                              <input
                                type="date"
                                placeholder="Date of Birth"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                min={Date.now()}
                              />
                            </div>
                          </Col>

                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="">Gender :</label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                              >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                          </Col>

                          <Col md={12} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Short Bio :</label>

                              <textarea
                                type="text-ae"
                                placeholder="Short Bio"
                                rows="4"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={shortBio}
                                onChange={(e) => setShortBio(e.target.value)}
                                style={{
                                  borderRadius: "15px",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {/* Step 2 */}
                    {stepFormCounter == 2 && (
                      <>
                        {/* undergraduate college and major */}
                        <Row className="m-auto mt-4">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">
                                Undergraduate University :
                              </label>

                              <input
                                type="text"
                                placeholder="Undergraduate University"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={undergraduateCollege}
                                onChange={(e) =>
                                  setUndergraduateCollege(e.target.value)
                                }
                              />
                            </div>
                          </Col>

                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">
                                Undergraduate Major :
                              </label>

                              <input
                                type="text"
                                placeholder="Undergraduate Major"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={undergraduateMajor}
                                onChange={(e) =>
                                  setUndergraduateMajor(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* graduate college and type of degree */}
                        <Row className="m-auto">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Graduate College :</label>

                              <input
                                type="text"
                                placeholder="Graduate College"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={graduateCollege}
                                onChange={(e) =>
                                  setGraduateCollege(e.target.value)
                                }
                              />
                            </div>
                          </Col>

                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">
                                Type of specailty :
                              </label>

                              <input
                                type="text"
                                placeholder="Type of specailty"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={typeOfDegree}
                                onChange={(e) =>
                                  setTypeOfDegree(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* year of completion and membership number */}
                        <Row className="m-auto">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">
                                Data of Graduation :
                              </label>

                              <input
                                type="date"
                                placeholder="Data of Graduation"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={degreeYearOfCompletion}
                                onChange={(e) =>
                                  setDegreeYearOfCompletion(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Higest Education :</label>

                              <input
                                type="text"
                                placeholder="Higest Education"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={education}
                                onChange={(e) => setEducation(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* Experience */}
                        <Row className="m-auto">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">
                                Certificate Number :
                              </label>

                              <input
                                type="number"
                                placeholder="Certificate Number"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={membershipNumber}
                                onChange={(e) =>
                                  setMembershipNumber(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">
                                Total Experience (in years) :
                              </label>

                              <input
                                type="number"
                                placeholder="Total Experience (in years)"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={totalExperience}
                                onChange={(e) =>
                                  setTotalExperience(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {/* Step 3 */}
                    {stepFormCounter == 3 && (
                      <>
                        {/* specialization */}
                        <Row className="m-auto">
                          <Col md={12} sm={12}>
                            <div className="my-4">
                              <label className="mb-2">specialization :</label>

                              <Select
                                isMulti
                                name="colors"
                                options={specializationArray}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={specialization}
                                onChange={(e) => setSpecialization(e)}
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* consultation  and address */}
                        <Row className="m-auto">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Consultation Fee :</label>

                              <input
                                type="number"
                                placeholder="Consultation Fee"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={consultationFee}
                                onChange={(e) =>
                                  setConsultationFee(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Your Address :</label>

                              <input
                                type="text"
                                placeholder="Your Address"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>

                        {/* hospital address */}
                        <Row className="m-auto">
                          <Col md={6} sm={12}>
                            <div className="mb-4">
                              <label className="mb-2">Hospital Address :</label>

                              <input
                                type="text"
                                placeholder="Hospital Address"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={hospitalAddress}
                                onChange={(e) =>
                                  setHospitalAddress(e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {/* Buttons */}
                    <Row className="m-auto">
                      <Col md={12} sm={12}>
                        <div className="mb-4 personalInfo-btns">
                          <button
                            disabled={stepFormCounter == 1}
                            onClick={() => setStepformCounter((p) => p - 1)}
                          >
                            Back
                          </button>
                          <button disabled={isApiCall} onClick={handleNext}>
                            {stepFormCounter < 3
                              ? "Next"
                              : isApiCall
                              ? "Please Wait..."
                              : "Submit"}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </div>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Col>
          <Col lg={12}>
            <div className="otp-img2">
              <img src={loginBgImg} alt="img" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DoctorSignin;
