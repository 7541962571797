import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SolutionInOneApp from "../components/SolutionInOneApp";

import { Avatar, Stack } from "@mui/material";

import Scroll from "../assets/images/Scroll.png";
import Quote from "../assets/images/Group 6.png";

import helpbg_1 from "../assets/images/helpbg_1.png";
import homeBgRightangle from "../assets/images/home-bg-rightangle.png";
import BannerRound from "../assets/images/BannerRound.png";
import crossImage from "../assets/images/cross.png";
import bannerBgLine from "../assets/images/bannerBgLine.png";

import { Get } from "../Axios/AxiosFunctions";
import { imageUrl, URL as baseUrl } from "../Config/apiUrl";

import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";

const avatarSm = { width: 57, height: 57 };
const avatarLg = { width: 97, height: 97 };

function Home() {
  const [whatPeopleSayAboutUsData, setWhatPeopleSayAboutUsData] = useState([]);
  const [cmsData, setCmsData] = useState([]);
  const [solutionInOneAppCmsData, setSolutionInOneAppCmsData] = useState([]);
  const [appStoreLinkcmsData, setAppStoreLinkcmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [howCanDaweeyeHelpData, setHowCanDaweeyeHelpData] = useState([]);

  const [
    visionAndMissionAnalysis1CmsData,
    setVisionAndMissionAnalysis1CmsData,
  ] = useState([]);
  const [
    visionAndMissionAnalysis2CmsData,
    setVisionAndMissionAnalysis2CmsData,
  ] = useState([]);

  const getHomeCmsData = async () => {
    const url = baseUrl(
      `cms/page/home?solutionInOneApp=true&&howCanDaweeyeHelp=true&&appStoreLinks=true&&whatPeopleSayAboutUs=true&visionAndMissionAnalysis1=true&visionAndMissionAnalysis2=true`
    );

    const response = await Get(url);
    if (response !== undefined) {
      setCmsData(response?.data?.data);
      setSolutionInOneAppCmsData(response?.data?.solutionInOneAppData);
      setAppStoreLinkcmsData(response?.data?.appStoreLinksData);
      setHowCanDaweeyeHelpData(response?.data?.howCanDaweeyeHelpData);
      setVisionAndMissionAnalysis1CmsData(
        response?.data?.visionAndMissionAnalysis1Data
      );
      setVisionAndMissionAnalysis2CmsData(
        response?.data?.visionAndMissionAnalysis2Data
      );
      let _whatPeopleSayAboutUsData = response?.data?.whatPeopleSayAboutUsData;
      if (_whatPeopleSayAboutUsData.length > 3)
        _whatPeopleSayAboutUsData.length = 3;
      setWhatPeopleSayAboutUsData(_whatPeopleSayAboutUsData);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getHomeCmsData();
  }, []);

  // shuffleData
  let shuffleData = (indexToMove) => {
    if (
      indexToMove === 1 ||
      indexToMove > whatPeopleSayAboutUsData.length - 1
    ) {
      return;
    }

    let arr = [...whatPeopleSayAboutUsData];
    arr[1] = arr[indexToMove];
    arr[indexToMove] = whatPeopleSayAboutUsData[1];

    setWhatPeopleSayAboutUsData(arr);
  };

  return (
    <>
      <div
        className={`w-100 ${isLoading && "opacity-zero"}`}
        style={{ overflow: "hidden" }}
      >
        <Container className="home_banner_b mb-5">
          <Row className="home_bg_picture home-bg ">
            <Col lg={6} md={12} sm={12} className="home_first_sec_left">
              <img
                src={bannerBgLine}
                className="bannerBgLine img-fluid"
                alt="Vector png"
              />
              <img
                src={homeBgRightangle}
                className="homeBgRightangle"
                alt="Vector png"
              />
              <img src={BannerRound} className="BannerRound" alt="Vector png" />
              <img src={crossImage} className="crossImage" alt="Vector png" />

              <div className="heading">
                <h3 className="text-color font-60 fw-bold">
                  {cmsData?.sec1Heading}
                </h3>
                <p className="mt-3 mb-5 text-left">
                  {cmsData?.sec1Description}
                </p>
              </div>
              <div className="storeImg">
                {appStoreLinkcmsData.map((item, index) => {
                  return (
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img
                        fluid
                        src={`${imageUrl}${item?.image}`}
                        className="me-4 storeImages"
                        style={{ maxWidth: "100%" }}
                        alt="Vector png"
                      />
                    </a>
                  );
                })}
              </div>
            </Col>

            <Col lg={6} md={12}>
              <img
                className="home-right-first"
                src={`${imageUrl}${cmsData?.sec1Image}`}
                style={{ maxWidth: "100%" }}
                alt="Vector png"
                onLoadStart={(e) => console.log(e, "tete")}
                loading="lazy"
              />
            </Col>

            <a href="#solution" className="ScrollIcon ">
              <div>
                <img fluid src={Scroll} alt="Vector png" />
              </div>
            </a>
          </Row>
        </Container>

        <Container fluid id="solution">
          <Row className="row_margin mt-5">
            <div className="max-width ">
              <SolutionInOneApp
                cmsData={cmsData}
                solutionInOneAppCmsData={solutionInOneAppCmsData}
              />
            </div>
          </Row>
        </Container>

        <section className="doctorRedBg">
          <Container className="custmContainer">
            <Row>
              <Col md={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
                <div className="redBgImg"></div>
                <div className="home_laptopImg">
                  <img src={`${imageUrl}${cmsData?.sec3Image}`} alt="" />
                </div>
              </Col>
              <Col
                md={{ span: 12, order: 1 }}
                lg={{ span: 6, order: 2 }}
                className="center_small"
              >
                <div className="about_mytute">
                  <h3 className="mb-4 text-color fw-bold">
                    {cmsData?.sec3Heading}
                  </h3>
                  <p className="text-left">{cmsData?.sec3Description}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* here */}
        <Container className="howCanDawaeeHelp_main">
          <Row className="row_margin left_bg">
            <div className="text-center">
              <Row className="d-flex justify-content-center">
                <Col className="how_can_we_help_col">
                  <h3 className="font-60 fw-bold text-center text-color mb-3">
                    {cmsData?.sec4Heading}
                  </h3>
                  <p
                    className="text-center m-auto dHelpPara"
                    style={{ maxWidth: "60%" }}
                  >
                    {cmsData?.sec4Description}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12} lg={6}>
                  <div className="mainDiv_circle">
                    {visionAndMissionAnalysis1CmsData.map((item, index) => {
                      return (
                        <div key={index} className="circle_vision">
                          <div
                            className={`circle ${
                              visionAndMissionAnalysis1CmsData.length - 1 ===
                              index
                                ? ""
                                : "line"
                            }`}
                          ></div>
                          <div className="circle_text">
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col md={12} lg={6}>
                  <div className="dawaee_help_img">
                    <img
                      fluid
                      alt=""
                      src={`${imageUrl}${cmsData?.sec4Image}`}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="justify-content-center text-center my-5">
              <img src={helpbg_1} className="helpbg_1" alt="Vector png" />
            </div>
            <div className="justify-content-center text-center appStore_googlePlay">
              {appStoreLinkcmsData.map((item, index) => {
                return (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <img
                      fluid
                      src={`${imageUrl}${item?.image}`}
                      className="me-4 storeImages"
                      style={{ maxWidth: "100%" }}
                      alt="Vector png"
                    />
                  </a>
                );
              })}
            </div>
          </Row>
        </Container>

        <div
          className="container-fluid people-says-about-us"
          style={{ height: "910px" }}
        >
          <Row className="justify-content-center whatPeopleSays">
            <Col
              lg={6}
              md={12}
              className="d-flex align-items-start quoteImg_main"
            >
              <div className="quoteImg">
                <img fluid src={Quote} alt="quote" />
              </div>
              <h3
                className="text-white text-left fw-bold font-60 people-says-about-us-title-box whatPeopleHead"
                style={{ maxWidth: "70%", zIndex: "9" }}
              >
                {cmsData?.sec5Heading}
              </h3>
            </Col>

            <Col
              lg={6}
              md={12}
              className="text-left d-flex align-items-start justify-content-center"
            >
              <div style={{ maxWidth: "70%" }}>
                <p className="people-says-about-us-text-box text-white font-18">
                  {whatPeopleSayAboutUsData?.length > 0 &&
                    whatPeopleSayAboutUsData[1].description}
                </p>
                <hr
                  style={{
                    maxWidth: "100%",
                    height: "2px",
                    backgroundColor: "grey",
                  }}
                />

                <div className=" avatarImg">
                  <Stack direction="row" spacing={2} alignItems="center">
                    {whatPeopleSayAboutUsData?.map((e, i) => {
                      return (
                        <div onClick={() => shuffleData(i)}>
                          <Avatar
                            key={i}
                            alt="Cindy Baker"
                            className="c-p"
                            sx={i == 1 ? avatarLg : avatarSm}
                          >
                            {e?.subTitle?.[0] || "A"}
                          </Avatar>
                        </div>
                      );
                    })}
                  </Stack>
                  <p className="text-white mt-3">
                    {whatPeopleSayAboutUsData?.length > 0 &&
                      whatPeopleSayAboutUsData[1]?.subTitle}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Container className="book_doctor">
          <Row className="justify-content-center">
            <Col
              lg={6}
              md={{ span: 12, order: 2 }}
              className="book_dr_img fluid"
            >
              <div className="book_dr_img fluid">
                <img
                  fluid
                  src={`${imageUrl}${cmsData?.sec6Image}`}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </Col>

            <Col
              lg={5}
              md={{ span: 12, order: 1 }}
              className="book_doctor_headings"
            >
              <h3 className="m-0 text-color font-60 fw-bold text-left text-left ">
                {cmsData?.sec6Heading}
              </h3>
              <p className="mt-3 mb-5 text-left">{cmsData?.sec6Description}</p>

              <div className="storeImg">
                {appStoreLinkcmsData.map((item, index) => {
                  return (
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img
                        fluid
                        src={`${imageUrl}${item?.image}`}
                        className="me-4 storeImages"
                        style={{ maxWidth: "100%" }}
                        alt="Vector png"
                      />
                    </a>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {isLoading && <BeforeLoadContentLoader />}
    </>
  );
}

export default Home;
