import React from "react";

import ModalSkeleton from "./ModalSkeleton";

import { imageUrl } from "../Config/apiUrl";

import { AiFillCloseCircle } from "react-icons/ai";
import ShowMoreShowLessText from "./ShowMoreShowLessText";

const DetailModal = ({
  open,
  setOpen,
  label,
 
  modalData,
}) => {
  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            paddingBottom: "30px",
          }}
          onClick={() => setOpen(false)}
        >
          <AiFillCloseCircle style={{ fontSize: 40, color: "#0B30A9" }} />
        </div>
        <form className="login100-form validate-form">
          <span className="login100-form-title are-you-sure-label">
            {label}
          </span>
          <div style={{ height: "400px", objectFit: "contain" }}>
            <img
              src={`${imageUrl}${modalData?.imageUrl}`}
              alt=""
              width={"100%"}
              style={{ height: "400px", objectFit: "contain" }}
            />
          </div>
          <h2 className="mt-5">{modalData?.title}</h2>
          <p>
            <ShowMoreShowLessText text={modalData?.description} />
          </p>
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default DetailModal;
