import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { imageUrl, URL as baseUrl } from "../Config/apiUrl";

const Explorebycategory = ({ exploreByCategoryCmsData }) => {
  return (
    <>
      <section>
        <Container>
          <Row className="exploreCategory-row">
            {exploreByCategoryCmsData.map((item, index) => {
              return (
                <Col lg={3} md={6} sm={6} xs={12}>
                  <div className="exploreCategory">
                    <img src={`${imageUrl}${item.image}`} alt="" />
                    <h5>{item.title}</h5>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Explorebycategory;
