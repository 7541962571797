import {
  SET_CART_ALL_ITEMS,
  ADD_CART_ITEM,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEM,
  CLEAR_CART,
} from "./actionType";

export const setCartAllItems = (cartItems) => {
  return {
    type: SET_CART_ALL_ITEMS,
    payload: cartItems,
  };
};

export const addCartItem = (cartItem) => {
  return {
    type: ADD_CART_ITEM,
    payload: cartItem,
  };
};

export const deleteCartItem = (cartItem) => {
  return {
    type: DELETE_CART_ITEM,
    payload: cartItem,
  };
};

export const updateCartItem = (cartItem, cartAction) => {
  return {
    type: UPDATE_CART_ITEM,
    payload: cartItem,
    cartAction: cartAction,
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};
