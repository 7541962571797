import React from "react";
import doctorpic from "../assets/images/drPic.png";
import { useSelector } from "react-redux";

import moment from "moment";
import { imageUrl } from "../Config/apiUrl";

const AppointmentSm = ({ data, onClick }) => {
  const user = useSelector((state) => state?.authReducer?.user);

  const appointmentWith = ["doctor", "covid19"].includes(user?.role)
    ? data?.patientId
    : data?.appointmentWith == "covid19"
    ? data?.covid19Id
    : data?.doctorId;

  return (
    <>
      <div className="Appointments-box2">
        <div className="dashborad-message-box c-p" onClick={onClick}>
          <div className="msg-img-box">
            <img
              src={`${imageUrl}${appointmentWith?.photo}`}
              alt="img"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = doctorpic;
              }}
            />
          </div>
          <div className="msg-text">
            <span className=".big">
              {appointmentWith?.displayName || "Some Name"}
            </span>
            <span className=".small">
              {moment(data?.appointmentDate).format("ddd MM YYYY")},{" "}
              {data?.appointmentStartTime}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentSm;
