import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import virus from "../assets/images/virus.png";
import VirusCut from "../assets/images/virus_cut.png";
import Transmission from "../components/Transmission";


import LatestUpdateCovid19 from "../components/LatestUpdateCovid19";


import { Get } from "../Axios/AxiosFunctions";
import { imageUrl, URL as baseUrl } from "../Config/apiUrl";
import DetailModal from "../components/DetailModal";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";

const Covid19 = () => {
  const [cmsData, setCmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [transmissionOfCovid19, setTransmissionOfCovid19] = useState([]);
  const [latestUpdateAboutCovid19, setLatestUpdateAboutCovid19] = useState([]);
  const [appStoreLinkcmsData, setAppStoreLinkcmsData] = useState([]);

  const [modalData, setModalData] = useState({});

  const [openDetailModal, setOpenDetailModal] = useState(false);

  const getCovid19CmsData = async () => {
    const url = baseUrl(
      `cms/page/covid19?transmissionOfCovid19=true&latestUpdateAboutCovid19=true&appStoreLinks=true`
    );

    const response = await Get(url);
    if (response !== undefined) {
      setCmsData(response?.data?.data);
      setTransmissionOfCovid19(response?.data?.transmissionOfCovid19Data);
      setLatestUpdateAboutCovid19(response?.data?.latestUpdateAboutCovid19Data);
      setAppStoreLinkcmsData(response?.data?.appStoreLinksData);
    }
    // setIsLoading(false);
  };

  // to scroll top up when page refreshed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getCovid19CmsData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeOut);
  }, []);

  const openCheck = (imageUrl, title, description) => {
    setOpenDetailModal(true);
    setModalData({ imageUrl, title, description });
  };

  return (
    <>
      <div
        className={`w-100 ${isLoading && "opacity-zero"}`}
        style={{ overflow: "hidden" }}
      >
        <Container className="home_banner_b mb-5">
          <Row className="home_bg_picture home-bg ">
            <Col lg={6} md={12} sm={12} className="home_first_sec_left">
              <div className="heading">
                <h3 className="m-0 text-color font-60 fw-bold ">
                  {cmsData?.sec1Heading}{" "}
                </h3>
                <p className="mt-3 mb-5 text-left">
                  {cmsData?.sec1Description}
                </p>
              </div>
            </Col>

            <Col lg={6} md={12}>
              <div className="mobileandvirus covid_mobile">
                <img
                  className="home-right-first"
                  src={`${imageUrl}${cmsData?.sec1Image}`}
                  style={{ maxWidth: "100%" }}
                  alt="vector mobile"
                />
                <img
                  className="virus-right-first"
                  src={virus}
                  style={{ maxWidth: "100%" }}
                  alt="vector mobile"
                />
              </div>
            </Col>
          </Row>
        </Container>

        <section className="doctorRedBg marginTop coronavirus-covid-19">
          <Container className="custmContainer">
            <Row>
              <Col lg={6} md={12} className="man-with-mask">
                <div className="redBgImg"></div>
                <div className="home_laptopImg">
                  <img src={`${imageUrl}${cmsData?.sec2Image}`} alt="" />
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="center_small coronavirus-col">
                <div className="about_mytute coronavirus_covid19">
                  <h3 className="mb-4 text-color fw-bold">
                    {cmsData?.sec2Heading}
                  </h3>
                  <p className="text-left">{cmsData?.sec2Description}</p>
                </div>
                <div className="virusCut">
                  <Image src={VirusCut} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* _________________________Transmission of Covid-19_____________________ */}

        <Container className="marginTop">
          <Row className=" left_bg">
            <div className="text-center">
              <Row className="d-flex justify-content-center">
                <Col className="">
                  <h3 className="font-60 fw-bold text-center text-color mb-4">
                    {cmsData?.sec3Heading}
                  </h3>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="transmition_main">
            {transmissionOfCovid19.map((item, index) => {
              return (
                <>
                  <Transmission item={item} index={index} />
                </>
              );
            })}
          </Row>
        </Container>

        {/* ____________________________________ */}

        {/* ____________________________What sholud I vaccine___________________________ */}

        <section className="doctorRedBg marginTop">
          <Container className="custmContainer">
            <Row>
              <Col
                md={6}
                sm={12}
                className="center_small"
                // style={{ display: "flex", justifyContent: "left", textAlign: "left" }}
              >
                <div className="about_mytute whatsholudIVaccine">
                  <h3 className="mb-4 text-color fw-bold">
                    {cmsData?.sec4Heading}
                  </h3>
                  <p className="text-left">{cmsData?.sec4Description}</p>
                  <div className="storeImg">
                    {appStoreLinkcmsData.map((item, index) => {
                      return (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img
                            fluid
                            src={`${imageUrl}${item?.image}`}
                            className="me-4 storeImages"
                            style={{ maxWidth: "100%" }}
                            alt="Vector png"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="redBgImg_Right"></div>
                <div className="home_laptopImg_Right">
                  <img src={`${imageUrl}${cmsData?.sec4Image}`} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* _______________________________________________________ */}

        <Container className="mb-5">
          <Row className="row_margin left_bg">
            <div className="text-center">
              <Row className="d-flex justify-content-center">
                <Col className="">
                  <h3 className="font-60 fw-bold text-center text-color latest_update_heading">
                    {cmsData?.sec5Heading}
                  </h3>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="latestUpdate_main">
            {latestUpdateAboutCovid19.map((item, index) => {
              return (
                <>
                  <LatestUpdateCovid19
                    // onClick={() => openCheck}
                    key={index}
                    item={item}
                    index={index}
                    setFun={openCheck}
                  />
                </>
              );
            })}
          </Row>
        </Container>

        <Container className="book_doctor">
          <Row className="justify-content-center">
            <Col md={6} sm={12} className="book_dr_img fluid">
              <div className="book_dr_img fluid">
                <img
                  fluid
                  src={`${imageUrl}${cmsData?.sec6Image}`}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </Col>

            <Col md={5} sm={12} className="book_doctor_headings">
              <h3 className="m-0 text-color font-60 fw-bold text-left text-left ">
                {cmsData?.sec6Heading}
              </h3>
              <p className="mt-3 mb-5 text-left">{cmsData?.sec6Description}</p>

              <div className="storeImg">
                {appStoreLinkcmsData.map((item, index) => {
                  return (
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img
                        fluid
                        src={`${imageUrl}${item?.image}`}
                        className="me-4 storeImages"
                        style={{ maxWidth: "100%" }}
                        alt="Vector png"
                      />
                    </a>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>

        <DetailModal
          open={openDetailModal}
          setOpen={setOpenDetailModal}
          modalData={modalData}
        />
      </div>

      {isLoading && <BeforeLoadContentLoader />}
    </>
  );
};

export default Covid19;
