import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

const LabServiceModal = (props) => {
  const { modalData, isLoading, handleYes } = props;
  const [serviceName, setServiceName] = useState(modalData?.serviceName || "");
  const [servicePrice, setServicePrice] = useState(
    modalData?.servicePrice || ""
  );
  const covid19Services = useSelector(
    (state) => state?.authReducer?.user?.covid19Services
  );

  // emptyStates
  const emptyStates = () => {
    setServiceName("");
    setServicePrice("");
  };

  useEffect(() => {
    return () => {
      emptyStates();
    };
  }, []);

  // handleSubmit
  const handleSubmit = () => {
    if (serviceName === "" || servicePrice === "")
      return toast.warn("Please fill all the fields");

    if (servicePrice < 1) return toast.warn("Please enter a valid price");

    if (modalData) {
      const selectedItemIndex = covid19Services?.findIndex(
        (item) => item?._id === modalData?._id
      );
      covid19Services[selectedItemIndex].serviceName = serviceName;
      covid19Services[selectedItemIndex].servicePrice = servicePrice;
      handleYes(
        covid19Services,
        "Service edited successfully.",
        "initial",
        emptyStates
      );
    } else {
      let finalData = [
        ...covid19Services,
        {
          serviceName,
          servicePrice,
        },
      ];
      handleYes(
        finalData,
        "Service added successfully",
        "initial",
        emptyStates
      );
    }
  };

  useEffect(() => {
    return () => {
      setServiceName("");
      setServicePrice("");
    };
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box appointment_Des_modal small-modal-x"
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="appointmentDespModalBody">
                <div className="appointmentDesrModal_inner">
                  <h4>{modalData ? "Edit" : "Add"} Service</h4>

                  {/* Service Name */}
                  <div className="mb-3">
                    <label className="form-label l-services-m-text">
                      Service Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter service name"
                      className="form-control"
                      value={serviceName}
                      onChange={(e) => setServiceName(e.target.value)}
                    />
                  </div>
                  {/* Service Charges */}
                  <div className="mb-3">
                    <label className="form-label l-services-m-text">
                      Service Charges
                    </label>
                    <input
                      type="number"
                      placeholder="Enter service charges"
                      className="form-control"
                      value={servicePrice}
                      onChange={(e) => setServicePrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-btn apppointmentDest_closeBtn">
                  <button onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? "Please Wait..." : "Submit"}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LabServiceModal;
