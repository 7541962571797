import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import DrPic from "../assets/images/drPic.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { BsTelephone, BsGenderAmbiguous, BsPlus } from "react-icons/bs";
import { FiType } from "react-icons/fi";
import moment from "moment";

import {
  MdOutlineAccountBalance,
  MdEmail,
  MdLocationCity,
} from "react-icons/md";
import { FaBirthdayCake } from "react-icons/fa";
import { imageUrl, URL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";
import MessageModal from "../components/MessageModal";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FlagIcon from "@mui/icons-material/Flag";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const DoctorDetail = () => {
  const history = useHistory();
  const [isApiCall, setIsApiCall] = useState(false);
  const [profileData, setProfileData] = useState({});
  console.log(
    "🚀 ~ file: DoctorDetail.js ~ line 36 ~ DoctorDetail ~ profileData",
    profileData?.education
  );
  const [schedule, setSchedule] = useState([]);
  let [newText, setnewText] = useState("about");
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const [currentProfileId, setCurrentProfileId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);
  const [commissions, setCommissions] = useState([]);
  var numeral = require("numeral");
  let about = ` Lorem ipsum dolor sit amet, consectetur adipiscing
    elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis
    nostrud exercitation ullamco laboris nisi ut aliquip
    ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu
    fugiat nulla pariatur. Excepteur sint occaecat
    cupidatat non proident, sunt in culpa qui officia
    deserunt mollit anim id est laborum.`;

  // getData
  const getData = async (id) => {
    const url = URL(`users/profile/${id}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setProfileData(response.data?.data);
      setSchedule(response.data?.schedule);
    }
  };
  const getCommissions = async () => {
    const url = URL(`config/commissions`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);

    if (response !== undefined) {
      setCommissions(response.data?.data);
      console.log("===================setCommissions", response?.data?.data);
    }
  };

  const doctorCommission = commissions[2]?.adminCommission?.rate;
  const covidCommission = commissions[1]?.adminCommission?.rate;

  useEffect(() => {
    const pageParams = new URLSearchParams(window.location.search);
    const id = pageParams.get("id");
    setCurrentProfileId(id);
    getData(id);
    getCommissions();
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  return (
    <div>
      <Container fluid className="dashboard">
        <Row className="">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="my-3 g-0">
              <DashboardTopBar />
              <Col lg={12} className="mt-5">
                {/* doctor head */}
                {profileData?.role === "doctor" && (
                  <Row>
                    <Col md={6}>
                      {/* <div className=""> */}
                      <div className="doctor-detail-section1">
                        <div className="doctor-detail-imgBox">
                          <img
                            src={`${imageUrl}${
                              profileData?.photo == "default.jpg"
                                ? "/test/"
                                : profileData?.photo
                            }`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = DrPic;
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          <h6 className="t-t-c">
                            {`${profileData?.firstName || ""} ${
                              profileData?.middleName || ""
                            } ${profileData?.lastName || ""}`}
                          </h6>
                          <p>
                            <span className="profile-list">
                              {profileData?.doctorSpecialization?.length > 0 &&
                                profileData?.doctorSpecialization[0]}{" "}
                            </span>
                            <span
                              style={{
                                color: "#EF464B",
                                fontSize: "14px",
                                marginLeft: "10px",
                              }}
                            >
                        
                            </span>
                          </p>

                          <p>
                            <LocationOnIcon
                              style={{ color: "#EF464B", fontSize: "15px" }}
                            />
                         
                            <span className="profile-list">
                              {profileData?.hospitalAddress ||
                                "Hospital Address"}
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                    </Col>
                    <Col md={3}>
                      <div className="profile-info-container  doctor-detail-sectionss">
                        <p>
                          <BsTelephone className="doctordetail-icon" />{" "}
                          <span className="profile-list">{`${
                            profileData?.countryCode || "+1"
                          } ${profileData?.phoneNumber}`}</span>
                        </p>
                        <p>
                          <MdOutlineAccountBalance className="doctordetail-icon" />{" "}
                          <span className="profile-list">
                            {profileData?.country || "Nil"}
                          </span>
                        </p>
                        <p>
                          <BsGenderAmbiguous className="doctordetail-icon" />{" "}
                          <span className="profile-list">
                            {profileData?.gender || "Nil"}
                          </span>
                        </p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className=" doctor-detail-sectionss">
                        <p>
                          <MdEmail className="doctordetail-icon" />{" "}
                          <span className="profile-list">
                            {profileData?.email}
                          </span>
                        </p>
                        <p>
                          <MdLocationCity className="doctordetail-icon" />{" "}
                          <span className="profile-list">
                            {profileData?.city || "Nil"}
                          </span>
                        </p>
                        <p>
                          <FaBirthdayCake className="doctordetail-icon" />{" "}
                          <span className="profile-list">
                            {moment(profileData?.dob).format("Do MMM YYYY")}
                          </span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}

                {/* Covid19 head */}
                {profileData?.role === "covid19" && (
                  <Row className="my-5">
                    <Col lg={5} md={12}>
                      <div className="MedicalHead">
                        <div className="doctor-detail-imgBox">
                          <img src={`${imageUrl}${profileData?.photo}`} />
                        </div>
                        <div>
                          <h5 className="t-t-c">{`${
                            profileData?.firstName || ""
                          } ${profileData?.middleName || ""} ${
                            profileData?.lastName || ""
                          }`}</h5>
                          <p>
                            For emergency Call :
                            {profileData?.licenseNumber || "Nil"}
                          </p>
                          <p>Visitors :{profileData?.visitors || "Nil"}</p>
                
                        </div>
                      </div>
                    </Col>

                    <Col lg={3} md={6} sm={6} className="icons_profile">
                      <ul className="MedicalH">
                   
                        <li>
                          <LocalPhoneIcon className="midLabIcon" />{" "}
                          {`${profileData?.countryCode || "+1"} ${
                            profileData?.phoneNumber
                          }`}
                        </li>
                        <li>
                          <FlagIcon className="midLabIcon" />
                          {profileData?.country || "Somalia"}
                        </li>
                        <li>
                          <FiType
                            className="midLabIcon"
                            style={{ fontSize: "24px" }}
                          />
                          {profileData?.ownerName || "Nil"}
                        </li>
                      </ul>
                    </Col>
                    <Col lg={3} md={6} sm={6} className="icons_profile">
                      <ul className="MedicalH">
                        <li>
                          <LocationOnIcon className="midLabIcon" />
                          {profileData?.address || "Nil"}
                        </li>
              
                        <li>
                          <LocationCityIcon className="midLabIcon" />
                          {profileData?.city || "Nil"}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                )}

                {/* doctor */}
                {profileData?.role === "doctor" && (
                  <Row>
                    <Col lg={1}></Col>
                    <Col lg={4} md={6} className="mt-3">
                      <div className="doctorDetail-fees-card">
                        <div>
                          <h5 className="gray-color">Consultation Fee</h5>
                          <h5>
                            {numeral(
                              profileData?.consultationFee +
                                parseInt(
                                  (profileData?.consultationFee *
                                    doctorCommission) /
                                    100
                                ) +
                                commissions[3]?.adminCommission?.rate
                            ).format("0.0a")}
                            (SlSh)
                          </h5>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} md={6} className="mt-3">
                      <div className="doctorDetail-experience-card">
                        <h5 className="gray-color">Experience</h5>
                        <h5>{profileData?.totalExp || "Nil"} years</h5>
                      </div>
                    </Col>
                  </Row>
                )}

                {/* covid service */}
                {profileData?.role === "covid19" && (
                  <Row>
                    <Col lg={1} />
                    <Col lg={8} md={12}>
                      <Row className="appointmentsTables apponitment_tableee m-auto mb-5 h-auto mt-5">
                        <Col className="p-0">
                          <div className="appointment_table">
                            <Table
                              responsive="sm"
                              className="AppointmentTables"
                            >
                              <thead>
                                <tr>
                                  <th
                                    className="profile_tablle"
                                    style={{
                                      color: "#fff",
                                    }}
                                  >
                                    S.No
                                  </th>
                                  <th className="profile_tablle">
                                    Service Name
                                  </th>
                                  <th className="profile_tablle">
                                    Service Price
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {profileData?.covid19Services?.map(
                                  (service, index) => (
                                    <tr key={index}>
                                      <td className="profile_tablle">
                                        {index + 1}
                                      </td>
                                      <td className="profile_tablle">
                                        {service?.serviceName}
                                      </td>
                                      <td className="profile_tablle">
                                        {numeral(
                                          service?.servicePrice +
                                            parseInt(
                                              (service?.servicePrice *
                                                covidCommission) /
                                                100
                                            ) +
                                            commissions[3]?.adminCommission
                                              ?.rate
                                        ).format("0.0a")}
                                        (SlSh)
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col lg={1}></Col>
                  <Col lg={8} md={12} className="mt-5">
                    <div className="doctor-detail-tabs-card">
                      {/* Tabs */}
                      <div className={`doctor-detail-tabs-header`}>
                        <button
                          onClick={() => setnewText("about")}
                          className={`${
                            newText === "about" ? "ActiveTab" : ""
                          } ${profileData?.role === "covid19" && "w-100"}`}
                        >
                          About
                        </button>
                        {profileData?.role === "doctor" && (
                          <>
                            <button
                              onClick={() => setnewText("education")}
                              className={`${
                                newText === "education" ? "ActiveTab" : ""
                              }`}
                            >
                              Education
                            </button>
                            <button
                              onClick={() => setnewText("specialization")}
                              className={`${
                                newText === "specialization" ? "ActiveTab" : ""
                              }`}
                            >
                              Specialization
                            </button>
                          </>
                        )}
                      
                      </div>
                      {/* Tabs respective content */}
                      <div className="doctor-detail-tabs-text">
                        <p>
                          {newText === "about" &&
                            (profileData?.shortBio || about)}

                          {newText === "education" &&
                            profileData?.education?.length > 0 &&
                            profileData?.education}

                          {newText === "specialization" &&
                            profileData?.doctorSpecialization?.length > 0 &&
                            profileData?.doctorSpecialization?.map(
                              (item, index) => <li>{item}</li>
                            )}

                          {/* {newText === "services" && services} */}
                        </p>
                      </div>
                    </div>
                    <Row className="mb-4">
                      <Col>
                        {user?._id !== currentProfileId && (
                          <div className="MidLabBtn text-center ">
                            <button className="Med" onClick={() => showModal()}>
                              Message
                            </button>
                            <button
                              className="active"
                              onClick={() => {
                                history.push({
                                  pathname: `/doctor-schedule`,
                                  state: {
                                    schedule,
                                    consultationFee:
                                      profileData?.consultationFee,
                                    profileData,
                                    doctorCommission: doctorCommission,
                                    covidCommission: covidCommission,
                                    wafiPayCommission:
                                      commissions[3]?.adminCommission?.rate,
                                  },
                                });
                              }}
                            >
                              Book Appointment
                            </button>
                          </div>
                        )}

                        {user?._id == currentProfileId && (
                          <div className="MidLabBtn text-center">
                            <button
                              className="active"
                              onClick={() => history.goBack()}
                            >
                              Go Back
                            </button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <MessageModal
        openModal={openModal}
        showModal={showModal}
        closeModal={closeModal}
        profileData={profileData}
      />
      {isApiCall && <BodyLoader dark />}
    </div>
  );
};

export default DoctorDetail;
