import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import WithdrawModal from "../components/WithdrawModal";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";
import moment from "moment";

import { Get } from "../Axios/AxiosFunctions";
import {

  URL as baseUrl,
} from "../Config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import InvoiceImg from "../assets/images/invoice.png";
import { updateUser } from "../store/Actions/authAction";
import BodyLoader from "../components/BodyLoader";

const PaymentScreen = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [invoiceActivityData, setInvoiceActivityData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);

  const getInvoiceActivityData = async () => {
    const url = baseUrl(`users/withdrawals`);
    setIsLoading(true);
    const response = await Get(url, accessToken);
    console.log("asda", response);
    setIsLoading(false);

    if (response !== undefined) {
      setInvoiceActivityData(response?.data?.data);
    }
  };

  const getMe = async () => {
    const url = baseUrl(`users/me`);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      console.log(response);
      dispatch(updateUser(response?.data?.data?.user));
    }
  };
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  useEffect(() => {
    getInvoiceActivityData();
    getMe();
  }, []);

  return (
    <div>
      <Container fluid className="dashboard">
        <Row>
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="mt-4 navbar-padding-x">
              <Col md={12}>
                <DashboardTopBar />
              </Col>
            </Row>

            {/* here */}
            <Row>
              <Col md={12}>
                <Row className="withdrwaRow">
                  <Col md={12}>
                    <div className="withdrw">
                      <h5 className="small">My Wallet</h5>
                      <div className="withdrwal_col">
                        <button
                          className="widthdrawl_btn"
                          onClick={() => setModalShow(true)}
                        >
                          Widthdrawal
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="balanceCard">
                      <p className="big">Available Balance</p>
                      <h5>
                        {user?.userWallet?.availableBalance}{" "}
                        <span className="usd">(SlSh)</span>
                      </h5>
                      <p className="small">
                        The amount of credits available for withdrawal in your
                        wallet.
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="balanceCard">
                      <p className="big">Withdrawal Pending</p>
                      <h5>
                        {user?.userWallet?.pendingAmount}{" "}
                        <span className="usd">(SlSh)</span>
                      </h5>
                      <p className="small">
                        The amount of credits currently awaiting withdrawal to
                        be sent out during the next payment cycle
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="mywalletPara">
                  <Col md={12}>
                    <p className="small">
                      *The amounts listed here close approximation. There may be
                      a slight difference due to changes in foreign exchange
                      rates. All amounts are based on US Dollars.
                    </p>
                  </Col>
                </Row>
              </Col>
         
            </Row>
            {/* Table */}
            <Row className="invoice_activity">
              <Col md={12}>
                <h5>Invoice Activity</h5>
              </Col>
            </Row>
            <Row className="appointmentsTables custmTable">
              <Col className="p-0">
                {invoiceActivityData.length == 0 ? (
                  <div className="no-data-table-content">
                    <img src={InvoiceImg} alt="invoice" width={80} />
                    <p style={{ fontSize: 40 }}>No data found</p>
                  </div>
                ) : (
                  <div>
                    {loading ? (
                      <BodyLoader />
                    ) : (
                      <Table responsive="sm" className="AppointmentTables">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Processing Fee</th>
                            <th>Net Payment</th>
                            <th>Description</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {invoiceActivityData.length == 0 ? (
                            <tr>
                              <td colSpan={6}>
                                <div className="no-data-table-content">
                                  <img
                                    src={InvoiceImg}
                                    alt="invoice"
                                    width={80}
                                  />
                                  <p style={{ fontSize: 40 }}>No data found</p>
                                </div>
                              </td>
                            </tr>
                          ) : ( */}
                          {invoiceActivityData.map((item, index) => {
                            return (
                              <tr>
                                <td>{moment(item?.createdAt).format("ll")}</td>
                                <td>{item.amount}(SlSh)</td>
                                <td>{item?.commission}%</td>
                                <td>
                                  {/* {item?.amount * 1 -
                                      (item?.amount * 1) / item?.commission} */}
                                  {item.amount -
                                    (item?.amount * item?.commission) / 100}
                                  (SlSh)
                                </td>
                                <td>{item.message}</td>
                                <td>{item.status}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {modalShow && (
        <WithdrawModal
          show={modalShow}
          onHide={() => {
            getInvoiceActivityData();
            setModalShow(false);
          }}
        />
      )}
    </div>
  );
};

export default PaymentScreen;
