import React from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";

import { imageUrl } from "../Config/apiUrl";
import OrderItem from "./OrderItem";
const OrderDescriptionModal = (props) => {
  const { modalData } = props;

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Modal
              className="modal-box appointment_Des_modal small-modal-x-w70"
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="appointmentDespModalBody">
                <div className="appointmentDesrModal_inner">
                  <h4>Order Detail</h4>
                  <div className="checkout_table">
                    <table className="table">
                      <tr>
                        <th className="font24">
                          <span></span>
                          Order Summary
                        </th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Total Price</th>
                      </tr>
                      {modalData?.items?.map((e) => {
                        return <OrderItem data={e} />;
                      })}
                    </table>
                  </div>

                  <div className="appointmentDespModal_text order-item-text-p">
                    <p className="big">
                      Shipment Number:{" "}
                      <span className="paraTimingDes">
                        {" "}
                        #{modalData?.shipmentDetails?.shipmentNumber}
                      </span>
                    </p>
                    <p className="big">
                      Shipment Address:{" "}
                      <span className="paraTimingDes">
                        {" "}
                        {modalData?.shipmentDetails?.address}
                      </span>
                    </p>
                    <p className="big">
                      Email:{" "}
                      <span className="paraTimingDes">
                        {modalData?.shipmentDetails?.email}
                      </span>{" "}
                    </p>
                    <p className="big">
                      Name:{" "}
                      <span className="paraTimingDes">
                        {" "}
                        {modalData?.shipmentDetails?.name}
                      </span>
                    </p>
                    <p className="big">
                      City:{" "}
                      <span className="paraTimingDes">
                        {" "}
                        {modalData?.shipmentDetails?.city}
                      </span>
                    </p>
                  </div>
                </div>
                <span className="paraTimingDes"></span>

                <div className="modal-btn apppointmentDest_closeBtn">
                  {modalData?.prescriptionImg && (
                    <button
                      className="me-3 px-290"
                      onClick={() =>
                        window.open(`${imageUrl}${modalData?.prescriptionImg}`)
                      }
                    >
                      {"Show Prescription"}
                    </button>
                  )}

                  <button onClick={props.onHide} className="px-290">
                    {"Close"}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderDescriptionModal;
