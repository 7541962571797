import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DashboardTopBar from "../components/dashboardTopBar";
import SideNavbar from "./SideNavbar";
import SelectCity from "./SelectCity";
import { Get } from "../Axios/AxiosFunctions";
import { URL } from "../Config/apiUrl";
import { useSelector } from "react-redux";
import BodyLoader from "../components/BodyLoader";
import { useHistory } from "react-router-dom";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";

const SelectPreferencesForSearch = () => {
  const history = useHistory();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const [isApiCall, setIsApiCall] = useState(false);
  const [cities, setCities] = useState([]);
  const [specialization, setSpecialization] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // getData
  const getData = async () => {
    const url1 = URL("city?noPagination=true");
    const url2 = URL("specialization?noPagination=true");

    setIsApiCall(true);
    const response1 = await Get(url1, accessToken);
    const response2 = await Get(url2, accessToken);
    setIsApiCall(false);

    if (response1 !== undefined && response2 !== undefined) {
      setCities(response1?.data?.data);
      setSpecialization(response2?.data?.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="">
          <Col md={isMobile ? "1" : "3"}>
            <SideNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMobile={isMobile}
            />
          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <div className="doctor-topbar-section mt-5 navbar-padding-x">
              <DashboardTopBar />
            </div>

            <div className="doctor-selectCity-section mt-5">
              <SelectCity
                cities={cities}
                specialization={specialization}
                setSelectedCity={setSelectedCity}
                setSelectedSpecialization={setSelectedSpecialization}
                selectedCity={selectedCity}
                selectedSpecialization={selectedSpecialization}
              />
            </div>
            {selectedCity && selectedSpecialization && (
              <Col md={12} className="f-e mt-3">
                <button
                  className="btn-bid-x"
                  onClick={() => {
                    history.push({
                      pathname: "/DoctorsNear",
                      search: `?city=${selectedCity}&specialization=${selectedSpecialization.replaceAll(
                        "&",
                        "-"
                      )}`,
                    });
                  }}
                >
                  Search Doctors
                </button>
              </Col>
            )}
          </Col>
        </Row>
      </Container>

      {isApiCall && <BodyLoader />}
    </>
  );
};

export default SelectPreferencesForSearch;
