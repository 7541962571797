import React, { useState } from "react";
import Chart from "react-apexcharts";

const ApexChart2 = ({ statsData }) => {
  const [state] = useState({
    series: statsData,
    options: {
      chart: {
        height: 300,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "0px",
            },
            value: {
              fontSize: "0px",
            },
          },
        },
      },
    },
  });

  return (
    <div id="chart">
      <Chart
        options={state.options}
        series={statsData}
        type="radialBar"
        width={300}
        height={220}
      />
    </div>
  );
};
export default ApexChart2;
