import React from "react";
import Menu from "@mui/material/Menu";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

function NotificationMenu({
  notifications,
  openNotifications,
  setOpenNotifications,
  openNot,
  setAnchorEl,
}) {

  const handleClose = () => {
    setOpenNotifications(null);
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={openNotifications}
        open={openNot}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="notification-menu"
      >
        <section>
          <Row>
            {notifications.map((notification, i) => {
              return (
                <Col md="12" key={notification?._id}>
                  <div className="noti-item">
                    <a
                    // to={{
                    //   // pathname: `/job/${notification?.job}`,
                    //   // state: notification?.job,
                    //   // pathname: "#",
                    // }}
                    >
                      <p>{notification?.message}</p>
                      <p className="noti-date">
                        about {moment(notification?.createdAt).fromNow()}
                      </p>
                    </a>
                  </div>
                </Col>
              );
            })}
          </Row>

          {notifications.length === 0 && (
            <div className="no-noti">No notifications yet.</div>
          )}
        </section>
      </Menu>
    </div>
  );
}

export default NotificationMenu;
