import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import SideNavbar from "./SideNavbar";
import DashboardTopBar from "../components/dashboardTopBar";
import { apiHeader, URL } from "../Config/apiUrl";
import { Patch } from "../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";

import LabeServicesModal from "../components/LabServiceModal";
import NullDataComponent from "../components/NullDataComponent";
import { toast } from "react-toastify";
import { updateUser } from "../store/Actions/authAction";
import AreYouSureModal from "../components/AreYouSureModal";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";


const Labservices = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const user = useSelector((state) => state?.authReducer?.user);
  const [isApiCall, setIsApiCall] = useState(false);

  const [openModal, SetOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [isDeleteServicesModalOpen, setIsDeleteServicesModalOpen] =
    useState(false);
  const [deleteServiceItem, setDeleteServiceItem] = useState(null);
  const [isDeletingApiCall, setIsDeletingApiCall] = useState(false);
  const [isMobile,setIsMobile]=useState(false)
  const [isOpen,setIsOpen]=useState(false)

  // handleDeleteService
  const handleDeleteService = () => {
    let covid19servicesCopy = [...user?.covid19Services];
    submitServicesHandler(
      covid19servicesCopy.filter((e) => e?._id !== deleteServiceItem?._id),
      "Service deleted successfully.",
      "delete",
      () => setIsDeleteServicesModalOpen(false)
    );
  };

  //   submitServicesHandler
  const submitServicesHandler = async (
    covid19Services,
    message = "Task successfull",
    type = "initial",
    emptyStates
  ) => {
    const formData = new FormData();
    for (let key in covid19Services) {
      formData.append("covid19Services", JSON.stringify(covid19Services[key]));
    }

    const url = URL(`users/updateMe`);
    type == "initial" && setIsApiCall(true);
    type == "delete" && setIsDeletingApiCall(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    type == "initial" && setIsApiCall(false);
    type == "delete" && setIsDeletingApiCall(false);

    if (response !== undefined) {
      SetOpenModal(false);
      toast.success(message);
      dispatch(updateUser(response?.data?.data?.user));
      emptyStates && emptyStates();
      setModalData(null);
    }
  };
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);

  return (
    <div>
      <Container fluid className="padding-fluid-container">
        <Row className="padding-fluid-container-xx">
          <Col md={isMobile ? "1" : "3"}>
          <SideNavbar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />

          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="mt-5">
              <Col lg={12}>
                <DashboardTopBar />
              </Col>
            </Row>

            {/* Screen header */}
            <Row className="my-4 m-auto lab-servicers">
              <Col md={3} sm={6} xs={6}>
                <h5>Lab Services</h5>
              </Col>
              <Col md={9} sm={6} xs={6} className="f-right-services">
                <button onClick={() => SetOpenModal(true)}>Add Service</button>
              </Col>
            </Row>

            {/* Table */}
            <Row className="appointmentsTables m-auto mb-5">
              <Col className="p-0">
                <div className="appointment_table">
                  <Table responsive="sm" className="AppointmentTables">
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "#fff",
                          }}
                        >
                          S.No
                        </th>
                        <th>Service Name</th>
                        <th>Service Price</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user?.covid19Services?.length > 0 &&
                        user?.covid19Services.map((e, i) => (
                          <>
                            <tr key={i}>
                              <td>{++i}</td>
                              <td>{e?.serviceName}</td>
                              <td>
                                {parseFloat(e?.servicePrice).toFixed(2)} (SlSh)
                              </td>

                              <td>
                                <VisibilityIcon
                                  className="c-p"
                                  onClick={() => {
                                    setModalData(e);
                                    SetOpenModal(true);
                                  }}
                                />
                                <ClearIcon
                                  className="c-p"
                                  onClick={() => {
                                    setDeleteServiceItem(e);
                                    setIsDeleteServicesModalOpen(true);
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                  {!isApiCall && user?.covid19Services.length == 0 && (
                    <div className="orders-s-null-data">
                      <NullDataComponent text={"No Service Found."} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {openModal && (
        <LabeServicesModal
          show={openModal}
          modalData={modalData}
          isLoading={isApiCall}
          onHide={() => SetOpenModal(false)}
          handleYes={submitServicesHandler}
        />
      )}

      {isDeleteServicesModalOpen && (
        <AreYouSureModal
          show={isDeleteServicesModalOpen}
          onHide={() => setIsDeleteServicesModalOpen(false)}
          message={"Do you really you want to delete this service?"}
          isLoading={isDeletingApiCall}
          handleYes={handleDeleteService}
        />
      )}

    </div>
  );
};

export default Labservices;
