import React from "react";
import { Card, Col, Container } from "react-bootstrap";

import { imageUrl } from "../Config/apiUrl";
import ShowMoreShowLessText from "./ShowMoreShowLessText";

function SolutionInOneAppCards({ solutionInOneAppCmsData }) {
  return (
    <Container className="soultion_card_cont">
      <div className="row">
        {solutionInOneAppCmsData.map((item, index) => {
          return (
            <Col
              lg={4}
              md={6}
              sm={12}
              className="text-center solution_In_one_App_parrent"
            >
              <Card
                style={{ maxWidth: "90%", height: "100%" }}
                className="card-shadow my-3 card_cutm"
              >
                <div className="app-solution-img-box">
                  <Card.Img variant="top" src={`${imageUrl}${item?.image}`} />
                </div>
                <Card.Body>
                  <Card.Title className="fw-bolder">{item.title}</Card.Title>
                  <Card.Text className="mt-3 p_small ">
                    <ShowMoreShowLessText
                      text={item.description}
                      visibility={50}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </div>
    </Container>
  );
}

export default SolutionInOneAppCards;
