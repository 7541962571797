import React, { useState,  useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideNavbar from "./SideNavbar";
import "../assets/styles/doctordashboard.css";
import DashboardTopBar from "../components/dashboardTopBar";
import { useSelector } from "react-redux";
import { Get} from "../Axios/AxiosFunctions";
import {
  URL as BaseURL,
  imageUrl,

  URL,
} from "../Config/apiUrl";
import { useHistory } from "react-router-dom";
import BodyLoader from "../components/BodyLoader";
import { isMobileViewHook } from "../customHooks/isMobileViewHook";


const ProductCategory = () => {
  const history = useHistory();
  let noIcon = true;
  let noRight = true;
  const { access_token: accessToken, user } = useSelector(
    (state) => state.authReducer
  );
  const [isApiCall, setIsApiCall] = useState(false);
  const [category, setCategory] = useState([]);
  const [isMobile,setIsMobile]=useState(false)
  const [isOpen,setIsOpen]=useState(false)

  // CategoryApiCall
  const categoryData = async () => {
    const url = URL(`category`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    setIsApiCall(false);
    if (response !== undefined) {
      setCategory(response.data.data);
    }
  };

  useEffect(() => {
    categoryData();
  }, []);
  useEffect(() => {
    isMobileViewHook(setIsMobile);
    setIsOpen(isMobile && false);
  }, []);
  return (
    <>
      <Container fluid className="">
        <Row>
          <Col md={isMobile ? "1" : "3"}>
          <SideNavbar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />

          </Col>
          <Col md={isMobile ? "11" : "9"}>
            <Row className="mt-5">
              <Col md={12}>
                <DashboardTopBar noIcon={noIcon} noRight={noRight} />
            </Col>
            </Row>
            <Row className="mt-5 category_row">
            <h5 className="mb-5">Categories</h5>
            {category.map((items, index) => (
                <>
                  <Col lg={3} md={5} sm={5} xs={12} className="my-3">
                    <div
                      className="dashboard-Pharmacy-box pharmacy_box_2 c-p"
                      onClick={() => history.push({
                          pathname:"/Pharmacy",
                          state:items?.catName
                      })}
                    >
                      <div className="category-img-box">
                        <img src={`${imageUrl}${items?.image}`} alt="img" />
                      </div>
                      <p>{items?.catName}</p>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      {isApiCall && <BodyLoader />}
    </>
  );
};

export default ProductCategory;
