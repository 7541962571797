import React from "react";
import { Col } from "react-bootstrap";

const Transmission = ({ item, index }) => {
  return (
    <Col md={4} sm={12} className="transmision_col">
      <h3 className="transmision_index">{`0${index + 1}`}</h3>
      <h6 className="transmision_head">{item.title}</h6>
      <p>{item.description}</p>
    </Col>
  );
};

export default Transmission;
