import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/images/logoWhite.png";
import Appoitments from "../assets/images/appoitments.png";
import Schedule from "../assets/images/schedule.png";
import Inbox from "../assets/images/inbox.png";
import Dashboard from "../assets/images/dashboard.png";
import Wallet from "../assets/images/wallet.png"
import Settings from "../assets/images/setting.png";
import Logout from "../assets/images/logout.png";
import orderImg from "../assets/images/order.png";
import MobileLogo from "../assets/images/D.png";
import capsule from "../assets/images/capsule.png"
import { Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOutRequest } from "../store/Actions/authAction";
import { useHistory } from "react-router-dom";

const SideNavbar = ({ isOpen, setIsOpen, isMobile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state?.authReducer?.user);


  const [selectedSidebar, setSelectedSidebar] = useState("/Dashboard");

  useEffect(() => {
    const path = window.location.pathname;
    setSelectedSidebar(path);
  }, []);

  const handleLogout = () => {
    dispatch(signOutRequest());
    console.log("==================>logout")
    history.replace("/");
  };

  return (
    <>
      <Container className="ps-0">
        <Row onClick={() => setIsOpen(!isOpen)} className="ps-0">
          <Col className={`sideNavbar  ${isOpen == false && "nav__cont"}`}>
            <div className="ms-5 my-5">
              <img src={isMobile && !isOpen ? MobileLogo : Logo} />
            </div>
            <div>
              <ul className="navs">
                {/* Dashboard */}
                <li
                  className={`nav__items ${
                    selectedSidebar === "/Dashboard" && "Dashboardactive"
                  }`}
                >
                  <div className="wallet-icon">
                    <img src={Dashboard} />
                    </div>
                  <Link
                    to="/Dashboard"
                    onClick={() => setSelectedSidebar("/Dashboard")}
                  >
                    {" "}
                    Dashboard{" "}
                  </Link>
                </li>

                {/* Appointment */}
                <li
                  className={`
                    nav__items
                    ${
                      selectedSidebar === "/Appointments" && "Dashboardactive"
                    }`}
                >
                  <div className="wallet-icon">
                    <img src={Appoitments} />
                    </div>
                  <Link
                    to="/Appointments"
                    onClick={() => setSelectedSidebar("/Appointments")}
                  >
                    {" "}
                    Appointment{" "}
                  </Link>
                </li>

                {/* Orders */}
                {user?.role == "patient" && (
                  <li
                    className={`
                      nav__items ${selectedSidebar === "/orders" &&
                      "Dashboardactive"}`
                    }
                  >
                   
                    <div className="wallet-icon">
                    <img src={orderImg} />
                    </div>
                    <Link
                      to="/orders"
                      onClick={() => setSelectedSidebar("/orders")}
                    >
                      {" "}
                      Orders{" "}
                    </Link>
                  </li>
                )}

                {/* For Doctor and covid19 */}
                {(user?.role == "doctor" || user?.role == "covid19") && (
                  <li
                    className={`
                      nav__items ${ selectedSidebar === "/manage-schedule" &&
                      "Dashboardactive"}`
                    }
                  >
                    <div className="wallet-icon">
                    <img src={Schedule} />
                    </div>
                    <Link
                      to="/manage-schedule"
                      onClick={() => setSelectedSidebar("/manage-schedule")}
                    >
                      {" "}
                      My Schedule{" "}
                    </Link>
                  </li>
                )}

                {/* For Doctor */}
                {user?.role == "doctor" && (
                  <li
                    className={
                      selectedSidebar === "/payment" && "Dashboardactive"
                    }
                  >
                    <div className="wallet-icon">
                    <img src={Wallet} />
                    </div>
                    <Link
                      to="/payment"
                      onClick={() => setSelectedSidebar("/payment")}
                    >
                      {" "}
                      Wallet{" "}
                    </Link>
                  </li>
                )}

                {user?.role == "covid19" && (
                  <li
                    className={
                      selectedSidebar === "/services" && "Dashboardactive"
                    }
                  >
                    <div className="wallet-icon">
                    <img src={capsule} />
                    </div>
                    
                    <Link
                      to="/services"
                      onClick={() => setSelectedSidebar("/services")}
                    >
                      {" "}
                      Services{" "}
                    </Link>
                  </li>
                )}

                {/* Chat */}
                <li
                  className={
                    selectedSidebar === "/ChatScreen" && "Dashboardactive"
                  }
                >
                  <div className="wallet-icon">
                    <img src={Inbox} />
                    </div>
                  <Link
                    to="/ChatScreen"
                    onClick={() => setSelectedSidebar("/ChatScreen")}
                  >
                    {" "}
                    Inbox{" "}
                  </Link>
                </li>

                <li
                  className={
                    selectedSidebar === "/settings" && "Dashboardactive"
                  }
                >
                  <div className="wallet-icon">
                    <img src={Settings} />
                    </div>
                  <Link
                    to="/settings"
                    onClick={() => setSelectedSidebar("/settings")}
                  >
                    {" "}
                    Settings{" "}
                  </Link>
                </li>

                {/* Logout */}
                <li className="c-p">
                <div className="wallet-icon">
                    <img src={Logout} />
                    </div>
                  <a  onClick={()=>{handleLogout()}}> Logout </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SideNavbar;
